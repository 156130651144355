(function() {
  'use strict';

  /** @ngInject */
  function storageService() {

    var _this = this;

    var STORAGE_LOCALSTORAGE    = 'localStorage';
    var STORAGE_SESSIONSTORAGE  = 'sessionStorage';

    var _defaultStorageEngine = STORAGE_LOCALSTORAGE;
    var _storageEngines       = [STORAGE_LOCALSTORAGE, STORAGE_SESSIONSTORAGE];
    var _storageKeyPrefix     = null;
    var _storages             = {};

    _this.setDefaultStorageEngine = function(engine) {
      if(_storageEngines.indexOf(engine) === -1) {
        throw new Error('Invalid storage engine.');
      }
      _defaultStorageEngine = engine;

      return _this;
    };

    _this.setStorageKeyPrefix  = function(prefix) {
      if(angular.isString(prefix)) {
        _storageKeyPrefix = prefix;
      }
      return _this;
    };

    _this.registerStorage  = function(storageKey, interceptor, parser, engine) {
      interceptor = (angular.isFunction(interceptor)) ? interceptor : function(data) {return data;};
      parser      = (angular.isFunction(parser)) ? parser : function(data) {return data};
      engine      = (_storageEngines.indexOf(engine) !== -1) ? engine : _defaultStorageEngine;

      if(!angular.isString(storageKey)) {
        throw new Error('storageKey needs to be a string');
      }

      if(_storageKeyPrefix) {
        storageKey  = [_storageKeyPrefix, storageKey].join('.');
      }

      if(angular.isDefined(_storages[storageKey])) {
        throw new Error('storage is already registered');
      }

      _storages[storageKey] = {
        engine      : engine,
        interceptor : interceptor,
        parser      : parser
      };

      return _this;
    };

    _this.lsTest = function (){
      var test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch(e) {
        return false;
      }
    };

    /** @ngInject */
    _this.$get = function($window) {
      return function(storageKey, value) {

        if(_this.lsTest() === true){
          // available
          if(_storageKeyPrefix) {
            storageKey  = [_storageKeyPrefix, storageKey].join('.');
          }

          if(angular.isUndefined(_storages[storageKey])) {
            throw new Error('Unknown storage with key: ' + storageKey);
          }

          var storage = _storages[storageKey];

          if(angular.isUndefined(value)) {
            // getter
            return storage.parser($window[storage.engine].getItem(storageKey));
          } else {
            // setter
            if(value === null) {
              $window[storage.engine].removeItem(storageKey);
            } else {
              $window[storage.engine].setItem(storageKey, storage.interceptor(value));
            }
            return true;
          }
        }
      }
    }
  }

  angular
    .module('wml.portal.storage')
    .provider('storage', storageService);

})();
