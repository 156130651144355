(function() {
  'use strict';

  /** @ngInject */
  function VideoListController(cmsService, videoListService) {
    var vm  = this;
    vm.bottomBlock = {};

    cmsService.loadData().then(function(data) {
      vm.data = data.plain();
      cmsService.setSEO(data);
    });

    _getContent();

    function _getContent() {
      videoListService.getBlockContent().then(function(response){
        vm.bottomBlock = response.plain();
      });
    }
  }

  angular
    .module('wml.portal.videoList')
    .controller('VideoListController', VideoListController);
})();
