(function() {
  'use strict';

  /** @ngInject */
  function serverSideValidation($rootScope) {
    return {
      restrict  : 'A',
      link: function(scope, elm, attrs, ctrl) {
        var _fieldname = attrs.serverSideValidation;
        var _event = $rootScope.$on('wml.portal.invalid.fields', function (event, response) {
          if (angular.isDefined(response) && angular.isArray(response)) {
            angular.forEach(response, function(item){
              if(item === _fieldname){
                elm.addClass('server-error');
                elm.on('focus', function(){
                  elm.removeClass('server-error');
                });
              }
            });
          }
        });

        scope.$on('$destroy', function() {
          _event();
        });
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('serverSideValidation', serverSideValidation)
})();
