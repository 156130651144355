(function() {
  'use strict';

  /** @ngInject */
  function PasswordForgotController(authenticationService) {

    var vm  = this;

    // private variables

    // Public variables
    vm.form = null;
    vm.sendingData  = false;
    vm.bEmailSent   = false;

    // Public function definitions
    vm.resetPassword = resetPassword;

    // Public functions

    function resetPassword(){
      vm.form.$commitViewValue();
      if(vm.form.$valid) {
        vm.sendingData = true;
        authenticationService.forgotPassword(vm.form.email).then(function(){
          vm.bEmailSent = true;
        }, function(){
          vm.bEmailSent = false;
        }).finally(function(){
          vm.sendingData = false;
        });
      }
    }

    // Private functions


    activate();

    function activate(){
    }
  }

  angular
    .module('wml.portal.authentication')
    .controller('PasswordForgotController', PasswordForgotController);
})();
