(function() {
  'use strict';

  /** @ngInject */
  function FooterController(appInfoService, registry, footerService) {
    var vm  = this;

    vm.displayVersioning  = (registry.developmentMode || registry.testingMode);
    vm.apiInfo            = null;
    vm.frontendInfo       = null;
    vm.data               = null;

    footerService.loadData().then(function(response){
      vm.data = response.plain();
    });

    appInfoService.getApiInfo().then(function(response) {
      vm.apiInfo  = response;
    });

    appInfoService.getFrontendInfo().then(function(response) {
      vm.frontendInfo  = response.data;
    });
  }

  angular
    .module('wml.portal.footer')
    .controller('FooterController', FooterController);
})();
