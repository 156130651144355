(function() {
  'use strict';

  /** @ngInject */
  function registryService($windowProvider) {

    var _this = this;

    var _$window        = $windowProvider.$get();
    var _bDevelopment   = false;
    var _bBeta          = false;
    var _bTesting       = false;
    var _bAcceptance    = false;
    var _hostName       = _$window.location.hostname;
    var _bProduction = true; var _RestApi = 'https://api.wml.nl';

    _this.RestApi         = _RestApi;
    _this.DevelopmentMode = _bDevelopment;
    _this.BetaMode        = _bBeta;
    _this.TestingMode     = _bTesting;
    _this.AcceptanceMode  = _bAcceptance;
    _this.ProductionMode  = _bProduction;
    _this.hostName        = _hostName;

    _this.$get  = getModule;

    /** @ngInject */
    function getModule() {
      return {
        hostName        : _hostName,
        restApiUrl      : _this.RestApi,
        developmentMode : _this.DevelopmentMode,
        betaMode        : _this.BetaMode,
        testingMode     : _this.TestingMode,
        productionMode  : _this.ProductionMode
      };
    }

  }

  angular.module('wml.portal.registry')
    .provider('registry', registryService);

})();
