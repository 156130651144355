(function() {
  'use strict';

  /** @ngInject */
  function navigationService(RestangularExt, $q, $stateRegistry, HeaderSwitchRoute, MessageRoute, FooterRoute, ivAuthenticate, $state, $transitions) {
    var _cache        = {
      header: [],
      footer: []
    };
    var _parsedCache  = null;

    /** @ngInject */
    var translationLoadedFn = function($q, $translate, $interval) {
      var deferred  = $q.defer();

      var checkInterval = $interval(function() {
        if($translate.isReady()) {
          $interval.cancel(checkInterval);
          deferred.resolve();
        }
      }, 100);

      return deferred.promise;
    };

    $transitions.onStart({
      to: function (state) {

        angular.forEach(_cache.header, function(item){
          item.active     = false;
        });

        function setActive(childItem) {
          if(childItem.parentId) {
            angular.forEach(_cache.header, function(item){
              if(item.id === childItem.parentId){
                item.active = true;

                if(angular.isDefined(item.parentId)) {
                  setActive(item);
                }
              }
            })
          }
        }

        angular.forEach(_cache.header, function(item){
          if(item.name === state.name){
            item.active = true;

            setActive(item)
          }
        });
      }
    });

    return {
      load          : load,
      getStateByUrl : getStateByUrl,
      getMenu       : _cache
    };


    function fetch(callback) {
      if(angular.equals(_cache, {header: [], footer: []})) {
        RestangularExt.all('navigation').customGET().then(function(response){
          var data  = response.plain();
          for(var key in data) {
            if(_cache[key] && data.hasOwnProperty(key)) {
              _cache[key].splice(0, _cache[key].length);
              for(var i=0; i < data[key].length; i++) {
                _cache[key].push(data[key][i]);
              }
            }
          }
          callback(_cache);
        });
      } else {
        callback(_cache);
      }
    }

    function load() {
      if(!_parsedCache) {
        var deferred  = $q.defer();
        fetch(function(items) {
          angular.forEach(items, function(navBlock) {
            angular.forEach(navBlock, function(item, key) {
              var templateUrl;
              var controller;
              var controllerAs;
              var resolve;

              switch(item.type) {
                case 'default'  : {
                  templateUrl   = 'app/components/detail-page/views/detail-page.view.html';
                  controller    = 'DetailPageController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;} };
                } break;

                case 'list'  : {
                  templateUrl   = 'app/components/list-page/views/list-page.view.html';
                  controller    = 'ListPageController';
                  controllerAs  = 'list';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;} };
                } break;
                case 'vacancy-list':
                case 'vacancy-list-internal'  : {
                  templateUrl   = 'app/components/vacancy/views/vacancy-list.view.html';
                  controller    = 'VacancyListController';
                  controllerAs  = 'list';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;} };
                } break;
                case 'detail'  : {
                  templateUrl   = 'app/components/detail-page/views/detail-page.view.html';
                  controller    = 'DetailPageController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;}, detailId: function () {return item.detail;} };
                } break;
                case 'vacancy-detail'  : {
                  templateUrl   = 'app/components/vacancy/views/vacancy-detail.view.html';
                  controller    = 'VacancyDetailController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;}, detailId: function () {return item.detail;} };
                } break;
                case 'standpipe'  : {
                  templateUrl   = 'app/components/standpipe/views/standpipe.view.html';
                  controller    = 'StandpipeController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;}, detailId: function () {return item.detail;} };
                } break;
                case 'carafe'  : {
                  templateUrl   = 'app/components/carafe/views/carafe.view.html';
                  controller    = 'CarafeController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;}, detailId: function () {return item.detail;} };
                } break;
                case 'water-estafette'  : {
                  templateUrl   = 'app/components/water-estafette/views/registration.view.html';
                  controller    = 'waterEstafetteRegistrationController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;}, detailId: function () {return item.detail;} };
                } break;
                case 'water-estafette-vote'  : {
                  templateUrl   = 'app/components/water-estafette/views/vote.view.html';
                  controller    = 'waterEstafetteVoteController';
                  controllerAs  = 'detail';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;}, detailId: function () {return item.detail;} };
                } break;
                case 'waterhardheid' : {
                  templateUrl   = 'app/components/waterhardheid/views/waterhardheid.view.html';
                  controller    = 'waterhardheidController';
                  controllerAs  = 'waterhardheid';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;} };
                } break;
                case 'damage-form' : {
                  templateUrl   = 'app/components/damage-form/views/damage-form.view.html';
                  controller    = 'damageFormController';
                  controllerAs  = 'damage';
                  resolve       = {translationsLoaded  : translationLoadedFn, pageType: function() {return item.type}, contentId: function() {return item.id;} };
                } break;
                case 'video-list': {
                  templateUrl = 'app/components/video-list/views/video-list.view.html';
                  controller = 'VideoListController';
                  controllerAs = 'videoList';
                  resolve = { translationsLoaded: translationLoadedFn, pageType: function () { return item.type; }, contentId: function () { return item.id; } };
                } break;
              }

              var state = {
                name  : item.name,
                url: item.url,
                views: {
                  header: HeaderSwitchRoute,
                  message: MessageRoute,
                  content: {
                    templateUrl: templateUrl,
                    controller: controller,
                    controllerAs: controllerAs
                  },
                  footer: FooterRoute
                },
                resolve : resolve
              };

              var bannerView = 'banners@' + item.name;

              state.views[bannerView] = {
                templateUrl     : 'app/components/banners/views/banners.view.html',
                controller      : 'BannersController',
                controllerAs    : 'banner'
              };

              var sideMenuConfig = 'sideMenu@' + item.name;

              state.views[sideMenuConfig] = {
                templateUrl     : 'app/components/side-menu/views/side-menu.view.html',
                controller      : 'SideMenuController',
                controllerAs    : 'sideMenu'
              };

              $stateRegistry.register(state);

              ivAuthenticate.addInsecureState(item.name);
            });
          });

          deferred.resolve(true);
        });
        _parsedCache  = deferred.promise;
      }

      return _parsedCache;
    }

    function getStateByUrl(urlPath) {
      var states  = $state.get();
      for(var j=0; j < $state.get().length; j++) {
        if(states[j].url === urlPath) {
          return states[j].name;
        }
      }

      return false;
    }

  }

  angular.module('wml.portal.navigation')
    .factory('navigationService', navigationService);

})();
