(function() {
  'use strict';

  /** @ngInject */
  function CorporationAddressController(corporationService, storage, $state, $location) {
    var vm = this;
    // private variables

    // Public variables
    vm.addressesStatus = true;

    // Public function definitions
    vm.select = select;
    vm.activate = activate;

    // Public functions
    function select(data) {
      storage('corporation_address', data);
      $state.go('dashboard');
    }

    function activate() {
      var queryParams = $location.search();
      corporationService.getAddresses(vm.addressesStatus, queryParams.refresh).then(function (response) {
        vm.addresses = response;
        if (vm.addressesStatus && vm.addresses.length) {
          storage('corporation_address', vm.addresses[0]);
        }
      });
    }

    // Private functions
    activate();
  }

  angular
    .module('wml.portal.corporation')
    .controller('CorporationAddressController', CorporationAddressController);
})();
