(function() {
  'use strict';

  /** @ngInject */
  function RestangularExt($rootScope, Restangular) {
    return Restangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.addRequestInterceptor(function(element) {
        $rootScope.$broadcast('wml.loader.start');
        return element;
      });

      RestangularConfigurer.addResponseInterceptor(function (data) {
        // intercept all responses and broadcast to the loader
        $rootScope.$broadcast('wml.loader.stop');

        return data;
      });

      RestangularConfigurer.setErrorInterceptor(function (response, deferred) {
        // intercept all errors and broadcast to the loader
        $rootScope.$broadcast('wml.loader.abort');
      });

    });
  }

  angular.module('wml.portal.restangular')
    .factory('RestangularExt', RestangularExt);

})();
