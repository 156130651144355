(function() {
  'use strict';

  /** @ngInject */
  function VacancyDetailController(registry, cmsService, vacancyDetailService, Upload, $rootScope) {
    var vm  = this;

    //public variables
    vm.motivationFileName = null;
    vm.resumeFileName     = null;
    vm.candidate          = vacancyDetailService.data;
    vm.showVacancyForm    = true;
    vm.sendingData        = false;
    vm.form               = null;

    vm.countries = [
      {
        id: 'NL',
        label: 'NL'
      },
      {
        id: 'BE',
        label: 'BE'
      },
      {
        id: 'DE',
        label: 'DE'
      }];

    //public functions
    vm.readMotivationFile  = readMotivationFile;
    vm.readResumeFile      = readResumeFile;
    vm.sendApplicationForm = sendApplicationForm;

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    cmsService.loadData().then(function(data) {
      vm.data = data;
      cmsService.setSEO(data);
    });

    function readMotivationFile(){
      vm.motivationFileName = (vm.candidate.motivation_file && vm.candidate.motivation_file.name) ? vm.candidate.motivation_file.name : "";
    }

    function readResumeFile(){
      vm.resumeFileName = (vm.candidate.resume_file && vm.candidate.resume_file.name) ? vm.candidate.resume_file.name : "";
    }

    function sendApplicationForm(){
      if(vm.form.$valid) {
        vm.sendingData = true;

        var files = [
          vm.candidate.motivation_file,
          vm.candidate.resume_file
        ];

        var formData = angular.extend({}, vm.candidate, {
          vacancy_name: vm.data.Content.vac_name.data
        });

        Upload.upload({
          url   : registry.restApiUrl + '/form/vacancy',
          data  : formData
        }).then(function(response){
          vm.showVacancyForm = false;
          $rootScope.$broadcast('wml.portal.message', response.data);
        }).finally(function(){
          vm.sendingData = false;
        })
      }
    }
  }

  angular
    .module('wml.portal.detailPage')
    .controller('VacancyDetailController', VacancyDetailController);
})();
