(function () {
  'use strict';

  function waterEstafetteService(RestangularExt){
    var _formRouteRegister        = RestangularExt.all('/form/waterestafette/register');
    var _formRouteRegisterCities  = RestangularExt.all('/form/waterestafette/register/cities');
    var _formRouteVote            = RestangularExt.all('/form/waterestafette/vote');

    return {
      register              : register,
      getRegistrations      : getRegistrations,
      getRegistrationCities : getRegistrationCities,
      vote                  : vote
    };

    function register(data) {
      return _formRouteRegister.post(data);
    }

    function getRegistrations(city) {
      return _formRouteRegister.customGET(null,{city: city});
    }

    function getRegistrationCities() {
      return _formRouteRegisterCities.customGET();
    }

    function vote(registrationId) {
      return _formRouteVote.one(registrationId.id).post();
    }
  }


  angular
    .module('wml.portal.water-estafette')
    .factory('waterEstafetteService', waterEstafetteService)
})();
