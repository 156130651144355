(function () {
  'use strict';

  /** @ngInject */
  function UsageInstallmentChangeController($filter, $stateParams, customerService, $state) {
    var vm = this;

    // private variables

    // Public variables
    vm.budgetBillingPlan      = {};
    vm.stateParams            = $stateParams;
    vm.data                   = {};
    vm.sendingData            = false;
    vm.visibleForm            = true;

    // Public function definitions
    vm.filterCurrency = filterCurrency;
    vm.save           = save;

    // Public functions
    function save (){
      if(vm.form.$valid) {
        vm.sendingData = true;
        vm.data.installment_amount =  vm.data.installment_amount.replace(new RegExp('\\.', 'g'), '');
        customerService.setBudgetBillingPlan($stateParams.contract_account_id, $stateParams.contract_id, vm.data).then(function() {
          $state.go('invoicesInstallmentChanged', $stateParams);
        }).finally(function(){
          vm.sendingData = false;
        })
      }
    }

    function filterCurrency() {
      if(vm.data.installment_amount){
        vm.data.installment_amount = $filter('number')(parseInstallment(), 1) + '0';
      }
    }

    // Private functions

    function parseInstallment(){
      var _lastIndexOf  = vm.data.installment_amount.lastIndexOf('.');
      var _amountLength = vm.data.installment_amount.length;
      var _amount       = vm.data.installment_amount;
      if(_lastIndexOf !== -1 && (_amountLength - _lastIndexOf <= 3 )){
        _amount = _amount.substr(0,_lastIndexOf) + ',' + vm.data.installment_amount.substr(_lastIndexOf,_amountLength);
      }
      return _amount.replace(new RegExp('\\.', 'g'), '').replace(",", ".");
    }

    activate();

    function activate() {
      customerService.getBudgetBillingPlan($stateParams.contract_account_id, $stateParams.contract_id).then(function(response) {
        vm.visibleForm  = true;
        vm.budgetBillingPlan = response;
      }, function(error) {
        if(angular.isObject(error) && angular.isDefined(error.data) && angular.isDefined(error.data.code) && error.data.code === 8009) {
          vm.visibleForm  = false;
        }
      });
    }

  }

  angular
    .module('wml.portal.usage')
    .controller('UsageInstallmentChangeController', UsageInstallmentChangeController);
})();
