(function() {
  'use strict';

  /** @ngInject */
  function dashboardService() {


    return {

    };


  }

  angular.module('wml.portal.dashboard')
    .factory('dashboardService', dashboardService);

})();
