(function() {
  'use strict';

  /** @ngInject */
  function ContactPersonFormController($stateParams, $state, $rootScope, $translate, enumService, contactPersonService) {
    // private variables
    var vm = this;
    var typeMapping = {
      general         : $translate.instant('customer.contact_persons.type.general').toLowerCase(),
      administration  : $translate.instant('customer.contact_persons.type.administration').toLowerCase(),
      incidents       : $translate.instant('customer.contact_persons.type.incidents').toLowerCase(),
      newsletter      : $translate.instant('customer.contact_persons.type.newsletter').toLowerCase()
    };

    // Public variables
    vm.form = null;
    vm.sendingData  = false;
    vm.insertions   = null;
    vm.pageTitle    = null;
    vm.data = {
      account_id  : null,
      type        : null,
      firstname   : null,
      insertion   : null,
      lastname    : null,
      email       : null,
      phone       : null
    };

    if($state.current.name === 'contactPersonAdd'){
      vm.pageTitle = $translate.instant('customer.contact_persons.page_title_add');
    } else {
      vm.pageTitle = $translate.instant('customer.contact_persons.page_title');
    }

    // Public function definitions
    vm.save     = save;

    // Public functions
    function save() {
      if(vm.form.$valid) {
        vm.sendingData  = true;
        contactPersonService.save(vm.data).then(function(data) {
          if(data.fields && data.fields.contact_person) {
            if(vm.data.account_id){
              contactPersonService.addUpdate(vm.data.account_id, data.fields.contact_person);
            } else {
              contactPersonService.addUpdate(data.fields.contact_person.relation_account_id, data.fields.contact_person);
            }
            $rootScope.$broadcast('clear-cache', {customer: {customer: true}});
            $state.go('customer');
          }
        }).finally(function() {
          vm.sendingData  = false;
        });
      }
    }

    // Private functions
    function activate() {
      if($stateParams.account_id) {
        vm.data.account_id  = $stateParams.account_id;
        contactPersonService.load($stateParams.account_id).then(function(response) {
          vm.data.firstname = response.firstname || '';
          vm.data.lastname  = response.lastname || '';
          vm.data.insertion = response.insertion || '';
          vm.data.email     = response.email.correspondence || '';
          vm.data.phone     = response.phone || '';
        });
      }

      for(var key in typeMapping) {
        if(typeMapping[key] === $stateParams.type) {
          vm.data.type  = key;
          break;
        }
      }

      enumService.getInsertions().then(function(data) {
        vm.insertions = data;
      });
    }

    activate();

  }

  angular.module('wml.portal.contactperson')
    .controller('ContactPersonFormController', ContactPersonFormController);

})();
