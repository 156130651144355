(function () {
  'use strict';

  /** @ngInject */
  function appointmentCalendarController($state, appointmentService, enumService, ivAuthenticate, $rootScope) {
    var vm = this;

    // private variables


    // Public variables
    vm.form               = null;
    vm.data               = {};
    vm.weekday            = [];
    vm.dayparts           = [];
    vm.appointmentsSlots  = null;
    vm.mode = '';

    // Public function definitions

    vm.next = next;
    vm.cancel = cancel;

    // Public functions
    function next() {
      if (vm.form.$valid) {
        appointmentService.storeAppointment(vm.data);
        $state.go('appointmentSummary' + vm.mode);
      }
    }

    function cancel() {
      appointmentService.clearAppointment();
      if (ivAuthenticate.isAuthenticated()) {
        $state.go('dashboard');
      } else {
        $state.go('appointmentFormCreate');
      }
    }

    // Private functions

    activate();

    function activate() {

      vm.mode = $state.current.name.split('appointmentCalendar')[1];

      var appointmentData = appointmentService.getAppointment();
      if (angular.isObject(appointmentData)) {
        vm.data = appointmentData;
        if (angular.isUndefined(vm.data.appointment) && angular.isString(vm.data.start) && vm.data.start.length > 0) {
          vm.data.appointment = vm.data.start;
        }
      }

      enumService.getWeekdays().then(function(response) {
        vm.weekday = response.plain();
      });

      enumService.getDayparts().then(function(response) {
        vm.dayparts = response.plain();
      });

      if (vm.mode !== 'Delete') {
        appointmentService.getAppointmentSlots().then(function (response) {
          vm.appointmentsSlots = response;
        });
      } else { // Delete
        vm.appointmentsSlots = [{
          start: vm.data.start,
          end: vm.data.end,
        }];
      }

      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }

  }

  angular
    .module('wml.portal.appointment')
    .controller('appointmentCalendarController', appointmentCalendarController);
})();
