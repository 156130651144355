(function () {
  'use strict';

  /** @ngInject */
  function corporationService($rootScope, RestangularExt, registry, ivAuthenticate, storage) {

    var cacheServiceName = 'corporation';
    var cache = {
      activeAddresses: null,
      inActiveAddresses: null
    };

    $rootScope.$on("clear-cache", function (evt, evtData) {
      var key;
      if (angular.isObject(evtData)) {
        if (evtData[cacheServiceName]) {
          if (typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for (key in cache) {
              cache[key] = null;
            }
          } else if (angular.isObject(evtData[cacheServiceName])) {
            for (key in cache) {
              if (typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key] = null;
              }
            }
          }
        }
      } else {
        for (key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      getAddresses        : getAddresses,
      getAddressInfo      : getAddressInfo,
      getCsvExportUrl     : getCsvExportUrl
    };

    function getAddresses(activeAddresses, refresh) {
      if (refresh || (!cache.activeAddresses && activeAddresses) || (!cache.inActiveAddresses && !activeAddresses)) {
        var result = RestangularExt.all('corporation/address').getList({inactive: !activeAddresses}).then(function(items) {
          return items.map(function (item) {
            var searchkey = item.street + item.house_number + item.house_number_addition + item.postal_code + item.city + item.installation_id + item.serial_number_id + item.contract_id;
            item.searchkey = searchkey.replace(/\s/g, '').toLowerCase();
            return item;
          });
        });

        if (activeAddresses) {
          cache.activeAddresses = result;
        } else {
          cache.inActiveAddresses = result;
        }
      }
      return (activeAddresses ? cache.activeAddresses : cache.inActiveAddresses);
    }

    function getAddressInfo(postalCode, houseNumber, houseNumberAddition) {
      var getParams = {
        postal_code: postalCode,
        house_number: houseNumber
      };

      if (houseNumberAddition) {
        getParams.house_number_addition = houseNumberAddition;
      }

      return RestangularExt.all('corporation/address').customGET(null, getParams);
    }

    function getCsvExportUrl() {
      var url   = registry.restApiUrl + '/corporation/address/export/csv?access_token=' + ivAuthenticate.getAccessToken();

      if(storage('customer_number')) {
        url = url + '&customer=' + storage('customer_number');
      }

      return url;
    }

  }

  angular.module('wml.portal.corporation')
    .factory('corporationService', corporationService);

})();
