(function() {
  'use strict';

  function checkNumber() {
    return {
      restrict  : 'A',
      require   : 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        var NUMBER_REGEXP = /^\d+$/;

        function fromUser(text) {
          if(text){
            if (text.length) {
              ctrl.$setValidity('checkNumber', NUMBER_REGEXP.test(text));
            }
            return text;
          } else {
            ctrl.$setValidity('checkNumber', true);
          }
          return '';
        }

        ctrl.$parsers.unshift(fromUser);
        ctrl.$formatters.unshift(fromUser);
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('checkNumber', checkNumber)
})();




