(function() {
  'use strict';

  /** @ngInject */
  function config($translateProvider, storageProvider, $injector, translations_nl_NL, registryProvider) {
    var defaultLanguage = 'nl_NL';

    // To prevent timing issues, define storage key prefix at this point also to make sure keys are consistent
    storageProvider.setStorageKeyPrefix('wml');
    // register storage
    storageProvider.registerStorage('language', null, null, 'localStorage');
    // manually load $window service as dependency for storage service
    var $window     = $injector.get('$windowProvider').$get();
    // manually load storage service
    var storage     = storageProvider.$get($window);

    var preferredLanguage = defaultLanguage;

    var storedLanguageISO = storage('language');

    if(angular.isDefined(storedLanguageISO)) {
      preferredLanguage = storedLanguageISO;
    }

    storage('language', preferredLanguage);

    if(registryProvider.DevelopmentMode) {
      $translateProvider.translations('NL', translations_nl_NL);
    } else {
      $translateProvider.useUrlLoader(registryProvider.RestApi + '/translation');
    }
    $translateProvider.preferredLanguage('NL');
    $translateProvider.useSanitizeValueStrategy('escapeParameters');
  }

  angular
    .module('wml.portal')
    .config(config);

})();
