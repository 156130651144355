(function () {
  'use strict';

  /** @ngInject */
  function corporationStateCheckService(storage) {

    var _allowedStates = [
      'accountOptions',
      'chooseAddress',
      'dashboard',
      'customerEdit',
      'profile',
      'profilePassword',
      'profileEmail',
      'invoices',
      'invoicesRemaining',
      'premisesActionOverview',
      'premisesRegister',
      'premisesRegisterMeterReading',
      'premisesRegisterNewPremises',
      'premisesRegisterSummary',
      'premisesRegisterThankYou',
      'premisesUnregisterMoveOutDate',
      'premisesUnregister',
      'premisesUnregisterMeterReading',
      'premisesUnregisterCorrespondenceAddress',
      'premisesUnregisterSummary',
      'premisesUnregisterThankYou'
    ];

    return {
      isAllowedSecureState: isAllowedSecureState
    };

    function isAllowedSecureState(state) {
      if(angular.isObject(storage('corporation_address'))){
        return _allowedStates.indexOf(state) !== -1;
      } else if (state === 'chooseAddress' || state === 'accountOptions') {
        return true;
      }
      return false;
    }

  }

  angular.module('wml.portal.corporation')
    .factory('corporationStateCheckService', corporationStateCheckService);

})();
