(function () {
  'use strict';

  function waterhardheidController(cmsService,$rootScope, detailService, waterhardheidService){
    var vm = this;
    vm.hardness = null;

    //public functions
    vm.getHardness = getHardness;

    cmsService.loadData().then(function(data) {
      vm.data = data;

      cmsService.setSEO(data);

      $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
    });

    function getHardness(){
      waterhardheidService.getHardness(vm.form.postal_code, vm.form.house_no).then(function (response) {
        if(response !== false){
           vm.hardness = response[0].hardness;
        }else {
          vm.hardness = response;
        }
      }, errorHardness);

      function errorHardness(error){
        Error(error);
      }
    }

    detailService.getBlockContent().then(function(response){
      vm.bottomBlock = response.plain();
    });
  }

  angular
    .module('wml.portal.waterhardheid')
    .controller('waterhardheidController',waterhardheidController)

})();
