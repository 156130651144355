(function() {
  'use strict';

  /** @ngInject */
  function passwordValidationIndicator($translate, passwordService) {

    return {
      restrict: 'E',
      templateUrl: 'app/components/generic/directives/password-validator/views/password-validation-indicator.view.html',
      replace: true,
      scope: {
        password: '=password'
      },
      link: function(scope) {
        scope.classes = [];
        scope.validated = passwordService.initialValidationResult;

        passwordService.getCriteria().then(function(criteria) {
          scope.criteria = criteria;

          scope.labels = {
            minLength: (scope.criteria.minLength === 1) ? $translate.instant('password.criteria.min_length_single') : $translate.instant('password.criteria.min_length_plural', {value: scope.criteria.minLength}),
            minCaps: (scope.criteria.minCaps === 1) ? $translate.instant('password.criteria.min_caps_single') : $translate.instant('password.criteria.min_caps_plural', {value: scope.criteria.minCaps}),
            minLowercase: (scope.criteria.minLowercase === 1) ? $translate.instant('password.criteria.min_lowercase_single') : $translate.instant('password.criteria.min_lowercase_plural', {value: scope.criteria.minLowercase}),
            minNumbers: (scope.criteria.minNumbers === 1) ? $translate.instant('password.criteria.min_numbers_single') : $translate.instant('password.criteria.min_numbers_plural', {value: scope.criteria.minNumbers}),
            minSpecialChars: (scope.criteria.minSpecialChars === 1) ? $translate.instant('password.criteria.min_special_chars_single') : $translate.instant('password.criteria.min_special_chars_plural', {value: scope.criteria.minSpecialChars})
          };

          scope.$watch('password', function(e) {
            scope.classes.splice(0, scope.classes.length);
            validateInput(scope.password);
          });

          function validateInput(input) {
            Object.assign(scope.validated, passwordService.validateInput(input));

            var totalCriteria = Object.keys(scope.validated).length;
            var totalSucceeded = 0;
            for(var key in scope.validated) {
              if(scope.validated.hasOwnProperty(key)) {
                if(scope.validated[key] === true) {
                  totalSucceeded += 1;
                }
              }
            }

            if(totalSucceeded === totalCriteria) {
              scope.classes.push('fa-lock', 'text-success');
            } else if(totalSucceeded > (totalCriteria / 2)) {
              scope.classes.push('fa-unlock', 'text-warning');
            } else {
              scope.classes.push('fa-unlock', 'text-danger');
            }
          }

        });

      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('passwordValidationIndicator', passwordValidationIndicator)
})();
