(function() {
  'use strict';

  /** @ngInject */
  function RegisterSummaryController($rootScope, $state, registerService) {
    var vm  = this;
    vm.sendingData = false;

    // public variables
    vm.data = registerService.data;
    vm.insertion = {
      value: null,
      label: null
    };

    // public functions
    vm.previousState = previousState;
    vm.save = save;

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    function save() {
      // check if vm.data.file_upload.name is set
      // if no more recollection of fileUpload, then file is gone due to refresh.
      if (vm.data && vm.data.meterReading && vm.data.meterReading.file_upload && vm.data.meterReading.file_upload.name && !vm.data.meterReading.file_upload.file) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 11013});
        vm.data.meterReading.file_upload = null;
        registerService.storeData();
        return;
      }

      vm.sendingData = true;
      registerService.save().then(function (data) {
        if (angular.isDefined(data.type) && data.type === 'message' && data.level !== 'success') {
          if (angular.isDefined(data.level) && (data.level === 'warning' || data.level === 'info')) {
            $rootScope.$broadcast('wml.portal.message', data);
            if (data.code === 12004 || data.code === 12008) {
              vm.data.forced_meter_reading = true;
              $state.go('registerMeterReading', {meterError: data.code});
            }
          }
        } else {
          $state.go('registerConfirmation');
        }
      }).catch(function () {
        // Notification: register api call failed contact customer support
        $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12106});
      }).finally(function () {
        vm.sendingData = false;
      });
    }

    // previous state function
    function previousState() {
      var bIsInFuture = vm.data.bIsInFuture;
      if (!bIsInFuture) { $state.go('registerPaymentMethod'); }
      if (bIsInFuture) { $state.go('registerPersonalInfo'); }
    }

    activate();
    function activate() {
      if (vm.data.personalInformation.insertion && vm.data.personalInformation.insertion.value) {
        vm.insertion = _.find(vm.data.insertions, function (ins) {
          return ins.value === vm.data.personalInformation.insertion.value;
        });
      }
    }
  }

  angular
    .module('wml.portal.register')
    .controller('RegisterSummaryController', RegisterSummaryController);
})();
