(function() {
  'use strict';

  /** @ngInject */
  function customerService($rootScope, $q, RestangularExt, storage) {
    var _customerRoute  = RestangularExt.all('/customer');

    var cacheServiceName  = 'customer';
    var cache = {
      premise   : null,
      address   : null,
      customer  : null,
      customers : null
    };

    $rootScope.$on("clear-cache", function(evt, evtData) {
      var key;
      if(angular.isObject(evtData)) {
        if(evtData[cacheServiceName]) {
          if(typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for(key in cache) {
              cache[key] = null;
            }
          } else if(angular.isObject(evtData[cacheServiceName])) {
            for(key in cache) {
              if(typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key]  = null;
              }
            }
          }
        }
      } else {
        for(key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      hasFutureMove         : hasFutureMove,
      getFutureMove         : getFutureMove,
      getFutureMoveCustomerNr: getFutureMoveCustomerNr,
      getCustomer           : getCustomer,
      getCustomers          : getCustomers,
      getPremises           : getPremises,
      getPremise            : getPremise,
      getInfo               : getInfo,
      getEmail              : getEmail,
      getAddress            : getAddress,
      getAddresses          : getAddresses,
      saveAddress           : saveAddress,
      clearAddressCache     : clearAddressCache,
      getContractAccount    : getContractAccount,
      savePaymentDetails    : savePaymentDetails,
      getBudgetBillingPlan  : getBudgetBillingPlan,
      setBudgetBillingPlan  : setBudgetBillingPlan,
      setReadingResult      : setReadingResult,
      getConsumption        : getConsumption,
      getDataLogger         : getDataLogger
    };

    function hasFutureMove(moveData) {
      return _customerRoute.one(storage('customer_number')).customGET('future-move', {postal_code: moveData.postal_code, house_number: moveData.house_no, house_number_addition: moveData.house_no_addition});
    }

    function getFutureMove(moveData) {
      return _customerRoute.one(storage('customer_number')).customGET('future-move', {request_code: moveData.future_move_token, postal_code: moveData.postal_code, house_number: moveData.house_no, house_number_addition: moveData.house_no_addition});
    }

    function getFutureMoveCustomerNr(futureMoveToken) {
      return _customerRoute.one(storage('customer_number')).customGET('future-move', {request_code: futureMoveToken});
    }

    function getCustomer() {
      if (cache.customer === null) {
        cache.customer = _customerRoute.one(storage('customer_number')).get();
      }
      return cache.customer;
    }

    function getCustomers() {
      if (cache.customers === null) {
        cache.customers = _customerRoute.getList();
      }
      return cache.customers;
    }

    function getPremises(params) {
      if (angular.isDefined(params)) {
        // When filtered dont cache and return promise
        return _customerRoute.one(storage('customer_number')).getList('premise', params);
      }

      if(cache.premise === null && angular.isUndefined(params)) {
        cache.premise = _customerRoute.one(storage('customer_number')).getList('premise');
      }
      return cache.premise;
    }

    function getPremise(premiseId) {
      return _customerRoute.one(storage('customer_number')).one('premise', premiseId).get();
    }

    function getInfo(contract) {
      return _customerRoute.one(storage('customer_number')).one('info').get(contract);
    }

    function getEmail() {
      return _customerRoute.one(storage('customer_number')).one('email').get();
    }

    function getAddress() {
      if(cache.address === null) {
        var deferred  = $q.defer();
        cache.address = deferred.promise;

        _customerRoute.one(storage('customer_number')).getList('address', {standard: true}).then(function(data) {
          deferred.resolve(data[0]);
        });
      }
      return cache.address;
    }

    function getAddresses() {
      if(cache.addresses === null) {
        cache.addresses = _customerRoute.one(storage('customer_number')).getList('address');
      }
      return cache.addresses;
    }

    function saveAddress(address) {
      return _customerRoute.one(storage('customer_number')).all('address').post(address);
    }

    function clearAddressCache() {
      cache.address = null;
    }

    function getContractAccount(contactAccountId) {
      return _customerRoute.one(storage('customer_number')).one('contract-account', contactAccountId).get();
    }

    function savePaymentDetails(contractAccountId, contractId, data) {
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('contract', contractId).all('payment-details').customPUT(data);
    }

    function getBudgetBillingPlan(contractAccountId, contractId) {
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('contract', contractId).customGET('budget-billing-plan');
    }

    function setBudgetBillingPlan(contractAccountId, contractId, data) {
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('contract', contractId).all('budget-billing-plan').customPUT(data);
    }

    function setReadingResult(contractAccountId, contractId, budgetBillingPlanId, meterReading) {
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('contract', contractId).one('budget-billing-plan', budgetBillingPlanId).customGET("recommendation", meterReading);
    }

    function getConsumption(contractAccountId, contractId, aggregate) {
      aggregate = aggregate || null;

      var getParams = {};
      if(aggregate) {
        getParams.aggregate = aggregate;
      }

      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('contract', contractId).one('consumption').get(getParams);
    }

    function getDataLogger(contractAccountId, contractId, params) {
      var getParams = params || {};
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('contract', contractId).one('data-logger').get(getParams);
    }

  }

  angular.module('wml.portal.customer')
    .factory('customerService', customerService);

})();


