(function() {
  'use strict';

  /** @ngInject */
  function meterReadingService(RestangularExt, $q, $filter, $rootScope, storage) {

    var _defaults = {
      date : null,
      deviations: null,
      deviation_code: null,
      deviation_reason: null,
      deviation_text: null,
      meter_reading: '',
      keep_installment_amount: null,
      num1: '',
      num2: '',
      num3: '',
      num4: '',
      num5: '',
      num6: '',
      num7: '',
      email: '',
      optional_email: false,
      forced_meter_reading: false,
      forced_meter_reading_err_code: null,
      consumption: null,
      file_upload: {
        file: null,
        name: null,
        type: null,
      },
      address: {
        city: null,
        house_no: null,
        house_no_addition: null,
        postal_code: null,
        street: null,
      },
      meter_number: null,
      credentials: {
        assignment_number: null,
        postal_code: null,
        meter_reading: null,
      }
    };

    var _meterReadingRoute = RestangularExt.all('/meter-reading');

    var cacheServiceName = 'meterreading';
    var cache = {
      list  : null
    };

    var _meterReadingData;

    if (storage('meter_readings') !== null) {
      _meterReadingData = storage('meter_readings');
    } else {
      _meterReadingData = angular.copy(_defaults);
    }

    function clearData() {
      _resetField(_meterReadingData);
      $rootScope.$broadcast('clear-cache', {meterreading: true});
      storage('meter_readings', null);
    }

    function _resetField(field, keyPath) {
      keyPath = keyPath || [];

      if (angular.isObject(field)) {
        for (var key in field) {
          if (field.hasOwnProperty(key)) {
            keyPath.push(key);
            if (angular.isObject(field[key]) && angular.isObject(_defaultForKey(keyPath))) {
              _resetField(field[key], keyPath);
            } else {
              field[key] = _defaultForKey(keyPath);
              keyPath.pop();
            }
          }
        }
        keyPath.pop();
      }
    }

    function _defaultForKey(keyPath) {
      var tmpValue = angular.copy(_defaults);
      for (var i = 0; i < keyPath.length; i++) {
        if (angular.isDefined(tmpValue[keyPath[i]])) {
          tmpValue = tmpValue[keyPath[i]];
        } else {
          return null;
        }
      }
      return tmpValue;
    }

    $rootScope.$on("clear-cache", function(evt, evtData) {
      var key;
      if(angular.isObject(evtData)) {
        if(evtData[cacheServiceName]) {
          if(typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for(key in cache) {
              cache[key] = null;
            }
          } else if(angular.isObject(evtData[cacheServiceName])) {
            for(key in cache) {
              if(typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key]  = null;
              }
            }
          }
        }
      } else {
        for(key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      getMeterReadings: getMeterReadings,
      setMeterReading: setMeterReading,
      getWFL: getWFL,
      setWFL: setWFL,
      checkMeterReading: checkMeterReading,
      meterReadingCheck: meterReadingCheck,
      verifyReading: verifyReading,
      meterDetails: meterDetails,
      storeData: storeData,
      clearData: clearData,
      data: _meterReadingData
    };

    function getMeterReadings() {
      if (!cache.list) {
        cache.list = _meterReadingRoute.getList();
      }
      return cache.list;
    }

    function storeData() {
      // do not save the file upload data to the cookie, base64 will become too big.
      var copy = angular.copy(_meterReadingData);
      copy.fileUpload = null;
      // file object does not need to get saved in cookie
      if (copy.file_upload) {
        copy.file_upload.file = null;   // base64 can get too big to save in cookie.
      }
      storage('meter_readings', copy);
    }

    function setMeterReading(assignmentNumber, postalCode, meterReading, force, date, deviationCode, deviationText, deviationReason, email, file_upload, keep_installment_amount) {

      date = $filter('date')(date, 'yyyy-MM-ddTHH:mm:ss');

      // 20170828 - rob@ivengi.com -  Do not parse as int due to parsing octal when string starts with one
      //                              or more zeroes in older javascript engines!
      //                              Parsing to integer logic moved to API!
      // meterReading = parseInt(meterReading);
      force = (typeof force === 'boolean') ? force : false;

      if (force === true) {
        if (angular.isDefined(deviationCode) === false) {
          $q.reject();
        }
      }

      deviationCode = deviationCode || null;
      deviationText = deviationText || null;
      deviationReason = deviationReason || null;

      return _meterReadingRoute.one(assignmentNumber).post(null, {
        reading: meterReading,
        force: force,
        date: date,
        deviation_code: deviationCode,
        deviation_text: deviationText,
        deviation_reason: deviationReason,
        postal_code: postalCode,
        email: email,
        file_upload: file_upload,
        keep_installment_amount: keep_installment_amount
      });
    }

    function getWFL(data) {
      return _meterReadingRoute.one(data.assignment_number).all('wfl').customGET();
    }

    function setWFL(data, wflAmount, wflMonth) {
      return _meterReadingRoute.one(data.assignment_number + '/wfl').post(null, {
        wfl_amount: wflAmount,
        wfl_month: wflMonth
      });
    }

    // regular yearly meterreading check
    function checkMeterReading(assignmentNumber, postalCode, meterReading, date) {
      date = $filter('date')(date, 'yyyy-MM-ddTHH:mm:ss');

      return _meterReadingRoute.one(assignmentNumber + '/check').post(null, {
        reading: meterReading,
        date: date,
        postal_code: postalCode,
      });
    }

    // function used to check meterreading for registering or moving in / out, where there probably is no assignment id (unlike regular yearly meterreading check that uses checkMeterReading)
    function meterReadingCheck(postalCode, meterReading, date, contractId, deviceId, moveReason) {
      date = $filter('date')(date, 'yyyy-MM-ddTHH:mm:ss');
      var data = {
        reading: meterReading,
        date: date,
        postal_code: postalCode,
        contract_id: contractId,
        device_id: deviceId,
        move_reason: moveReason
      };

      return _meterReadingRoute.customPOST(data, 'check');
    }

    function verifyReading(data) {
      return _meterReadingRoute.one(data.assignment_number).all('verify-address').customPOST({postal_code : data.postal_code})
    }

    function meterDetails(data) {
      return _meterReadingRoute.one(data.assignment_number).all('meter-details').customGET(null,{postal_code : data.postal_code})
    }
  }

  angular.module('wml.portal.meterReading')
    .factory('meterReadingService', meterReadingService);
})();
