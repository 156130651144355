(function() {
  'use strict';

  /** @ngInject */
  function addressService(Restangular) {

    var _addressRoute = Restangular.all('address');

    return {
      autofill  : autofill
    };

    function autofill(data) {
      return _addressRoute.customPOST(data, 'autocomplete');
    }
  }

  angular.module('wml.portal.address')
    .factory('addressService', addressService);

})();
