(function() {
  'use strict';

  /** @ngInject */
  function PasswordRestoreController($location, $state, $http, activationService, ivAuthenticate, Restangular, profileService, storage) {

    var vm  = this;

    // private variables

    // Public variables

    // Public function definitions

    // Public functions

    // Private functions

    function restorePassword(){
      var location = $location.search();
      storage('activation_query', location);

      if(angular.isDefined(location.e) && angular.isDefined(location.a) && angular.isDefined(location.r) && angular.isDefined(location.t) && angular.isDefined(location.p) && angular.isDefined(location.h)){
        activationService.activate(location).then(function(response) {
          ivAuthenticate.handleLoginSuccess({data: response});
          profileService.getProfile().then(getProfileSuccess);
        });
      }
    }

    function getProfileSuccess(response) {
      Restangular.setDefaultHeaders(angular.extend(Restangular.configuration.defaultHeaders, {'X-Customer-Number': response.customer_number}));
      $http.defaults.headers.common['X-Customer-Number']  = response.customer_number;
      storage('customer_number', response.customer_number);
      $state.go('profilePassword');
    }

    activate();

    function activate(){
      restorePassword();
    }
  }

  angular
    .module('wml.portal.authentication')
    .controller('PasswordRestoreController', PasswordRestoreController);
})();
