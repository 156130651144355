(function () {
  'use strict';

  /** @ngInject */
  function UsageCompareController($filter, $state, $stateParams, $rootScope, $q, customerService, usageMeterReadingService, cancelLink, goToLink, bShowAdvisedCheckbox) {
    var vm = this;

    // private variables


    // Public variables
    vm.data           = {};
    vm.consumption    = {};
    vm.sendingData    = false;

    // Public function definitions
    vm.filterCurrency       = filterCurrency;
    vm.save                 = save;
    vm.cancel               = cancel;
    vm.bShowAdvisedCheckbox = bShowAdvisedCheckbox;

    if(!bShowAdvisedCheckbox){
      vm.data.use_advised = true;
    }

    // Public functions
    function filterCurrency() {
      vm.data.installment_amount = $filter('number')(parseInstallment(), 1) + '0';
    }

    function save() {
      if(vm.form.$valid) {
        vm.sendingData = true;
        vm.data.installment_amount =  vm.data.installment_amount.replace(new RegExp('\\.', 'g'), '');
        customerService.setBudgetBillingPlan($stateParams.contract_account_id, $stateParams.contract_id, vm.data).then(function() {
          $state.go(goToLink, $stateParams);
        }).finally(function(){
          vm.sendingData = false;
        })
      }
    }

    function cancel() {
      $state.go(cancelLink, $stateParams);
    }

    // Private functions

    function parseInstallment(){
      var _lastIndexOf  = vm.data.installment_amount.lastIndexOf('.');
      var _amountLength = vm.data.installment_amount.length;
      var _amount       = vm.data.installment_amount;
      if (_lastIndexOf !== -1 && (_amountLength - _lastIndexOf <= 3 )){
        _amount = _amount.substr(0, _lastIndexOf) + ',' + vm.data.installment_amount.substr(_lastIndexOf, _amountLength);
      }
      return _amount.replace(new RegExp('\\.', 'g'), '').replace(",", ".");
    }

    activate();

    function activate() {
      var promises = {
        qGetConsumption       : customerService.getConsumption($stateParams.contract_account_id, $stateParams.contract_id),
        qGetBudgetBillingPlan : customerService.getBudgetBillingPlan($stateParams.contract_account_id, $stateParams.contract_id)
      };

      $q.all(promises).then(function(response) {
        var consumption       = response.qGetConsumption[0];
        vm.budgetBillingPlan  = response.qGetBudgetBillingPlan;

        var meterReading = usageMeterReadingService.getMeterReading();

        if(angular.isDefined(meterReading.level) && angular.isDefined(meterReading.code)){
          $rootScope.$broadcast('wml.portal.message', {level: meterReading.level, code:meterReading.code});
          meterReading.new_amount = vm.budgetBillingPlan.amount;
        }

        meterReading.reading_result = parseInt(meterReading.reading_result);

        if(consumption.meter_reading_results.length > 0 && angular.isObject(meterReading)){
          consumption.meter_reading_results = consumption.meter_reading_results.slice(0,1);

          meterReading.consumption = meterReading.reading_result - consumption.meter_reading_results[0].reading_result;
          meterReading.reading_date_time   = moment().format();
          consumption.meter_reading_results.unshift(meterReading);
        }

        vm.data.installment_amount         = $filter('number')(meterReading.new_amount, 1) + '0';
        vm.consumption = consumption;
      });
    }
  }

  angular
    .module('wml.portal.usage')
    .controller('UsageCompareController', UsageCompareController);
})();
