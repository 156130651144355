(function() {
  'use strict';

  /** @ngInject */
  function appointmentService($q, $rootScope, RestangularExt, storage) {
    var _msaRoute = RestangularExt.all('/meter-service-appointment');

    var cacheServiceName = 'appointment';
    var cache = {
      list  : null
    };

    $rootScope.$on("clear-cache", function(evt, evtData) {
      clearAppointment();
      var key;
      if(angular.isObject(evtData)) {
        if(evtData[cacheServiceName]) {
          if(typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for(key in cache) {
              cache[key] = null;
            }
          } else if(angular.isObject(evtData[cacheServiceName])) {
            for(key in cache) {
              if(typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key]  = null;
              }
            }
          }
        }
      } else {
        for(key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      verifyAppointment     : verifyAppointment,
      getAppointment        : getAppointment,
      getAppointments       : getAppointments,
      getAppointmentSlots   : getAppointmentSlots,
      storeAppointment      : storeAppointment,
      saveAppointment       : saveAppointment,
      clearAppointment      : clearAppointment,
      deleteAppointment     : deleteAppointment
    };

    function verifyAppointment(data){
      var obj = {
        postal_code : data.postal_code,
        filter_type_z6: data.filter_type_z6 || false
      };

      return _msaRoute.one(data.service_number).get(obj).then(function(response){
        //get a response and store
        storeAppointment(response.plain());
        return response;
      });
    }

    function getAppointment() {
      return storage('appointment');
    }

    function getAppointments(){
      if(!cache.list) {
        cache.list  = _msaRoute.getList();
      }
      return cache.list;
    }

    function getAppointmentSlots() {
      var data = getAppointment();
      var obj = {
        postal_code : data.address.postal_code,
        meter_type  : data.meter_type
      };
      return  _msaRoute.one(data.notify_no).one('time-slot').get(obj);
    }

    function storeAppointment(data) {
      if(angular.isDefined(storage('appointment')) && storage('appointment') !== null){
        data = angular.extend(storage('appointment'), data);
      }
      storage('appointment', data);
    }

    function deleteAppointment(data) {
      return _msaRoute.one(data.notify_no).customDELETE('schedule', {start: data.start, end: data.end, email: data.email});
    }

    function saveAppointment(data) {
      var appointmentData = {};

      if(data.appointment === 'other'){
        appointmentData.weekday     = data.appointment_other.weekday.value;
        appointmentData.timeslot    = data.appointment_other.timeslot.value;
        appointmentData.custom      = true;
      } else {
        appointmentData             = data.appointment;
        appointmentData.custom      = false;
      }

      if(angular.isDefined(data.meter_type)){
        appointmentData.meter_type  = data.meter_type.value;
      }

      if(angular.isDefined(data.email)){
        appointmentData.email       = data.email;
      }

      var params = {
        postal_code : data.address.postal_code
      };

      return  _msaRoute.one(data.notify_no).customPOST(appointmentData, "schedule", params, {})
    }

    function clearAppointment() {
      return storage('appointment', null);
    }

  }

  angular.module('wml.portal.appointment')
    .factory('appointmentService', appointmentService);

})();
