(function () {
  'use strict';

  angular
    .module('wml.portal')
    .config(config);

  /** @ngInject */
  function config(storageProvider, RestangularProvider, registryProvider, tmhDynamicLocaleProvider, ivAuthenticateProvider, $qProvider, $httpProvider, $locationProvider, ivIformProvider) {
    // Set restangular api base
    RestangularProvider.setBaseUrl(registryProvider.RestApi);

    ivIformProvider.setApiBaseUrl(window.location.origin + '/');

    // Set html5 urls
    $locationProvider.html5Mode(true);

    // Set default header
    $httpProvider.defaults.headers.common['X-Requested-With'] = "XMLHttpRequest";
    $httpProvider.defaults.withCredentials = true;

    // Show error on unhandled rejections
    $qProvider.errorOnUnhandledRejections(registryProvider.DevelopmentMode);

    // Configure iv-authenticate
    ivAuthenticateProvider
      .setLoginUrl(registryProvider.RestApi + '/authenticate')
      .setShortTokenLoginUrl(registryProvider.RestApi + '/authenticate')
      .setRefreshTokenUrl(registryProvider.RestApi + '/authenticate/refresh-token')
      .setRedirectState('login')
      .addShortTermState('premisesUnregister')
      .addShortTermState('premisesUnregisterFutureCorrespondenceEmail')
      .addShortTermState('premisesUnregisterMeterReading')
      .addShortTermState('premisesUnregisterCorrespondenceAddress')
      .addShortTermState('premisesUnregisterSummary')
      .addShortTermState('premisesUnregisterEmail')
      .addShortTermState('premisesUnregisterThankYou')
      .addShortTermState('premisesRegister')
      .addShortTermState('premisesRegisterNewPremises')
      .addShortTermState('premisesRegisterCorrespondenceAddress')
      .addShortTermState('premisesRegisterFutureCorrespondenceEmail')
      .addShortTermState('premisesRegisterMeterReading')
      .addShortTermState('premisesRegisterSummary')
      .addShortTermState('premisesRegisterThankYou')
      .addInsecureState('paneltestRegister')
      .addInsecureState('premisesUnregisterMoveOutDate')
      .addInsecureState('premisesRegisterMoveInDate')
      .addInsecureState('home')
      .addInsecureState('contact')
      .addInsecureState('contactThankYou')
      .addInsecureState('login')
      .addInsecureState('loginActivate')
      .addInsecureState('register')
      .addInsecureState('registerLogin')
      .addInsecureState('registerMoveInDate')
      .addInsecureState('registerCorrespondence')
      .addInsecureState('registerPersonalInfo')
      .addInsecureState('registerMeterReading')
      .addInsecureState('registerPaymentMethod')
      .addInsecureState('registerPortal')
      .addInsecureState('registerSummary')
      .addInsecureState('registerConfirmation')
      .addInsecureState('sign-up')
      .addInsecureState('sign-upThankYou')
      .addInsecureState('passwordRestore')
      .addInsecureState('passwordForgot')
      .addInsecureState('meterReadingNoAssignmentNum')
      .addInsecureState('meterReading')
      .addInsecureState('meterReadingRecording')
      .addInsecureState('meterReadingEmailConfirmation')
      .addInsecureState('meterReadingExtReading')
      .addInsecureState('meterReadingRecordingExtReading')
      .addInsecureState('meterReadingSummary')
      .addInsecureState('meterReadingThankYou')
      .addInsecureState('premisesUnregisterLogin')
      .addInsecureState('premisesRegisterLogin')
      .addInsecureState('msaSummary')
      .addInsecureState('msaTimeTable')
      .addInsecureState('msaServicenumberForm')
      .addInsecureState('appointmentFormCreate')
      .addInsecureState('appointmentEmailCreate')
      .addInsecureState('appointmentCalendarCreate')
      .addInsecureState('appointmentSummaryCreate')
      .addInsecureState('appointmentConfirmationCreate')

      .addInsecureState('appointmentFormUpdate')
      .addInsecureState('appointmentEmailUpdate')
      .addInsecureState('appointmentCalendarUpdate')
      .addInsecureState('appointmentSummaryUpdate')
      .addInsecureState('appointmentConfirmationUpdate')

      .addInsecureState('appointmentFormDelete')
      .addInsecureState('appointmentEmailDelete')
      .addInsecureState('appointmentCalendarDelete')
      .addInsecureState('appointmentSummaryDelete')
      .addInsecureState('appointmentConfirmationDelete')

      .addInsecureState('cms')
      .addInsecureState('faqList')
      .addInsecureState('pageNotFound')
      .addInsecureState('business_contact')
      .addInsecureState('search')
      .addInsecureState('routes');

    // Configure storage provider
    storageProvider
      .setDefaultStorageEngine('sessionStorage')
      .setStorageKeyPrefix('wml')
      .registerStorage('customer_number', null, null, 'localStorage')
      .registerStorage('company', _parseToJson, _parseFromJson, 'localStorage')
      .registerStorage('corporation', _parseToJson, _parseFromJson, 'localStorage')
      .registerStorage('corporation_address', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('testpanel_userid', null, null, 'localStorage')
      .registerStorage('activation_query', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('previous_state')
      .registerStorage('meter_reading', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('meter_readings', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('appointment', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('premises_register', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('premises_unregister', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('premises_procces_status', null, null, 'sessionStorage')
      .registerStorage('register_customer', _parseToJson, _parseFromJson, 'sessionStorage')
      .registerStorage('app_info', _parseToJson, _parseFromJson, 'localStorage')
      .registerStorage('waterestafette_voted', null, null, 'localStorage');

    function _parseToJson (data) {
      if (data) {
        return angular.toJson(data);
      } else {
        return data;
      }
    }

    function _parseFromJson (data) {
      if (data) {
        return JSON.parse(data);
      } else {
        return data;
      }
    }

    // Configure translations
    tmhDynamicLocaleProvider.localeLocationPattern('./app/i18n/angular-locale_{{locale}}.js');
    tmhDynamicLocaleProvider.defaultLocale('nl-nl');
  }

})();
