(function () {
  'use strict';

  /** @ngInject */
  function password($timeout, passwordService) {
    return {
      restrict: 'E',
      templateUrl: 'app/components/generic/directives/password/views/password.view.html',
      require: 'ngModel',
      scope: {
        password: '=ngModel',
        equals: '=',
        validate: '=',
        id: '@',
        placeholder: '@',
        ngRequired: '=ngRequired'
      },
      link: function (scope, elem, attrs, ctrl) {
        scope.validate = typeof scope.validate === 'boolean' ? scope.validate : true;
        scope.ngRequired = typeof scope.ngRequired === 'boolean' ? scope.ngRequired : true;
        scope.id = attrs.id;
        scope.showPassword = false;
        scope.passwordActive = false;
        scope.placeholder = scope.placeholder || 'login.password';

        scope.toggleVisibility = function () {
          scope.showPassword = !scope.showPassword;
        };
        scope.$watch('password', function () {
          if (scope.validate) {
            ctrl.$setValidity('password', passwordService.validateInput(scope.password, true));
          }
        });

        scope.$watchGroup(['password', 'equals'], function () {
          var confirmationIsSet = angular.isDefined(scope.password) && angular.isString(scope.password) && scope.password.length > 0;
          var passwordIsSet = angular.isDefined(scope.equals) && angular.isString(scope.equals) && scope.equals.length > 0;

          if (confirmationIsSet) {
            ctrl.$setTouched();
          }

          if (confirmationIsSet && passwordIsSet) {
            if (scope.password === scope.equals) {
              ctrl.$setValidity('equals', true);
            } else {
              ctrl.$setValidity('equals', false);
            }
          } else if (!confirmationIsSet && !scope.ngRequired) {
            ctrl.$setValidity('equals', true);
          }
        });
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('password', password);
})();
