(function() {
  'use strict';

  /** @ngInject */
  function ContactController(enumService, contactFormService, storage, $state, addressService, customerService, ivAuthenticate, $timeout, $rootScope, $translate, Upload, registry) {
    var vm = this;
    var isCorporation = storage('corporation') || false;

    vm.bAuthenticated = false;

    // Public variables
    vm.forms = {
      subject: {},
      customer: {},
      contact: {},
    };
    vm.connectionAdresses = null;
    vm.data = {
      premises: {},
      other_address: {
        check: false,
      },
    };
    vm.subjects = null;
    vm.timeBrackets = {
      morning: 'morning',
      afternoon: 'afternoon',
      no_preference: 'no_preference',
    };
    vm.activepart = 1;

    vm.fileToUpload = null;
    vm.loading = false;

    // Public function definitions
    vm.send = send;
    vm.cancel = cancel;
    vm.next = next;
    vm.prev = prev;
    vm.fileChange = fileChange;
    vm.autofillHouseNo = autofillHouseNo;

    function autoFill(data, addressObj) {
      addressService.autofill(data).then(function (response) {
        var address = angular.copy(response.plain());
        addressObj.loading = false;

        if (address.street) {
          addressObj.street = address.street;
        }
        if(address.house_no_addition){
          addressObj.house_no_addition = address.house_no_addition;
        }
        if (address.city) {
          addressObj.city = address.city;
        }
      });
    }

    function autofillHouseNo(addressObj) {
      if(angular.isString(addressObj.postal_code) && angular.isString(addressObj.house_no)) {
        addressObj.postal_code = addressObj.postal_code.toUpperCase();

        if(/^[0-9]{4}[A-Z]{2}$/.test(addressObj.postal_code)) {

          var data = {
            postal_code : addressObj.postal_code,
            house_no    : addressObj.house_no
          };

          var autofillKey = data.postal_code + '_' + data.house_no;

          if (angular.isString(addressObj.house_no_addition) && addressObj.house_no_addition.length > 0) {
            data.house_no_addition = addressObj.house_no_addition;
            autofillKey = autofillKey + '_' + addressObj.house_no_addition;
          }

          if (autofillKey === addressObj._lastAutofill) {
            return false;
          }

          addressObj.city = null;
          addressObj.street = null;
          addressObj.loading = true;

          addressObj._lastAutofill = autofillKey;

          autoFill(data, addressObj);
        }
      }
    }

    // Public functions
    function fileChange(file, errFiles) {
      $rootScope.$broadcast('wml.portal.message.cancel');
      if (errFiles.length > 0) {
        if (errFiles[0].$error === "maxSize") {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 27000});
        } else {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 27001});
        }
      }
      if (file) {
        vm.fileToUpload = file;
      }
    }

    function send() {
      $rootScope.$broadcast('wml.portal.message.cancel');
      if (vm.forms.subject.$valid && vm.forms.customer.$valid && vm.forms.contact.$valid) {
        Upload.base64DataUrl(vm.fileToUpload)
          .then(function (dataUrl) {
            if (dataUrl) {
              vm.data.file_upload = dataUrl;
              vm.data.file_type = vm.fileToUpload.type;
              vm.data.file_name = vm.fileToUpload.name;
            }
          })
          .finally(function () {
            var subjectType = vm.subjects[vm.data.contact_type].webContactType;
            var subjectReason = (vm.data.contact_reason)
              ? ': ' + vm.subjects[vm.data.contact_type].contactReasons[vm.data.contact_reason]
              : '';
            vm.data.subject = subjectType + subjectReason;
            contactFormService.send(vm.data).then(function (data) {
              $state.go('contactThankYou');
            });
          });
      }
    }

    function cancel() {
      $state.go('home');
    }

    function prev() {
      vm.activepart = vm.activepart - 1;
      $rootScope.$broadcast('wml.portal.message.cancel');
    }

    function next(form) {
      if (vm.forms[form].$valid) {
        if (vm.activepart === 2 && vm.data.customer_number && vm.connectionAdresses && vm.connectionAdresses.length) {
          contactFormService.getCustomer(vm.data.customer_number, vm.data.premises.house_no, vm.data.premises.house_no_addition, vm.data.premises.postal_code).then(function () {
            nextAction(true);
          });
        } else {
          nextAction(true);
        }
      } else {
        nextAction(false);
      }
    }
    function nextAction(next) {
      if (next) {
        vm.activepart = vm.activepart + 1;
      }
      $rootScope.$broadcast('wml.portal.message.cancel');
    }

    function translateTimeBrackets() {
      var timeBracketKeys = Object.keys(vm.timeBrackets);

      timeBracketKeys.forEach(function (bracketKey) {
        vm.timeBrackets[bracketKey] = $translate.instant('contact.time_bracket.' + vm.timeBrackets[bracketKey]);
      });
      timeBracketKeys.sort(function (a, b) {
        if (vm.timeBrackets[a] < vm.timeBrackets[b]) return -1;
        if (vm.timeBrackets[a] > vm.timeBrackets[b]) return 1;
        return 0;
      });
    }

    function getSubjects() {
      contactFormService.getContactTypes().then(function (data) {
        if (!data) { return; }
        data = data.plain();
        var subjects = angular.copy(data);

        var subjectKeys = Object.keys(subjects);

        // second level sort
        subjectKeys.forEach(function (key) {
          if (!subjects[key].contactReasons) { return; }
          return Object.keys(subjects[key].contactReasons).sort(function(a, b) {
            if (subjects[key].contactReasons[a] < subjects[key].contactReasons[b]) return -1;
            if (subjects[key].contactReasons[a] > subjects[key].contactReasons[b]) return 1;
            return 0;
          });
        });

        // first level sort
        subjectKeys.sort(function (a, b) {
          if (subjects[a].webContactType < subjects[b].webContactType) return -1;
          if (subjects[a].webContactType > subjects[b].webContactType) return 1;
          return 0;
        });
        vm.subjects = subjects;
      });
    }

    function getCustomerSuccess(response) {
      vm.data.customer_number = response.customer_number || null;
      vm.data.name = response.name || null;
      vm.data.phone = response.phone || null;
      vm.data.mobile_phone = response.mobile_phone || null;
      vm.data.email = response.email.correspondence || null;
      vm.isCompany = response.is_company;

      getPremises();
    }

    function getPremises() {
      customerService.getPremises().then(function (response) {
        vm.connectionAdresses = response.plain();
        angular.forEach(vm.connectionAdresses, function(value, key) {
          vm.connectionAdresses[key].address.premise_id = vm.connectionAdresses[key].premise_id;
        });

        if(vm.connectionAdresses.length >= 1) {
          vm.data.premises = vm.connectionAdresses[0].address;
        }

      }, function (premisesError) {
        throw Error('getPremises error:', premisesError);
      });
    }

    activate();

    function activate() {
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
      // get iManager data for contact page
      contactFormService.getData().then(function (response) {
        vm.contactInformation = response;
        $rootScope.seo_title = response.Content.seo_title.data;
        $rootScope.seo_description = response.Content.seo_description.data;
        $rootScope.seo_keywords = response.Content.seo_keywords.data;
      });

      getSubjects();
      translateTimeBrackets();

      vm.bAuthenticated = ivAuthenticate.isAuthenticated();
      if (vm.bAuthenticated && !isCorporation) {
        customerService.getCustomer().then(getCustomerSuccess);
      }
    }
  }

  angular
    .module('wml.portal.contact')
    .controller('ContactController', ContactController);
})();
