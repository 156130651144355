(function () {
  'use strict';

  /** @ngInject */
  function premisesRegisterSummaryController($state, ivAuthenticate, premiseRegisterService, premisesService, $rootScope, storage) {

    var vm = this;

    // private variables

    // Public variables
    vm.form = null;
    vm.data = premiseRegisterService.data;
    vm.bCorporation = storage('corporation') || false;

    // Public function definitions
    vm.save = save;
    vm.cancel = cancel;
    vm.sendingData = false;

    // Public functions
    function save() {
      // check if vm.data.file_upload.name is set
      // if no more recollection of fileUpload, then file is gone due to refresh.
      if (vm.data && vm.data.meter_reading && vm.data.meter_reading.file_upload && vm.data.meter_reading.file_upload.name && !vm.data.meter_reading.file_upload.file) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 11013});
        vm.data.meter_reading.file_upload = null;
        premiseRegisterService.storeData();
        return;
      }
      vm.sendingData = true;
      premiseRegisterService.register()
        .then(registerSuccess)
        .catch(function (error) {
          // Notification, no mail address available to send confirmation (12006)?
          if (error === null) { return; }

          var params = (angular.isDefined(error.fields)) ? error.fields : {};

          if (angular.isDefined(error.code)) {
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
          } else {
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12006'});
          }
        }).finally(function () {
          vm.sendingData = false;
        });
    }

    function registerSuccess(response) {
      if(angular.isObject(response) && angular.isDefined(response.code) && (response.code === 12004 || response.code === 12008)) {
        $rootScope.$broadcast('wml.portal.message', response);
        vm.data.forced_meter_reading = true;
        $state.go('premisesRegisterMeterReading', {meterError: response.code});
      } else {
        if (storage('corporation') && angular.isObject(storage('corporation_address'))) {
          clearCache();
          $state.go('premisesRegisterThankYou');
        } else {
          if (premisesService.getProcesStatus() === 'moving_in') {
            if (ivAuthenticate.isAuthenticated() && !vm.data.bIsInFuture) {
              premisesService.askToContinueModal().then(function () {
                $state.go('premisesUnregister');
              }, function () {
                $state.go('customer');
              }).finally(function () {
                clearCache();
              });
            } else {
              $state.go('premisesRegisterThankYou');
            }
          } else {
            $state.go('premisesRegisterThankYou');
          }
        }
      }
    }

    function cancel() {
      var bIsInFuture = vm.data.bIsInFuture;
      if (!bIsInFuture && vm.data.correspondence.bSetNew) {
        $state.go('premisesRegisterCorrespondenceAddress');
      } else if (!bIsInFuture) {
        $state.go('premisesRegisterNewPremises');
      } else if (bIsInFuture && vm.data.email.bSetNew && vm.data.email.correspondence) {
        $state.go('premisesRegisterFutureCorrespondenceEmail');
      } else {
        $state.go('premisesRegister');
      }
    }

    function clearCache() {
      premiseRegisterService.clearData();
      $rootScope.$broadcast('clear-cache', {customer : true});
    }

    // Private functions
    activate();

    function activate() {
    }
  }

  angular
    .module('wml.portal.premisesRegister')
    .controller('premisesRegisterSummaryController', premisesRegisterSummaryController);
})();
