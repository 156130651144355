(function() {
  'use strict';

  /** @ngInject */
  function premisesService($q, $uibModal, $rootScope, storage, $translate, registry, ivAuthenticate) {

    return {
      storeProcesStatus   : storeProcesStatus,
      getProcesStatus     : getProcesStatus,
      clearProcesStatus   : clearProcesStatus,
      askToContinueModal  : askToContinueModal,
      getCsvExportUrl     : getCsvExportUrl
    };

    function storeProcesStatus(data){
      storage('premises_procces_status', data);
    }

    function getProcesStatus(){
      return storage('premises_procces_status');
    }

    function clearProcesStatus(){
      storage('premises_procces_status', null);
    }

    function askToContinueModal() {
      var _deferred           = $q.defer();
      var _scope              = $rootScope.$new();
      _scope.title            = $translate.instant('premises.askContinueModal.' + storage('premises_procces_status') + '.title');
      _scope.paragraph        = $translate.instant('premises.askContinueModal.' + storage('premises_procces_status') + '.paragraph');
      _scope.paragraph2       = $translate.instant('premises.askContinueModal.' + storage('premises_procces_status') + '.paragraph2');

      _scope.modal = $uibModal.open({
        templateUrl: 'app/components/premises/partials/ask-to-continue.modal.partial.html',
        backdrop: 'static',
        scope: _scope,
        size: 'lg'
      });

      _scope.cancel = function(){
        _scope.modal.close();
        _deferred.reject();
      };

      _scope.next = function(){
        _scope.modal.close();
        _deferred.resolve();
      };
      return _deferred.promise;
    }

    function getCsvExportUrl() {
      return registry.restApiUrl + '/customer/'+ storage('customer_number') +'/premise/export/csv?access_token=' + ivAuthenticate.getAccessToken();
    }

  }

  angular.module('wml.portal.premises')
    .factory('premisesService', premisesService);

})();
