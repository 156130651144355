(function () {
  'use strict';

  /** @ngInject */
  function RegisterConfirmationController($rootScope, registerService) {
    var vm = this;

    vm.data = registerService.data;
    vm.isInFuture = '';
    vm.moveInDate = '';

    activate();

    function activate() {
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
      vm.isInFuture = (vm.data.bIsInFuture) ? 'future_' : '';
      vm.moveInDate = vm.data.meterReading.date;
      registerService.clearData();
    }
  }
  angular
    .module('wml.portal.register')
    .controller('RegisterConfirmationController', RegisterConfirmationController);
})();
