(function() {
  'use strict';

  function loader($rootScope) {

    return {
      restrict  : 'EA',
      templateUrl : 'app/components/generic/directives/loader/views/overlay.html',
      replace     : false,
      link: function(scope, iElem, iAttrs) {
        var loadingElementsCounter  = 0;
        $rootScope.loading          = true;

        scope.$on('wml.loader.start', function() {
          loadingElementsCounter++;
          if(loadingElementsCounter > 0) {
            $rootScope.loading = true;
          }
        });

        scope.$on('wml.loader.stop', function() {
          loadingElementsCounter--;
          if(loadingElementsCounter <= 0) {
            loadingElementsCounter  = 0; // correction for if any exceptions would ever occur
            $rootScope.loading = false;
          }
        });

        scope.$on('wml.loader.abort', function() {
          loadingElementsCounter  = 0;
          $rootScope.loading = false;
        });
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('loader', loader)
})();
