(function() {
  'use strict';

  /** @ngInject */
  function ContactThankYouController(ivAuthenticate, $rootScope) {
    var vm  = this;
    // private variables

    // Public variables
    vm.bAuthenticated = ivAuthenticate.isAuthenticated();

    // Public function definitions



    // Public functions


    // Private functions

    activate();


    function activate(){
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }

  }

  angular
    .module('wml.portal.contact')
    .controller('ContactThankYouController', ContactThankYouController);
})();
