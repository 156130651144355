(function () {
  'use strict';

  /** @ngInject */
  function meterReadingEmailConfirmationController($state, $stateParams, meterReadingService, $rootScope) {
    var vm = this;
    var _state = 'meterReadingRecording';
    var _stateParams = {};

    // private variables

    // Public variables
    vm.form = null;

    vm.data = meterReadingService.data;

    if (angular.isDefined($stateParams.assignment_number)) {
      vm.data.credentials.assignment_number = $stateParams.assignment_number;
    }

    if (angular.isDefined($stateParams.meter_reading)) {
      _state = 'meterReadingRecordingExtReading';
      vm.data.credentials.meter_reading = $stateParams.meter_reading;
      _stateParams.meter_reading = $stateParams.meter_reading;
    }

    // Public function definitions
    vm.next = next;
    vm.cancel = cancel;

    // Public functions
    function next() {
      vm.form.$commitViewValue();

      if (vm.form.$valid) {
        if (!vm.data.optional_email) {
          vm.data.email = '';
        }
        // vm.sendingData = true;
        meterReadingService.storeData();
        $state.go('meterReadingSummary', $stateParams);
      }
    }

    function cancel() {
      $state.go(_state, _stateParams);
    }
    // Private functions
    activate();

    function activate() {
      _stateParams.assignment_number = vm.data.credentials.assignment_number;
      _stateParams.postal_code = vm.data.credentials.postal_code;
      _stateParams.meter_reading = vm.data.meter_reading;
      if (vm.data.credentials.meter_reading) {
        _state = 'meterReadingRecordingExtReading';
        _stateParams.meter_reading = vm.data.credentials.meter_reading;
      }
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }
  }

  angular
    .module('wml.portal.meterReading')
    .controller('meterReadingEmailConfirmationController', meterReadingEmailConfirmationController);
})();
