(function() {
  'use strict';

  /** @ngInject */
  function autoTabTo($timeout) {

    return {
      restrict  : 'A',
      require   : 'ngModel',
      scope     : {
        'fieldValue' : '=ngModel',
      },
      link: function(scope, elm, attrs, ctrl) {
        var _fieldValue = null;
        function _focusNext(){
          var element = document.getElementById(attrs.autoTabTo);
          if (element){
            element.focus();
          }
        }

        elm.on('keyup', function(e) {
          if (angular.isDefined(this.value) && this.value.length === attrs.maxlength && (angular.isUndefined(elm.attr('data-overwrite-on-keyup')) || elm.attr('data-overwrite-on-keyup') === 'false')) {
            elm.attr('data-overwrite-on-keyup', 'true');
            _focusNext();
          } else {
            if(scope.fieldValue !== ''){
              if(e.key.length === 1){
                $timeout(function() {
                  scope.fieldValue = e.key;
                  _focusNext();
                });
              }
            }
          }
        });
      }
    };
  };

  angular.module('wml.portal.generic')
    .directive('autoTabTo', autoTabTo)
})();
