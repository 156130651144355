(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterLoginController($state, $rootScope, $timeout, customerService, premisesService, premiseUnregisterService, premiseRegisterService, $stateParams, $location, ivAuthenticate, storage, jwtHelper) {
    var vm = this;

    vm.form = null;
    vm.data = premiseUnregisterService.data;
    vm.sendingData = false;

    // Public function definitions
    vm.doLogin = doLogin;
    vm.cancel = cancel;
    vm.loginError = loginError;
    vm.formatFutureMoveResponse = formatFutureMoveResponse;

    // Public functions
    function cancel() {
      if (vm.data.credentials.future_move_token.length) {
        $rootScope.$broadcast('clear-cache');
        $state.go('home');
      } else {
        $state.go('premisesUnregisterMoveOutDate');
      }
    }

    function doLogin() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        $rootScope.$broadcast('wml.portal.message.cancel');
        $rootScope.$broadcast('wml.loader.start');

        vm.sendingData = true;

        vm.data.credentials.customer_number = vm.form.customer_number;
        vm.data.credentials.postal_code = vm.form.postal_code;
        vm.data.credentials.house_no = vm.form.house_no;
        vm.data.credentials.house_no_addition = vm.form.house_no_addition;
        vm.data.credentials.grant_type = 'short_term';

        ivAuthenticate.shortTermLogin(vm.data.credentials).then(function (response) {
          var claims = jwtHelper.decodeToken(response.data.access_token);

          if (angular.isDefined(claims)) {
            if (angular.isDefined(claims.corporation)) {
              storage('corporation', claims.corporation);
            }
            if (angular.isDefined(claims.company)) {
              storage('company', claims.company);
            }
          }

          if (angular.isDefined(claims) && angular.isDefined(claims.aud)) {
            storage('customer_number', claims.aud);

            // if hosFutureMoveOut token query param
            if (vm.data.credentials.future_move_token.length) {
              // get the FutureMoveOut data
              customerService.getFutureMove(vm.data.credentials).then(function (futureMoveRes) {
                vm.data.futureMoveOut = vm.formatFutureMoveResponse(futureMoveRes[0]);
                // if future move out exists and is equal to credentials
                if (vm.data.futureMoveOut.exists) {
                  vm.data.meter_reading.date = new Date(vm.data.futureMoveOut.moveDate).setHours(0, 0, 0, 0);
                  premiseUnregisterService.storeData();
                  $state.go('premisesUnregister');
                } else {
                  // Notification: This token has no future move request assigned, contact customer service
                  $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
                }
              }).catch(function () {
                // Notification: Failed to get future move data, contact customer service
                $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12101});
              });
            } else {
              // if not has a future move out token
              premiseUnregisterService.storeData();
              $state.go('premisesUnregister');
            }
          } else {
            // Notification: invalid credentials
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 2000});
          }
        }, function (error) { // ivAuthenticate.shortTermLogin
          vm.loginError(error);
        }).finally(function () {
          $timeout(function () {
            $rootScope.$broadcast('wml.loader.stop');
          }, 1000);
          vm.sendingData = false;
        });
      } else {
        // not valid form
        $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1001'});
      }
    }

    function formatFutureMoveResponse(futureMove) {
      return {
        exists: Boolean(futureMove.customer),
        moveDate: futureMove.date || '',
        premise: {
          postal_code: futureMove.postal_code,
          house_no: futureMove.house_num_1,
          house_no_addition: futureMove.house_num_2
        }
      };
    }

    function loginError(error) {
      if (error === null) { return; }
      var params = (angular.isDefined(error.fields)) ? error.fields : {};
      if (angular.isDefined(error.code)) {
        $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
      } else {
        $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '2014'});
      }
    }

    function activate() {
      var queryParams = $location.search();
      if (queryParams.fm && queryParams.fm.length) {
        // logout current user
        if (ivAuthenticate.isAuthenticated()) {
          ivAuthenticate.logout().then(function () {
            $rootScope.$broadcast('clear-cache');
            location.reload();
          });
        } else {
          // date not set && future move token exists
          if (/^\w+$/gi.test(queryParams.fm)) {
            customerService.getFutureMoveCustomerNr(queryParams.fm).then(function (futureMoveCustomerNr) {
              if (futureMoveCustomerNr) {
                vm.form.customer_number = futureMoveCustomerNr;
                vm.data.credentials.future_move_token = queryParams.fm;
                return;
              }
              throw new Error(); // Notification: invalid token format
            }).catch(function () {
              // Notification: invalid token format
              $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12100});
              return;
            });
          } else {
            // Notification: invalid token format
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12100});
            return;
          }
        }
      } else if (ivAuthenticate.isAuthenticated() && angular.isDefined(vm.data.bIsInFuture)) {
        // move on, there is no future move out / anonymous login page needed
        $state.go('premisesUnregister');
      } else if (ivAuthenticate.isAuthenticated() || angular.isUndefined(vm.data.bIsInFuture)) {
        // date not set, previous view skipped, go to move out date view
        $state.go('premisesUnregisterMoveOutDate');
      } else if (!ivAuthenticate.isAuthenticated()) {
        // save while clearing session
        var bIsInFuture = vm.data.bIsInFuture;
        var meterDate = vm.data.meter_reading.date;
        // clear previously set session data
        $rootScope.$broadcast('clear-cache', {customer: true});
        premiseUnregisterService.clearData();
        vm.data.bIsInFuture = bIsInFuture;
        vm.data.meter_reading.date = meterDate;
        premiseUnregisterService.storeData();
      }
    }
    activate();
  }

  angular.module('wml.portal.premisesUnregister')
    .controller('PremisesUnregisterLoginController', PremisesUnregisterLoginController);

})();
