(function() {
  'use strict';

  /** @ngInject */
  function signUpService(RestangularExt) {
    var _signUpRoute = RestangularExt.all('/customer/register');

    return {
      signUp   : signUp
    };

    function signUp(data){
      return _signUpRoute.post(data);
    }
  }

  angular.module('wml.portal.signup')
    .factory('signUpService', signUpService);

})();
