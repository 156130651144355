(function() {
  'use strict';

  /** @ngInject */
  function usageMeterReadingService(storage) {

    return {
      setMeterReading: setMeterReading,
      getMeterReading: getMeterReading
    };

    function setMeterReading(data) {
      storage('meter_reading', data);
    }

    function getMeterReading() {
      return storage('meter_reading');
    }
  }

  angular.module('wml.portal.usage')
    .factory('usageMeterReadingService', usageMeterReadingService);

})();
