(function () {
  'use strict';

  /** @ngInject */
  function RegisterPaymentMethodController($scope, $state, enumService, registerService, $rootScope) {
    var vm = this;

    // private variables
    var _paymentDates = [];
    var _paymentFrequency = [];

    // public variables
    vm.data = registerService.data;
    vm.paymentMethods = [];
    vm.paymentFrequency = [];
    vm.paymentDates = [];

    // public functions
    vm.save = save;
    vm.cancel = cancel;
    vm.filterPaymentDays = filterPaymentDays;

    // save functions
    function save() {
      registerService.storeData();
      // 20210923 - rob@ivengi.com
      //            Skip registerPortal state on customer request
      $state.go('registerSummary');
    }

    function cancel() {
      $state.go('registerMeterReading');
    }

    // enumService Data
    function getPaymentMethods() {
      enumService.getPaymentMethods().then(function(data){
        vm.paymentMethods = data.plain();

        // if not set preselect automatic payments
        if(vm.data.paymentMethod.payment_method.value === null && vm.data.paymentMethod.payment_method.label === null) {
          vm.data.paymentMethod.payment_method = vm.paymentMethods[0];
        }

        // if set set selected
        if(vm.data.paymentMethod.payment_method.value !== null && vm.data.paymentMethod.payment_method.label !== null) {
          for(var i = 0; i < vm.paymentMethods.length; i++){
            if(vm.data.paymentMethod.payment_method.value === vm.paymentMethods[i].value){
              vm.data.paymentMethod.payment_method = vm.paymentMethods[i];
              break;
            }
          }
        }
      });
    }

    function filterFrequencies() {
      vm.paymentFrequency = _paymentFrequency.filter(function(item){
        if(vm.data.paymentMethod.payment_method.value === 'automatic_collection'){
          return item.value === '01' || item.value === '03';
        } else {
          return item.value === '03';
        }
      });
    }

    function getPaymentFrequency() {
      enumService.getPaymentFrequency().then(function(data){
        var _data = data.plain();

        // remove frequncy 02 & 04
        _paymentFrequency = _data.filter(function(item){
          return item.value !== '02' && item.value !== '04'
        });

        filterFrequencies();

        // if set set selected
        if(vm.data.paymentMethod.payment_frequency.value !== null && vm.data.paymentMethod.payment_frequency.label !== null) {
          for(var i = 0; i < _paymentFrequency.length; i++){
            if(vm.data.paymentMethod.payment_frequency.value === _paymentFrequency[i].value){
              vm.data.paymentMethod.payment_frequency = _paymentFrequency[i];
              break;
            }
          }
        }

      });
    }

    function getPaymentDates() {
      enumService.getPaymentDates().then(function(data){
        _paymentDates = data.plain();

        if(vm.data.paymentMethod.payment_frequency.value !== null && vm.data.paymentMethod.payment_frequency.label !== null) {
          filterPaymentDays();
        }
      });

    }

    function filterPaymentDays() {
      if(vm.data.paymentMethod.payment_frequency.value !== '01'){
        vm.paymentDates = _paymentDates.filter(function(item){
          return item.value === '14D';
        });
        vm.data.paymentMethod.payment_date = vm.paymentDates[0];
      } else {
        vm.paymentDates = _paymentDates;

        if(vm.data.paymentMethod.payment_date.value !== null && vm.data.paymentMethod.payment_date.label !== null) {
          for(var k = 0; k < vm.paymentDates.length; k++){
            if(vm.data.paymentMethod.payment_date.value === vm.paymentDates[k].value){
              vm.data.paymentMethod.payment_date = vm.paymentDates[k];
              break;
            }

          }
        }

      }
    }

    var unregisterPaymentMethodWatcher  = $scope.$watch(function() {return vm.data.paymentMethod.payment_method;}, function(newValue, oldValue) {
      filterFrequencies();
      if(newValue.value === 'bank_transfer') {
        for(var i=0; i < vm.paymentFrequency.length; i++) {
          if(parseInt(vm.paymentFrequency[i].value) === 3) {
            vm.data.paymentMethod.payment_frequency = vm.paymentFrequency[i];
            break;
          }
        }
      }
    });

    $scope.$on('$destroy', unregisterPaymentMethodWatcher);

    function activate(){
      getPaymentMethods();
      getPaymentFrequency();
      getPaymentDates();
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }

    activate();

  }

  angular
    .module('wml.portal.register')
    .controller('RegisterPaymentMethodController', RegisterPaymentMethodController);
})();
