(function() {
  'use strict';

  /** @ngInject */
  function error404Service(RestangularExt) {

    var url = '/instellingen/error-404';
    var _cache = null;

    function getData(){
      if(_cache === null){
        _cache = RestangularExt.all('proxy').customGET(null, {url: url});
      }
      return _cache;
    }

    return {
      getData : getData
    }

  }

  angular.module('wml.portal.404')
    .factory('error404Service', error404Service);

})();
