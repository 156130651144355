(function() {
  'use strict';

  /** @ngInject */
  function SignUpThankYouController($translate, $rootScope) {
    var vm  = this;

    // private variables

    // Public variables
    vm.text = $translate.instant('message.backoffice.1013');

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');


    // Public function definitions


    // Public functions

    // Private functions

    activate();

    function activate() {

    }
  }

  angular
    .module('wml.portal.signup')
    .controller('SignUpThankYouController', SignUpThankYouController);
})();
