(function() {
  'use strict';

  /** @ngInject */
  function scrollHeader($window) {
    return {
      restrict  : 'A',
      scope: {
        'offsetTop'         : '@offsetTop',
        'offsetTopMobile'   : '@offsetTopMobile',
        'navOffset'         : '@navOffset',
        'fadeTime'          : '@fadeTime',
        'fadeTimeMobile'    : '@fadeTimeMobile'
      },
      link: function(scope, elm, attrs, ctrl) {
        var _topOffset  = scope.offsetTop;
        var _fadeTime   = scope.fadeTime;

        var _screen = angular.element($window);

        function _adjustOffset(){
          if($window.innerWidth < 992){
            scope.offsetTop = scope.offsetTopMobile;
            scope.fadeTime  = scope.fadeTimeMobile;
          } else {
            scope.offsetTop = _topOffset;
            scope.fadeTime  = _fadeTime;
          }
        }

        function _adjustScrollClasses() {
          var _currentOffset = $window.pageYOffset;
          if (_currentOffset >= scope.fadeTime && scope.fadeTime !== null) {
            elm.addClass("fadeout");
          } else {
            elm.removeClass("fadeout");
          }

          if (_currentOffset >= scope.offsetTop) {
            elm.addClass("min");
          } else {
            elm.removeClass("min");
          }

          if (_currentOffset >= scope.navOffset) {
            elm.addClass("movenav");
          } else {
            elm.removeClass("movenav");
          }
        }

        _screen.on("resize scroll", function() {
          _adjustOffset();
          _adjustScrollClasses();
        });

        _adjustOffset();
        _adjustScrollClasses();
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('scrollHeader', scrollHeader);
})();
