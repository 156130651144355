(function () {
  'use strict';

  /** @ngInject */
  function meterReadingRecordingNumberController($state, $stateParams, meterReadingService, $rootScope, storage) {
    var vm = this;
    var _state = 'meterReadingRecording';
    var _stateParams = {};

    // private variables

    // Public variables
    vm.form = null;
    vm.sendingData = false;

    vm.data = meterReadingService.data;

    if (angular.isDefined($stateParams.assignment_number)) {
      vm.data.credentials.assignment_number = $stateParams.assignment_number;
    }

    if (angular.isDefined($stateParams.meter_reading)) {
      _state = 'meterReadingRecordingExtReading';
      vm.data.credentials.meter_reading = $stateParams.meter_reading;
      _stateParams.meter_reading = $stateParams.meter_reading;
    }

    // Public function definitions
    vm.next = next;

    // Public functions
    function next() {
      vm.form.$commitViewValue();

      if (vm.form.$valid) {
        vm.sendingData = true;
        meterReadingService.verifyReading(vm.data.credentials).then(function (data) {
          // check if isCompany and isCorporation and storage those - later in summary needed to show/hide checkbox
          if (angular.isDefined(data.isCompany)) {
            storage('company', data.isCompany);
          }
          if (angular.isDefined(data.isCorporation)) {
            storage('corporation', data.isCorporation);
          }
          meterReadingService.storeData();
          _stateParams.assignment_number = vm.data.credentials.assignment_number;
          _stateParams.postal_code = vm.data.credentials.postal_code;
          $state.go(_state, _stateParams);
        }).finally(function () {
          vm.sendingData = false;
        });
      }
    }

    // Private functions
    activate();

    function activate() {
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }
  }

  angular
    .module('wml.portal.meterReading')
    .controller('meterReadingRecordingNumberController', meterReadingRecordingNumberController);
})();
