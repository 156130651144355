(function() {
  'use strict';

  /** @ngInject */
  function ListFaqController(faqService, cmsService, $rootScope) {
    var vm  = this;
    // private variables

    // Public variables
    vm.faqList   = [];
    vm.faqCategories = [];
    vm.topFaqs = [];

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    // Private functions
    function getFaqsFromURI(){
      //call service function to get FAQs
      faqService.getFaqs().then(function(response){
        vm.faqList = response.Faqs;
        createCategoryList();
        createTopFaqsList();
        cmsService.setSEO(response);
      });
    }

    vm.filterOnCategory = function(category){
      return function(item){
        for(var i = 0; i < item.categories.length; i++){
          if (item.categories[i].name === category){
            return true;
          }
        }
      }
    };

    function createCategoryList(){
      angular.forEach(vm.faqList, function(item, key){
        for(var i = 0; i < item.categories.length; i++){
          if (_.findIndex(vm.faqCategories, item.categories[i]) === -1){
            vm.faqCategories.push(item.categories[i]);
          }
        }
      });
      _.sortBy(vm.faqCategories, 'name');
    }

    function createTopFaqsList(){
      vm.topFaqs = vm.faqList.filter(function(item){
        return item.top_question === '1';
      });
    }

    activate();

    function activate(){
      getFaqsFromURI();
    }
  }

  angular
    .module('wml.portal.faq')
    .controller('ListFaqController', ListFaqController);
})();
