(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterMeterReadingController($document, $timeout, $rootScope, $state, $stateParams, $scope, $filter, calendarsService, premiseUnregisterService, customerService, storage, ivAuthenticate, Upload, meterReadingService, MOVE_REASON) {

    var vm = this;

    // private variables
    var _correspondenceAddress  = null;
    var _chosenPremise          = null;
    var _state                   = '';

    // Public variables
    vm.form             = null;
    vm.data             = premiseUnregisterService.data;
    vm.consumption      = null;

    vm.currentMeterReading = false;

    vm.showDatepicker = false;
    vm.datepickerFormat = "dd-MM-yyyy";
    vm.datepickerCurrentMonth = new Date();
    vm.datepickerOptions = {
      customClass: datePickerClass,
      minDate: null,
      maxDate: null
    };

    vm.fileToUpload = null;
    vm.sendingData  = false;

    // Public function definitions
    vm.next                          = next;
    vm.cancel                        = cancel;
    vm.openDatepicker                = openDatepicker;
    vm.fileChange                    = fileChange;

    $scope.$on('datepicker.monthChanged', function (event, newVal) {
      vm.datepickerCurrentMonth = newVal;
    });

    $scope.$on('datepicker.format', function (event, mode) {
      datepickerFormat(mode);
    });

    function datepickerFormat(mode) {
      if (mode === 'day') {
        $timeout(function () {
          var uibWeeks = $document[0].getElementsByClassName('uib-weeks');
          for (var i = 0; i < uibWeeks.length; i += 1) {
            var uibBans = uibWeeks[i].getElementsByClassName('uib-ban');
            if (uibBans.length === 7) {
              uibWeeks[i].style.display = 'none';
            }
            if (uibBans.length !== 7) {
              uibWeeks[i].style.display = 'table-row';
            }
          }
        }, 100);
      }
    }

    function datePickerClass(data) {
      var date = data.date;
      var mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        if (new Date(dayToCheck).getMonth() !== vm.datepickerCurrentMonth.getMonth()) {
          if (new Date(dayToCheck) > vm.datepickerCurrentMonth) {
            return 'uib-ban';
          }
          return 'uib-nope';
        }
        return 'uib-yeah';
      }
      return '';
    }

    function openDatepicker() {
      vm.datepickerCurrentMonth = (vm.data.meter_reading.date) ? vm.data.meter_reading.date : new Date();
      vm.showDatepicker = true;
      datepickerFormat('day');
    }

    function getCalendarSettingsFromImanager() {
      calendarsService.getCalendars('premisesUnregister').then(function (response) {
        var max = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.meterReadingMaxDateCorporation : response.meterReadingMaxDate);
        var min = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.meterReadingMinDateCorporation : response.meterReadingMinDate);
        vm.datepickerOptions.maxDate = (isNaN(max)) ? null : new Date(new Date().setDate(new Date().getDate() + max));
        vm.datepickerOptions.minDate = (isNaN(min)) ? null : new Date(new Date().setDate(new Date().getDate() - min));
      });
    }

    function fileChange(file, errFiles) {
      $rootScope.$broadcast('wml.portal.message.cancel');
      if (errFiles.length > 0) {
        if (errFiles[0].$error === "maxSize") {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        } else {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        }
      }
      if (file) {
        vm.data.meter_reading.file_upload = null;
        vm.fileToUpload = file;
      }
    }

    function checkMeterReadingSuccess(response) {
      if (angular.isObject(response) && angular.isDefined(response.code) && (response.code === 11003 || response.code === 11004)) {
        $rootScope.$broadcast('wml.portal.message', response);
        vm.data.forced_meter_reading = true;
        premiseUnregisterService.storeData();
        $state.go('premisesUnregisterMeterReading', {meterError: response.code});
      } else {
        $state.go(_state);
      }
    }

    function checkMeterReading() {
      meterReadingService.meterReadingCheck(vm.data.credentials.postal_code, vm.data.meter_reading.meter_reading, vm.data.meter_reading.date, vm.data.address.contract_id, vm.data.address.device.id, MOVE_REASON.MOVE_OUT).then(function (response) {
        checkMeterReadingSuccess(response);
      }).catch(function (error) {
        if (error === null) {
          return;
        }

        var params = (angular.isDefined(error.fields)) ? error.fields : {};

        if (angular.isDefined(error.code)) {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
        } else {
          // Notification, no mail address available to send confirmation (12006)
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12006'});
        }
      }).finally(function () {
        vm.sendingData = false;
      });
    }

    function next() {
      vm.sendingData = true;
      if (vm.form.$valid) {
        Upload.base64DataUrl(vm.fileToUpload)
          .then(function (dataUrl) {
            if (dataUrl) {
              vm.data.meter_reading.file_upload = {
                file: dataUrl,
                name: vm.fileToUpload.name,
                type: vm.fileToUpload.type
              };
            }
          })
          .finally(function () {
            if (_correspondenceAddress === _chosenPremise && !storage('corporation')) {
              vm.data.correspondence.bSetNew = true;
              _state = 'premisesUnregisterCorrespondenceAddress';
            } else {
              vm.data.correspondence.bSetNew = false;
              _state = (ivAuthenticate.isAuthenticated()) ? 'premisesUnregisterSummary' : 'premisesUnregisterEmail';
            }
            premiseUnregisterService.storeData();
            if (vm.data.forced_meter_reading) {
              $state.go(_state);
            } else {
              checkMeterReading();
            }
          });
      }
    }

    function cancel() {
      $state.go('premisesUnregister');
    }

    // Private functions
    function getAddressesSuccess(response) {
      _correspondenceAddress = response.house_no + response.house_no_addition + response.postal_code;
      _chosenPremise = vm.data.address.address.house_no + vm.data.address.address.house_no_addition + vm.data.address.address.postal_code;
    }

    function watchMeterNumbers(){
      vm.aNumberOfMeterNumbers  = [];
      for(var i=0; i < vm.numberOfMeterNumbers; i++){
        vm.aNumberOfMeterNumbers[i] = i + 1;
      }
      vm.currentMeterReading = vm.data.meter_reading.meter_reading;

      //meter reading
      $scope.$watch(function() {return vm.data.meter_reading.meter_reading;}, function(newValue) {
        if(angular.isString(newValue)) {
          if (vm.data.meter_reading.meter_reading && vm.currentMeterReading && vm.currentMeterReading !== newValue) {
            vm.data.forced_meter_reading = false;
            vm.currentMeterReading = vm.data.meter_reading.meter_reading;
          }
          var data    = newValue.split('');
          var length  = data.length;
          if(length < vm.numberOfMeterNumbers) {
            for(var j=0; j < (vm.numberOfMeterNumbers-length); j++) {
              data.unshift('0');
            }
          }
          for(var k=0; k < data.length; k++) {
            var key = 'num' + (k+1);
            vm.data.meter_reading[key]  = data[k];
          }
        } else {
          for(var l=1; l <= vm.numberOfMeterNumbers; l++) {
            vm.data.meter_reading['num' + l]  = 0;
          }
        }
      });
    }

    activate();

    function activate() {

      getCalendarSettingsFromImanager();

      if(vm.data.meter_reading.date !== null){
        vm.data.meter_reading.date = new Date(vm.data.meter_reading.date);
      }

      if (vm.data.forced_meter_reading && ($stateParams.meterError === 12004 || $stateParams.meterError === 12008 || $stateParams.meterError === 11004 || $stateParams.meterError === 11003)) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 12004});
      }

      customerService.getAddress().then(getAddressesSuccess);

      if (storage('corporation') && angular.isObject(storage('corporation_address'))) {
        vm.numberOfMeterNumbers = 5;
        watchMeterNumbers();

      } else  {
        customerService.getPremise(vm.data.address.premise_id).then(function(response){
          vm.numberOfMeterNumbers = response.device.pre_decimal || 5;
          watchMeterNumbers();
        });

        customerService.getConsumption(vm.data.address.contract_account_id, vm.data.address.contract_id).then(function(response){
          for(var i=0; i < response[0].meter_reading_results.length; i++) {
            if(response[0].meter_reading_results[i].is_open === false) {
              response[0].meter_reading_results[i].reading_date_time  = $filter('date')(response[0].meter_reading_results[i].reading_date_time, 'longDate');
              vm.consumption = response[0].meter_reading_results[i];
              break;
            }
          }
        });
      }
    }
  }

  angular
    .module('wml.portal.premisesUnregister')
    .controller('PremisesUnregisterMeterReadingController', PremisesUnregisterMeterReadingController);
})();
