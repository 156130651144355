(function () {
  'use strict';

  function waterhardheidService(RestangularExt){

    function getHardness(postalCode, houseNumber){
      return RestangularExt.all('waterhardheid').customGET(null, {'postal_code' : postalCode, 'house_number': houseNumber});
    }

    return {
      getHardness : getHardness
    }
  }


  angular
    .module('wml.portal.waterhardheid')
    .factory('waterhardheidService', waterhardheidService)
})();
