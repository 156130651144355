(function() {
  'use strict';

  /** @ngInject */
  function StandpipeController(cmsService, detailService, $rootScope, enumService, standpipeService) {
    // private variables
    var vm  = this;

    // public variables
    vm.sendingData        = false;
    vm.form               = null;
    vm.formData           = {
      company:{
        company_name:'',
        client_name:''
      },
      billing:{
        street:'',
        house_no:'',
        postal_code:'',
        city:'',
        house_no_addition:'',
        phone:'',
        email:'',
        invoice_electronic:true
      },
      location:{
        phone:'',
        contact_person:'',
        street:'',
        postal_code:'',
        city:'',
        house_no:'',
        hydrant:'',
        message:'',
        house_no_addition:''
      },
      duration: {
        start_date: "",
        start_timeslot: "",
        end_date: "",
        end_timeslot: ""
      },
      capacity:'',
      treatment:'',
      consumption:'',
      accept_rates: '',
      accept_conditions:''
    };

    vm.bottomBlock        = {};
    vm.startTimeslot      = null;
    vm.endTimeslot        = null;
    vm.capacity           = null;
    vm.formType           = null;
    vm.bShowForm          = true;
    vm.readOnlyCapacity   = false;

    // datepickers
    vm.showDatepickerStart       = false;
    vm.showDatepickerEnd         = false;
    vm.datepickerFormat          = "dd-MM-yyyy";
    vm.datepickerOptions         = {
      minDate : new Date(),
      dateDisabled : disabled
    };

    // Public function definitions
    vm.openDatepickerStart    = openDatepickerStart;
    vm.openDatepickerEnd      = openDatepickerEnd;
    vm.send                   = send;

    // Public functions
    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    function openDatepickerStart() {
      vm.showDatepickerStart = true;
    }

    function openDatepickerEnd() {
      vm.showDatepickerEnd = true;
    }

    function send(){
      if(vm.form.$valid) {
        vm.sendingData = true;

        standpipeService.send(vm.formData)
          .then(function () {
            vm.bShowForm = false;
          })
          .finally(function () {
            vm.sendingData = false;
          });
      }
    }



    // Private functions
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    activate();

    function activate() {
      cmsService.loadData().then(function(data) {
        vm.data = data;

        if(angular.isDefined(data.Content.form) && data.Content.form.index){
          vm.formType = data.Content.form.index;
          vm.formData.type = vm.formType;

          enumService.getStandpipe(vm.formType, 'start_timeslot').then(function (response) {
            vm.startTimeslot = response.plain();
          });

          enumService.getStandpipe(vm.formType, 'end_timeslot').then(function (response) {
            vm.endTimeslot = response.plain();
          });

          enumService.getStandpipe(vm.formType, 'capacity').then(function (response) {
            vm.capacity = response.plain();

            if(vm.capacity.length === 1){
              vm.formData.capacity = vm.capacity[0].value;
              vm.readOnlyCapacity  = true;
            }
          });
        }

        cmsService.setSEO(data);

        $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
      });

      detailService.getBlockContent().then(function(response){
        vm.bottomBlock = response.plain();
      });

    }
  }

  angular
    .module('wml.portal.standpipe')
    .controller('StandpipeController', StandpipeController);
})();
