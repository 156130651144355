(function () {
  'use strict';

  /** @ngInject */
  function enumService($rootScope, RestangularExt) {
    var _enumRoute = RestangularExt.all('enum');

    var cacheServiceName  = 'enum';
    var cache = {
      salutations         : null,
      insertions          : null,
      countries           : null,
      addressTypes        : null,
      paymentMethods      : null,
      paymentFrequency    : null,
      paymentDates        : null,
      weekdays            : null,
      dayparts            : null,
      contactform         : null,
      businesscontactform : null,
      standpipe           : null,
      sendcontrol         : null
    };

    $rootScope.$on("clear-cache", function(evt, evtData) {
      var key;
      if(angular.isObject(evtData)) {
        if(evtData[cacheServiceName]) {
          if(typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for(key in cache) {
              cache[key] = null;
            }
          } else if(angular.isObject(evtData[cacheServiceName])) {
            for(key in cache) {
              if(typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key]  = null;
              }
            }
          }
        }
      } else {
        for(key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      getSalutations                  : getSalutations,
      getCountries                    : getCountries,
      getAddressTypes                 : getAddressTypes,
      getPaymentMethods               : getPaymentMethods,
      getPaymentFrequency             : getPaymentFrequency,
      getPaymentDates                 : getPaymentDates,
      getWeekdays                     : getWeekdays,
      getDayparts                     : getDayparts,
      getContactFormSubjects          : getContactFormSubjects,
      getBusinessContactFormSubjects  : getBusinessContactFormSubjects,
      getInsertions                   : getInsertions,
      getStandpipe                    : getStandpipe,
      getSendControl                  : getSendControl
    };

    function getSalutations() {
      if(cache.salutations === null){
        cache.salutations = _enumRoute.one('salutation').getList();
      }
      return cache.salutations;
    }

    function getCountries() {
      if(cache.countries === null){
        cache.countries = _enumRoute.one('country').getList();
      }
      return cache.countries;
    }

    function getAddressTypes() {
      if(cache.addressTypes === null){
        cache.addressTypes = _enumRoute.one('address').getList();
      }
      return cache.addressTypes;
    }

    function getPaymentMethods() {
      if(cache.paymentMethods === null){
        cache.paymentMethods = _enumRoute.one('paymentmethod').getList();
      }
      return cache.paymentMethods;
    }

    function getPaymentFrequency() {
      if(cache.paymentFrequency === null){
        cache.paymentFrequency = _enumRoute.one('paymentfrequency').getList();
      }
      return cache.paymentFrequency;
    }

    function getPaymentDates() {
      if(cache.paymentDates === null){
        cache.paymentDates = _enumRoute.one('paymentdate').getList();
      }
      return cache.paymentDates;
    }

    function getWeekdays() {
      if(cache.weekdays === null){
        cache.weekdays = _enumRoute.one('weekday').getList();
      }
      return cache.weekdays;
    }

    function getDayparts() {
      if(cache.dayparts === null){
        cache.dayparts = _enumRoute.one('timeslot').customGET();
      }
      return cache.dayparts;
    }

    function getContactFormSubjects() {
      if(cache.contactform === null) {
        cache.contactform = _enumRoute.one('contactform').customGET();
      }
      return cache.contactform;
    }

    function getBusinessContactFormSubjects() {
      if(cache.businesscontactform === null) {
        cache.businesscontactform = _enumRoute.one('businesscontactform').customGET();
      }
      return cache.businesscontactform;
    }

    function getInsertions() {
      if(!cache.insertions) {
        cache.insertions  = RestangularExt.all('enum/insertion').getList();
      }

      return cache.insertions;
    }

    function getStandpipe(type, fieldname) {
      var cacheName = type + fieldname;

      if(!cache[cacheName]) {
        cache[cacheName]  = RestangularExt.all('enum/standpipe/' + type + '|' + fieldname).getList();
      }

      return cache[cacheName];
    }

    function getSendControl() {
      if(!cache.sendcontrol) {
        cache.sendcontrol  = RestangularExt.all('enum/sendcontrol').getList();
      }

      return cache.sendcontrol;
    }
  }


  angular.module('wml.portal.enum')
    .factory('enumService', enumService);

})();
