(function() {
  'use strict';

  /** @ngInject */
  function CustomerEditController($rootScope, $scope, customerService, contactPersonService, enumService, billingInformationService, $filter, moment, $state, $timeout) {
    var vm  = this;

    // private variables
    var SEND_CONTROL  = {
      MAIL    : 0,
      DIGITAL : 3
    };

    // Public variables
    vm.data                       = {};
    vm.form                       = null;
    vm.salutations                = [];
    vm.dateNow                    = moment().subtract(18, 'years').format('DD-MM-YYYY');
    vm.sendingData                = false;
    vm.contactPersonData          = {general: false, administration: false, incidents: false, newsletter: false};

    // Public function definitions
    vm.save   = save;
    vm.cancel = cancel;

    // Public functions
    function save(){
      vm.form.$commitViewValue();
      if(vm.form.$valid) {
        vm.sendingData  = true;

        vm.data.email.update_account_email = Boolean(vm.data.email.update_account_email);

        vm.data.save().then(function(response) {
          $rootScope.$broadcast('clear-cache', {customer : {customer: true}});

          billingInformationService.updateSendControl(vm.data.sendcontrol).then(function(response) {
            $state.go('customerSaved');
            vm.sendingData = false;
            return response;
          });

          return response;
        }).finally(function () {
          vm.sendingData = false;
        });
      }
    }

    function cancel(){
      $rootScope.$broadcast('clear-cache', {customer : {customer: true}});
      $state.go('customer');
    }

    // Private functions
    function getSalutations () {
      enumService.getSalutations().then(function(data) {
        vm.salutations = data;
      });
    }

    function getCustomer(){
      customerService.getCustomer().then(getCustomerSuccess)
    }

    function getCustomerSuccess(response) {
      response.date_of_birth = $filter('date')(response.date_of_birth, "dd-MM-yyyy");
      vm.data = response;
      $timeout(function() {
        vm.form.$commitViewValue();
      }, 0);

      getSendControlOptions();
      getSendControl();
    }

    function getSendControlOptions() {
      enumService.getSendControl().then(sendControlOptionsSuccess);
    }

    function sendControlOptionsSuccess(response) {
      vm.sendcontrol  = response;
    }

    function getSendControl() {
      billingInformationService.getSendControl().then(sendControlSuccess);
    }

    function sendControlSuccess(response) {
      vm.data.sendcontrol = response.sendcontrol;
    }

    function getAdministrationContactPerson() {
      contactPersonService.getAdministrationContactPerson().then(function(response) {

        vm.data.billing_email = response.email.correspondence;
        vm.data.relation_account_id = response.customer_number;

        var updatedRecords  = contactPersonService.getUpdated();
        for(var key in updatedRecords) {
          if(updatedRecords[key].relation_type === 'administration') {
            vm.contactPersonData['administration']  = updatedRecords[key];
            if(angular.isString(updatedRecords[key].email)){
              vm.data.billing_email  = updatedRecords[key].email;
            }
            if(angular.isObject(updatedRecords[key].email) && angular.isString(updatedRecords[key].email.correspondence)){
              vm.data.billing_email  = updatedRecords[key].email.correspondence;
            }
          }
        }
      });
    }

    activate();

    function activate(){
      getSalutations();
      getCustomer();
      getAdministrationContactPerson();
    }

    var unregister  = $scope.$watch(function() {return vm.data.sendcontrol;}, function(newValue) {
      vm.data.is_digital  = (newValue === SEND_CONTROL.DIGITAL);
      if(vm.data.is_digital === false) {
        vm.data.billing_email = null;
      }
    });

    $scope.$on('$destroy', function() {
      $rootScope.$broadcast('clear-cache', {customer : {customer: true}});
      unregister();
    });
  }

  angular
    .module('wml.portal.customer')
    .controller('CustomerEditController', CustomerEditController);
})();
