(function() {
  'use strict';

  /** @ngInject */
  function LoginController($state, $http, $rootScope, $timeout, profileService, RestangularExt, storage, $location, activationService, ivAuthenticate) {
    var vm  = this;

    // private variables

    // Public variables
    vm.form = null;
    vm.sendingData  = false;

    // Public function definitions
    vm.doLogin = doLogin;

    // Public functions

    function doLogin(){
      vm.form.$commitViewValue();
      if(vm.form.$valid) {

        $rootScope.$broadcast('wml.portal.message.cancel');
        $rootScope.$broadcast('wml.loader.start');

        vm.sendingData = true;
        var credentials = {
          email       : vm.form.email,
          password    : vm.form.password
        };

        ivAuthenticate.login(credentials).then(function(response) {
          var claims  = ivAuthenticate.getJWTClaims();

          if (angular.isDefined(claims)) {
            if (angular.isDefined(claims.corporation)) {
              storage('corporation', claims.corporation);
            }
            if (angular.isDefined(claims.company)) {
              storage('company', claims.company);
            }
          }

          if(!storage('customer_number', response.customer_number) && (response.data.accounts && response.data.accounts.length > 1)) {
            if(angular.isDefined(claims) && angular.isDefined(claims.aud)) {
              storage('customer_number', claims.aud);
            }
            $state.go('accountOptions');
          } else {
            profileService.getProfile().then(getProfileSuccess);
          }

        },function (error) {
          if(error === null){
            return;
          }

          var params  = (angular.isDefined(error.fields)) ? error.fields : {};

          if(angular.isDefined(error.code)) {
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params : params});
          } else {
            $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1002'});
          }
        }).finally(function(){
          $timeout(function() {
            $rootScope.$broadcast('wml.loader.stop');
          }, 1000);

          vm.sendingData = false;
        });
      } else {
        $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code:'1001'});
      }
    }

    function getProfileSuccess(response) {
      RestangularExt.setDefaultHeaders(angular.extend(RestangularExt.configuration.defaultHeaders, {'X-Customer-Number': response.customer_number}));
      $http.defaults.headers.common['X-Customer-Number']  = response.customer_number;
      storage('customer_number', response.customer_number);
      $rootScope.$broadcast('clear-cache');

      if(storage('corporation') === true){
        $state.go('chooseAddress');
      } else {
        $state.go('dashboard');
      }
    }


    // Private functions

    function verifyActivation() {
      if($state.current.name === 'loginActivate'){
        var location = $location.search();
        if(angular.isDefined(location.e) && angular.isDefined(location.a) && angular.isDefined(location.r) && angular.isDefined(location.t) && angular.isDefined(location.h) && angular.isDefined(location.p)){
          activationService.activate(location).then(function(response){
            if(angular.isDefined(response.type) && response.type === 'message'){
              $rootScope.$broadcast('wml.portal.message', response);
            }
          })
        }
      }
    }

    activate();

    function activate() {
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
      verifyActivation();
    }

  }

  angular
    .module('wml.portal.login')
    .controller('LoginController', LoginController);
})();
