(function() {
  'use strict';

  /** @ngInject */
  function FaqController($state, faqService) {
    var vm  = this;
    // private variables

    // Public variables
    vm.bShowOtherQuestionsBlock   = false;
    vm.faqList                    = [];

    // Public function definitions

    // Public functions


    // Private functions
    function getFaqsFromURI(){
      //set stateName to filter FAQs
      var stateName = $state.current.name;

      //call service function to get FAQs
      faqService.getFaqs().then(function(response){
        vm.faqList = response.Faqs.filter(function(item) {
          return (item.states.indexOf(stateName) !== -1);
        });

      });
    }

    activate();

    function activate(){
      getFaqsFromURI();

      if(angular.isDefined($state.current.data) && angular.isDefined($state.current.data.bShowOtherQuestionsBlock)){
        vm.bShowOtherQuestionsBlock = $state.current.data.bShowOtherQuestionsBlock;
      } else {
        vm.bShowOtherQuestionsBlock = true;
      }
    }
  }

  angular
    .module('wml.portal.faq')
    .controller('FaqController', FaqController);
})();
