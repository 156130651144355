(function() {
  'use strict';

  /** @ngInject */
  function footerService(RestangularExt, navigationService, $q) {

    var _footer = null;

    return {
      loadData  : loadData
    };

    function loadData() {
      if(_footer === null) {
        var deferred  = $q.defer();

        navigationService.load().then(function() {
          RestangularExt.all('proxy').customGET(null, {url  : '/instellingen/footer'}).then(function(response) {
            for(var i in response) {
              if(response.hasOwnProperty(i)){
                for(var j in response[i]) {
                  if(response[i].hasOwnProperty(j)){
                    if(angular.isArray(response[i][j])) {
                      response[i][j]  = response[i][j].map(function(item) {
                        item.name = navigationService.getStateByUrl(item.link);
                        return item;
                      });
                    }
                  }
                }
              }
            }
            deferred.resolve(response);
          });
        });

        _footer = deferred.promise;
      }

      return _footer;
    }

  }

  angular.module('wml.portal.footer')
    .factory('footerService', footerService);

})();
