(function() {
  'use strict';

  /** @ngInject */
  function searchService(RestangularExt) {

    return {
      search      : search
    };

    function search(query) {
      query = encodeURIComponent(query);
      return RestangularExt.oneUrl('bingcustomsearch', 'https://api.cognitive.microsoft.com/bingcustomsearch/v7.0/search?q='+query+'&customconfig=3578062967&responseFilter=Webpages&mkt=nl-NL&safesearch=Moderate').withHttpConfig({withCredentials : false}).get(null, {
        'Ocp-Apim-Subscription-Key': 'e55e549acc884c20aa600d4d1e76d3e2',
        'Authorization': ''});
    }

  }

  angular.module('wml.portal.search')
    .factory('searchService', searchService);

})();
