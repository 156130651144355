(function() {
  'use strict';

  /** @ngInject */
  function homeService(RestangularExt) {

    var url = '/home';
    var _cache = null;

    function getData(){
      if(_cache === null){
        _cache = RestangularExt.all('proxy').customGET(null, {url: url});
      }
      return _cache;
    }

    return {
      getData : getData
    }

  }

  angular.module('wml.portal.home')
    .factory('homeService', homeService);

})();
