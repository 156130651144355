(function() {
  'use strict';

  /** @ngInject */
  function HeaderMainController($rootScope, $uibModal, $scope, $window, storage, $state, ivAuthenticate, customerService, navigationService, incidentService, headerService, $location) {
    var vm  = this;
    // private variables

    // Public variables
    vm.customers            = null;
    vm.currentCustomer      = null;
    vm.isNavCollapsed       = true;
    vm.isSearchCollapsed    = true;
    vm.isInsecureState      = ivAuthenticate.isInsecureState($state.current.name);
    vm.isShortTermState     = ivAuthenticate.isShortTermState($state.current.name);
    vm.meterReadingForm     = null;
    vm.meterReadingData     = {};
    vm.headerContent        = null;
    vm.menuItems            = navigationService.getMenu.header;
    vm.searchLink           = $state.href("search");
    vm.searchQuery          = '';
    vm.bCorporation         = storage('corporation') || false;
    vm.activeSlide          = 0;
    vm.intervalSlide        = 6000;

    // Public function definitions
    vm.changeCustomer     = changeCustomer;
    vm.logout             = logout;
    vm.showIncidents      = showIncidents;
    vm.goToIncident       = goToIncident;
    vm.goToMeterReading   = goToMeterReading;
    vm.search             = search;
    vm.getMenuItems       = getMenuItems;
    vm.openVideoModal     = openVideoModal;

    function openVideoModal(videoUrl){
      vm.intervalSlide = -1;

      $scope.modal  = vm;
      $scope.videoUrl  = videoUrl;

      vm.videoModal = $uibModal.open({
        templateUrl: 'app/components/header/views/partials/video.modal.partial.html',
        scope: $scope,
        size: 'lg',
        windowTopClass: 'video-modal'
      });

      vm.videoModal.result.then(function(){
        vm.intervalSlide = 6000;
      }, function(){
        vm.intervalSlide = 6000;
      })
    }

    function getMenuItems() {
      return vm.menuItems.filter(function(item) {
          return (parseInt(item.parent, 10) === 0 && item.bVisible && item.type !== 'detail' && item.name);
      });
    }

    function search(){
      $location.url(vm.searchLink).hash('q='+ vm.searchQuery);
    }


    function goToMeterReading (){
      var _number = '';
      if(angular.isDefined(vm.meterReadingData.num1)){ _number += vm.meterReadingData.num1; }
      if(angular.isDefined(vm.meterReadingData.num2)){ _number += vm.meterReadingData.num2; }
      if(angular.isDefined(vm.meterReadingData.num3)){ _number += vm.meterReadingData.num3; }
      if(angular.isDefined(vm.meterReadingData.num4)){ _number += vm.meterReadingData.num4; }
      if(angular.isDefined(vm.meterReadingData.num5)){ _number += vm.meterReadingData.num5; }

      $state.go('meterReadingExtReading', { meter_reading: _number})
    }

    navigationService.load();


    // Public functions
    function changeCustomer(customerNumber) {
      storage('customer_number', customerNumber);
      $state.go('dashboard');
    }

    function logout() {
      ivAuthenticate.logout().then(function() {
        $rootScope.$broadcast('clear-cache');
        $window.location.href = $state.href('login');
      });
    }

    // Private functions
    function getCustomers() {
      customerService.getCustomers().then(getCustomersSuccess, function() {
        vm.bAuthenticated = false;
      });
    }

    function getCustomersSuccess(response) {
      vm.customers = response;

      var currentCustomerNumber = storage('customer_number');

      vm.currentCustomer = _.find(vm.customers, function(item){
        return item.customer_number === currentCustomerNumber;
      });
    }
    activate();

    function activate(){
      headerService.loadData().then(function(response){
        vm.headerContent = response;
      });



      if(ivAuthenticate.isAuthenticated()){
        vm.bAuthenticated = true;
        getCustomers();
      } else {
        vm.bAuthenticated = false;
      }

      incidentService.load().then(handleIncidents);
    }

    function handleIncidents(response) {
      vm.incidents  = response;
    }

    function showIncidents() {
      $window.open('https://www.waterstoring.nl/wml', '_blank');
    }

    function goToIncident(link) {
      $window.open(link, '_blank');
    }
  }

  angular
    .module('wml.portal.header')
    .controller('HeaderMainController', HeaderMainController);
})();
