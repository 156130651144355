(function () {
  'use strict';

  /** @ngInject */
  function premisesRegisterMeterReadingController($document, $timeout, $rootScope, $state, $scope, $stateParams, calendarsService, premiseRegisterService, Upload, storage, meterReadingService, MOVE_REASON) {
    var vm = this;

    // private variables

    // Public variables
    vm.form = null;
    vm.data = premiseRegisterService.data;
    vm.sendingData = false;
    vm.currentMeterReading = false;
    vm.showDatepicker = false;
    vm.datepickerFormat = "dd-MM-yyyy";
    vm.datepickerCurrentMonth = new Date();
    vm.datepickerOptions = {
      customClass: datePickerClass,
      maxDate: null,
      minDate: null
    };

    vm.fileToUpload = null;

    // Public function definitions
    vm.next = next;
    vm.cancel = cancel;
    vm.openDatepicker = openDatepicker;
    vm.fileChange = fileChange;

    $scope.$on('datepicker.monthChanged', function (event, newVal) {
      vm.datepickerCurrentMonth = newVal;
    });

    $scope.$on('datepicker.format', function (event, mode) {
      datepickerFormat(mode);
    });

    function datepickerFormat(mode) {
      if (mode === 'day') {
        $timeout(function () {
          var uibWeeks = $document[0].getElementsByClassName('uib-weeks');
          for (var i = 0; i < uibWeeks.length; i += 1) {
            var uibBans = uibWeeks[i].getElementsByClassName('uib-ban');
            if (uibBans.length === 7) {
              uibWeeks[i].style.display = 'none';
            }
            if (uibBans.length !== 7) {
              uibWeeks[i].style.display = 'table-row';
            }
          }
        }, 100);
      }
    }

    function datePickerClass(data) {
      var date = data.date;
      var mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        if (new Date(dayToCheck).getMonth() !== vm.datepickerCurrentMonth.getMonth()) {
          if (new Date(dayToCheck) > vm.datepickerCurrentMonth) {
            return 'uib-ban';
          }
          return 'uib-nope';
        }
        return 'uib-yeah';
      }
      return '';
    }

    function openDatepicker() {
      vm.datepickerCurrentMonth = (vm.data.meter_reading.date) ? vm.data.meter_reading.date : new Date();
      vm.showDatepicker = true;
      datepickerFormat('day');
    }

    function getCalendarSettingsFromImanager() {
      calendarsService.getCalendars('premisesRegister').then(function (response) {
        var max = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.meterReadingMaxDateCorporation : response.meterReadingMaxDate);
        var min = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.meterReadingMinDateCorporation : response.meterReadingMinDate);
        vm.datepickerOptions.maxDate = (isNaN(max)) ? null : new Date(new Date().setDate(new Date().getDate() + max));
        vm.datepickerOptions.minDate = (isNaN(min)) ? null : new Date(new Date().setDate(new Date().getDate() - min));
      });
    }

    function fileChange(file, errFiles) {
      $rootScope.$broadcast('wml.portal.message.cancel');
      if (errFiles.length > 0) {
        if (errFiles[0].$error === "maxSize") {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        } else {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        }
      }
      if (file) {
        vm.data.meter_reading.file_upload = null;
        vm.fileToUpload = file;
      }
    }

    function next() {
      vm.sendingData = true;
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        Upload.base64DataUrl(vm.fileToUpload)
          .then(function (dataUrl) {
            if (dataUrl) {
              vm.data.meter_reading.file_upload = {
                file: dataUrl,
                name: vm.fileToUpload.name,
                type: vm.fileToUpload.type
              };
            }
          })
          .finally(function () {
            var today = new Date().setHours(0, 0, 0, 0);
            var moveInDate = new Date(vm.data.meter_reading.date).setHours(0, 0, 0, 0);
            vm.data.bIsInFuture = (moveInDate > today);
            premiseRegisterService.storeData();

            var bIsInFuture = vm.data.bIsInFuture;
            if (bIsInFuture) {
              $state.go('premisesRegisterSummary');
            } else if (vm.data.forced_meter_reading) {
              if (storage('corporation')) {
                $state.go('premisesRegisterSummary');
              } else {
                $state.go('premisesRegisterNewPremises');
              }
            } else {
              checkMeterReading();
            }
          });
      }
    }

    function checkMeterReadingSuccess(response) {
      if (angular.isObject(response) && angular.isDefined(response.code) && (response.code === 11003 || response.code === 11004)) {
        $rootScope.$broadcast('wml.portal.message', response);
        vm.data.forced_meter_reading = true;
        premiseRegisterService.storeData();
        $state.go('premisesRegisterMeterReading', {meterError: response.code});
      } else if (storage('corporation')) {
        $state.go('premisesRegisterSummary');
      } else {
        $state.go('premisesRegisterNewPremises');
      }
    }

    function checkMeterReading() {
      meterReadingService.meterReadingCheck(vm.data.address.postal_code, vm.data.meter_reading.meter_reading, vm.data.meter_reading.date, vm.data.address.contract_id, vm.data.address.device_id, MOVE_REASON.MOVE_IN).then(function (response) {
        checkMeterReadingSuccess(response);
      }).catch(function (error) {
        if (error === null) {
          return;
        }

        var params = (angular.isDefined(error.fields)) ? error.fields : {};

        if (angular.isDefined(error.code)) {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
        } else if (error.data.code === 11011) { // exception - on register give generic warning and set force on
          vm.data.forced_meter_reading = true;
          premiseRegisterService.storeData();
          $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 12004});
          $state.go('premisesRegisterMeterReading', {meterError: 12004});
        } else {
          // Notification, no mail address available to send confirmation (12006)
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12006'});
        }
      }).finally(function () {
        vm.sendingData = false;
      });
    }

    function cancel() {
      $state.go('premisesRegister');
    }

    // Private functions
    activate();

    function activate() {
      getCalendarSettingsFromImanager();
      if (vm.data.meter_reading.date !== null) {
        vm.data.meter_reading.date = new Date(vm.data.meter_reading.date);
      }

      if (vm.data.forced_meter_reading && ($stateParams.meterError === 12004 || $stateParams.meterError === 12008 || $stateParams.meterError === 11004 || $stateParams.meterError === 11003)) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 12004}); // cannot give reason, only picture
      }

      vm.currentMeterReading = vm.data.meter_reading.meter_reading;

      //meter reading
      $scope.$watch(function() {return vm.data.meter_reading.meter_reading;}, function(newValue) {
        if(angular.isString(newValue)) {
          if (vm.data.meter_reading.meter_reading && vm.currentMeterReading && vm.currentMeterReading !== newValue) {
            vm.data.forced_meter_reading = false;
            vm.currentMeterReading = vm.data.meter_reading.meter_reading;
          }

          var data    = newValue.split('');
          var length  = data.length;
          if(length < 5) {
            for(var j=0; j < (5-length); j++) {
              data.unshift('0');
            }
          }
          for(var k=0; k < data.length; k++) {
            var key = 'num' + (k+1);
            vm.data.meter_reading[key]  = data[k];
          }
        } else {
          for(var l=1; l <= 5; l++) {
            vm.data.meter_reading['num' + l]  = 0;
          }
        }
      });
    }
  }

  angular
    .module('wml.portal.premisesRegister')
    .controller('premisesRegisterMeterReadingController', premisesRegisterMeterReadingController);
})();
