(function() {
  'use strict';

  /** @ngInject */
  function dateFormat(moment) {
    return {
      require   : 'ngModel',
      restrict  : 'A',
      scope     : {
        'options'    : '=dateFormat',
        'dateString' : '=ngModel'
      },
      link  : function(scope, elem, attrs, ctrl) {
        var format = 'DD-MM-YYYY';

        if(angular.isObject(scope.options)){
          if(angular.isDefined(scope.options.format)){
            format = scope.options.format;
          }
        }


        scope.$watch('dateString', function() {
          if(angular.isDefined(scope.dateString) && angular.isString(scope.dateString) && scope.dateString.length) {
            var valid = moment(scope.dateString, format).isValid();
            var date    = moment(scope.dateString, format);

            if(angular.isObject(scope.options)){
              if(angular.isDefined(scope.options.minDate)){
                var minDate = moment(scope.options.minDate, format);

                if(date.isBefore(minDate)) {
                  valid = false;
                }
              }

              if(angular.isDefined(scope.options.maxDate)){
                var maxDate = moment(scope.options.maxDate, format);

                if(date.isSameOrAfter(maxDate)) {
                  valid = false;
                }
              }
            }
            ctrl.$setValidity('dateFormat', valid);
          } else {
            ctrl.$setValidity('dateFormat', true)
          }
        });
      }
    }
  }

  angular.module('wml.portal.generic')
    .directive('dateFormat', dateFormat)
})();
