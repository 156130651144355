(function() {
  'use strict';

  angular
    .module('wml.portal')
    .config(breadcrumbConfig);

  /** @ngInject */
  function breadcrumbConfig(breadcrumbsProvider) {
    breadcrumbsProvider
      .register({
        path    : ['home'],
        states  : ['dashboard', 'profile', 'accountOptions', 'premisesUnregisterLogin', 'premisesUnregisterMoveOutDate']
      })
      .register({
        path    : ['home', 'dashboard'],
        states  : ['customer', 'invoicesDashboard', 'usageDashboard', 'invoicesDashboardSaved', 'premisesRegisterMoveInDate']
      })
      .register({
        path    : ['home', 'profile'],
        states  : ['profilePassword', 'profileEmail']
      })
      // mijn persoonlijke gegevens
      .register({
        path    : ['home', 'dashboard', 'customer'],
        states  : ['premisesUnregisterThankYou', 'premisesUnregisterSummary', 'premisesUnregisterCorrespondenceAddress', 'premisesUnregisterMeterReading', 'premisesUnregister', 'premisesRegisterThankYou', 'premisesRegisterSummary', 'premisesRegisterNewPremises', 'premisesRegisterMeterReading', 'premisesRegister', 'premisesActionOverview', 'correspondenceAddress', 'customerEdit', 'customerSaved', 'contactPersonEdit', 'contactPersonAdd']
      })
      // mijn financiele gegevens
      .register({
        path    : ['home', 'dashboard', 'invoicesDashboard'],
        states  : ['invoicesInstallmentChanged', 'invoicesUsageCompare', 'installmentMeterReadings', 'installmentChange', 'paymentMethodEdit', 'invoicesRemaining', 'invoices']
      })
      // mijn verbruik
      .register({
        path    : ['home', 'dashboard', 'usageDashboard'],
        states  : ['installmentChanged', 'usageCompare', 'usageMeterReadings']
      })

  }

})();
