(function() {
  'use strict';

  /** @ngInject */
  function matchFilter() {
    return function(items, filter) {
      if(filter) {
        return items.filter(function(item) {
          return (item.customer_number.indexOf(filter) >= 0);
        });
      } else {
        return items;
      }
    }
  }

  angular.module('wml.portal.account')
    .filter('match', matchFilter);

})();
