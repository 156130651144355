(function(){
  'use strict';

  function damageFormController(detailService, cmsService, $rootScope, damageFormService, Upload, registry) {
    var vm = this;

    //public variables
    vm.formData     = damageFormService.data;
    vm.showForm     = true;
    vm.invalidFiles = null;

    //public function
    $rootScope.$broadcast('readspeaker.readclass', '');

    vm.addDamagedItem = addDamagedItem;
    vm.sendForm       = sendForm;
    vm.removeRow      = removeRow;

    cmsService.loadData().then(function(data) {
      vm.data = data;

      cmsService.setSEO(data);

      $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
    });

    detailService.getBlockContent().then(function(response){
      vm.bottomBlock = response.plain();
    });

    function addDamagedItem(){

      vm.formData.damagedItems.push({
        description      : '',
        purchase_date    : '',
        purchase_price   : '',
        day_value        : '',
        estimated_damage : ''
      });
    }

    function removeRow(index){
      if(index > 0){
        vm.formData.damagedItems.splice(index, 1);
      }
    }

    function sendForm(){

      if(vm.form.$valid){
        Upload.upload({
          url: registry.restApiUrl+'/form/damage',
          data : vm.formData
        }).then(function(response){
          vm.showForm = false;
          $rootScope.$broadcast('wml.portal.message', response.data);
          damageFormService.clearData();
        },function(error){
          throw new Error('damage form error:', error);
        })
      }
    }

  }
  angular
    .module('wml.portal.damage-form')
    .controller('damageFormController', damageFormController);
})();
