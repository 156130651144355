(function () {
  'use strict';

  function meterImages() {
    return {
      restrict: 'E',
      templateUrl: 'app/components/meter-readings/directives/views/meter-image.view.html'
    };
  }

  angular.module('wml.portal.meterReading')
    .directive('meterImages', meterImages);
})();
