(function() {
  'use strict';

  /** @ngInject */
  function CmsController($rootScope, $state, navigationService, $location) {
    $rootScope.$broadcast('wml.loader.start');
    var goToLoc = '';
    navigationService.load().then(function() {

      var stateName = navigationService.getStateByUrl($location.path());

      if (stateName) {
        goToLoc = stateName;
      } else if($location.path() === '/') {
        goToLoc = 'home';
      } else {
        goToLoc = 'pageNotFound';
      }
    }).finally(function () {
      $rootScope.$broadcast('wml.loader.abort');
      $state.go(goToLoc);
    });
  }

  angular.module('wml.portal.cms')
    .controller('CmsController', CmsController);

})();
