(function () {
  'use strict';

  /** @ngInject */
  function premisesRegisterController($state, addressService, premiseRegisterService, premiseUnregisterService, customerService, storage, $rootScope, premisesService, $stateParams) {

    var vm = this;

    // private variables
    var _lastAutofill = null;

    // Public variables
    vm.data = premiseRegisterService.data;
    vm.form = null;
    vm.noValidAddress = true;
    vm.loading = false;

    // Public function definitions
    vm.next = next;
    vm.getCustomer = getCustomer;
    vm.cancel = cancel;
    vm.autoFill = autoFill;
    vm.autofillHouseNo = autofillHouseNo;

    // Public functions
    function next() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        premiseRegisterService.storeData();

        var futureMoveCheck = {
          postal_code: vm.data.address.postal_code,
          house_no: vm.data.address.house_no,
          house_no_addition: vm.data.address.house_no_addition,
        };

        // always check if address has future move
        customerService.hasFutureMove(futureMoveCheck).then(function (isProspect) {
          // api Type Enum = MOVE_OUT:'O', MOVE_IN: 'I', MOVE_IN_OUT:'X';
          var moveInAlreadyExists = (isProspect.exists && (isProspect.move_in_out === 'I' || isProspect.move_in_out === 'X'));
          if (moveInAlreadyExists && !vm.data.credentials.future_move_token.length) {
            // notification: is already a prospect, contact customer service
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12103'});
            return;
          }
          // if not in future or token, go to meter reading
          var bIsInFuture = vm.data.bIsInFuture;
          var bCorrespondanceEmailKnown = vm.data.email.correspondence;
          if (!bIsInFuture || vm.data.credentials.future_move_token) { $state.go('premisesRegisterMeterReading'); }
          if (bIsInFuture) {
            if (bCorrespondanceEmailKnown) {
              $state.go('premisesRegisterSummary');
            } else {
              $state.go('premisesRegisterFutureCorrespondenceEmail');
            }
          }
        })
          .catch(function () {
            // notification: failed to check if has future move data, contact customer service.
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12104'});
            return;
          });
      }
    }

    function cancel() {
      // make decision based on future move token, go to premisses-register login
      if (vm.data.credentials.future_move_token.length) {
        $state.go('premisesRegisterLogin', {fm: vm.data.credentials.future_move_token});
      } else {
        if (storage('corporation')) {
          $state.go('customer');
        }
        $state.go('premisesRegisterMoveInDate');
      }
    }

    function autofillHouseNo() {
      if (angular.isString(vm.data.address.postal_code) && angular.isString(vm.data.address.house_no)) {
        vm.data.address.postal_code = vm.data.address.postal_code.toUpperCase();

        if (/^[0-9]{4}[A-Z]{2}$/.test(vm.data.address.postal_code.replace(/\s/g, ''))) {
          var data = {
            postal_code       : vm.data.address.postal_code,
            house_no          : vm.data.address.house_no,
            house_no_addition : null
          };

          if (angular.isString(vm.data.address.house_no_addition) && vm.data.address.house_no_addition.length > 0) {
            data.house_no_addition  = vm.data.address.house_no_addition;
          }

          var autofillKey = data.postal_code + '_' + data.house_no + '_' + data.house_no_addition;
          if (autofillKey === _lastAutofill) {
            return false;
          }
          vm.noValidAddress           = true;
          vm.loading                  = true;
          vm.data.address.city        = null;
          vm.data.address.street      = null;

          _lastAutofill = autofillKey;

          autoFill(data);
        }
      }
    }

    function getCustomer() {
      customerService.getCustomer().then(getCustomerSuccess)
    }

    function getCustomerSuccess(response) {
      vm.data.email.correspondence = response.email.correspondence;
      premiseRegisterService.storeData();
    }

    function autoFill(data) {
      addressService.autofill(data).then(function(response) {
        var address = angular.copy(response.plain());

        vm.loading          = false;
        vm.noValidAddress   = false;

        $rootScope.$broadcast('wml.portal.message.cancel');

        if(typeof address.valid_for_subscription === 'boolean' && !address.valid_for_subscription){
          vm.noValidAddress = true;
          $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1005'});
        }

        if(address.city === null){
          vm.noValidAddress = true;
          $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1004'});
        }

        if(address.street){
          vm.data.address.street = address.street;
        }
        if(address.house_no_addition){
          vm.data.address.house_no_addition = address.house_no_addition;
        }
        if(address.city){
          vm.data.address.city = address.city;
        }

        if(address.contract_id){
          vm.data.address.contract_id = address.contract_id;
        }

        if (address.meter_number) {
          vm.data.address.meter_number = address.meter_number;
        }

        if(address.premise_id){
          vm.data.address.premise_id = address.premise_id;
        }

        if (address.device_id) {
          vm.data.address.device_id = address.device_id;
        }
      });
    }

    // Private functions
    activate();

    function activate() {
      if($stateParams.clearCache === 'true'){
        premiseRegisterService.clearData();
        premiseUnregisterService.clearData();
        premisesService.clearProcesStatus();
      }

      if (angular.isDefined(vm.data.prospect.exists) && vm.data.prospect.exists && vm.data.credentials.future_move_token.length) {
        vm.data.connectionAddress = vm.data.prospect.premise;
      }

      if ((vm.data.address.postal_code && vm.data.address.house_no) || vm.data.connectionAddress) {
        vm.autofillHouseNo();
      }

      if (vm.data.address.city !== null) {
        vm.noValidAddress = false;
      }

      if (angular.isUndefined(premisesService.getProcesStatus()) || premisesService.getProcesStatus() === null) {
        premisesService.storeProcesStatus('moving_in');
      }

      if (angular.isUndefined(vm.data.credentials.grant_type) || vm.data.credentials.grant_type !== 'short_term') {
        vm.getCustomer();
      }
    }
  }

  angular
    .module('wml.portal.premisesRegister')
    .controller('premisesRegisterController', premisesRegisterController);
})();
