(function() {
  'use strict';

  /** @ngInject */
  function passwordService(RestangularExt, $q) {

    var _criteria = {
      minLength: 8,
      minCaps: 1,
      minLowercase: 1,
      minNumbers: 1,
      minSpecialChars: 1
    };

    var _serverSideSettingsLoaded = false;

    RestangularExt.all('proxy').customGET(null, {url: '/instellingen/wachtwoord-criteria'}).then(function(response) {
      Object.assign(_criteria, response);
      _serverSideSettingsLoaded = true;
    });

    var _initialValidationResult = {
      minLength: false,
      minCaps: false,
      minLowercase: false,
      minNumbers: false,
      minSpecialChars: false
    };

    return {
      getCriteria: getCriteria,
      get initialValidationResult() {
        return Object.assign({}, _initialValidationResult);
      },
      validateInput: validateInput
    };

    function getCriteria() {
      if(_serverSideSettingsLoaded) {
        return $q.resolve(_criteria)
      } else {
        var deferredObj = $q.defer();

        var interval = setInterval(function() {
          if(_serverSideSettingsLoaded === true) {
            clearInterval(interval);
            deferredObj.resolve(_criteria);
          }
        }, 200);

        return deferredObj.promise;
      }
    }

    function validateInput(input, returnAsSingleBoolean) {
      returnAsSingleBoolean = (typeof returnAsSingleBoolean === 'boolean') ? returnAsSingleBoolean : false;

      var validationResult = Object.assign({}, _initialValidationResult);

      if(!input) {
        return validationResult;
      }

      validationResult.minLength = (input.length >= _criteria.minLength);

      var caps = input.match(/[A-Z]/g);
      var capsLength = (caps) ? caps.length : 0;
      validationResult.minCaps = (capsLength >= _criteria.minCaps);

      var lowercase = input.match(/[a-z]/g);
      var lowercaseLength = (lowercase) ? lowercase.length : 0;
      validationResult.minLowercase = (lowercaseLength >= _criteria.minLowercase);

      var numbers = input.match(/[0-9]/g);
      var numbersLength = (numbers) ? numbers.length : 0;
      validationResult.minNumbers = (numbersLength >= _criteria.minNumbers);

      validationResult.minSpecialChars = ((input.length - (capsLength + lowercaseLength + numbersLength)) >= _criteria.minSpecialChars);

      if(returnAsSingleBoolean) {
        var numberOfCriteriaMet = 0;
        for(var key in validationResult) {
          if(validationResult[key] === true) {
            numberOfCriteriaMet += 1;
          }
        }
        return (numberOfCriteriaMet === Object.keys(validationResult).length)
      }

      return validationResult;
    }


  }

  angular.module('wml.portal.generic')
    .service('passwordService', passwordService)

})();
