(function () {
  'use strict';

  /** @ngInject */
  function RegisterLoginController($state, $rootScope, $timeout, $location, customerService, registerService, $stateParams, ivAuthenticate, storage, jwtHelper) {
    var vm = this;

    vm.form = null;
    vm.data = registerService.data;
    vm.sendingData = false;

    // Public function definitions
    vm.doLogin = doLogin;

    function doLogin() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        $rootScope.$broadcast('wml.portal.message.cancel');
        $rootScope.$broadcast('wml.loader.start');

        vm.sendingData = true;

        vm.data.credentials.postal_code = vm.form.postal_code;
        vm.data.credentials.house_no = vm.form.house_no;
        vm.data.credentials.house_no_addition = vm.form.house_no_addition;

        // if hosFutureMove token query param
        if (vm.data.credentials.future_move_token.length) {
          // ivAuthenticate.shortTermLogin not needed here, API use future move token to vallidate user
          customerService.getFutureMove(vm.data.credentials).then(function (futureMoveRes) {
            var futureMove = futureMoveRes[0];
            vm.data.prospect = {
              exists: Boolean(futureMove.customer),
              moveDate: futureMove.date || '',
              premise: {
                postal_code: futureMove.postal_code,
                house_no: futureMove.house_num_1,
                house_no_addition: futureMove.house_num_2
              }
            };

            vm.data.personalInformation.initials = futureMove.first_name;
            vm.data.personalInformation.insertion = futureMove.prefix;
            vm.data.personalInformation.lastName = futureMove.last_name;
            vm.data.personalInformation.phone = futureMove.phone;
            vm.data.personalInformation.mobile_phone = futureMove.mobile;
            vm.data.personalInformation.email = futureMove.email;
            vm.data.personalInformation.birthday = futureMove.birthdate;

            var today = new Date().setHours(0, 0, 0, 0);
            var moveInDate = new Date(futureMove.date).setHours(0, 0, 0, 0);
            vm.data.bIsInFuture = (moveInDate > today);

            if (vm.data.prospect.exists) {
              vm.data.meterReading.date = new Date(vm.data.prospect.moveDate).setHours(0, 0, 0, 0);
              registerService.storeData();
              $state.go('register');
            } else {
              // Notification: This token has no future move request assigned, contact customer service
              $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
            }
          }).catch(function () {
            if (error === null) { return; }

            var params = (angular.isDefined(error.fields)) ? error.fields : {};

            if (angular.isDefined(error.code)) {
              $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
            } else {
              // Notification: Failed to get future move data, contact customer service
              $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12101});
            }

          }).finally(function () {
            $timeout(function () {
              $rootScope.$broadcast('wml.loader.stop');
            }, 1000);

            vm.sendingData = false;
          });
        } // else if no token available, this will be caught in active()
      } else {
        // not valid form
        $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1001'});
      }
    }

    function activate() {
      var queryParams = $location.search();
      if (queryParams.fm && queryParams.fm.length) {
        // future move token exists
        if (/^\w+$/gi.test(queryParams.fm)) {
          vm.data.credentials.future_move_token = queryParams.fm;
          return;
        } else {
          // Notification: invalid token format, contact customer service.
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12100});
          return;
        }
      } else {
        // Notification: no token available, contact customer service.
        $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12105});
      }
    }
    activate();
  }

  angular.module('wml.portal.register')
    .controller('RegisterLoginController', RegisterLoginController);

})();
