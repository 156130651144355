(function() {
  'use strict';

  /** @ngInject */
  function socialSharing($location) {
    return {
      restrict  : 'E',
      templateUrl : 'app/components/generic/directives/social/views/social-buttons.html',
      replace     : false,
      link: function(scope, iElem, iAttrs) {
        scope.currentUrl = $location.absUrl();
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('socialSharing', socialSharing)
})();
