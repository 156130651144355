(function() {
  'use strict';

  /** @ngInject */
  function invoicesService($rootScope, $q, Restangular, RestangularExt, storage) {
    var _customerRoute = RestangularExt.all('/customer');
    var cache = {
      account_balance : null
    };
    var _aInvoices = [];

    $rootScope.$on("clear-cache", function(evt, evtData) {
      _aInvoices            = [];
      cache.account_balance = null;
    });

    return {
      getInvoices               : getInvoices,
      getInvoiceDetail          : getInvoiceDetail,
      startPayment              : startPayment,
      getAccountBalance         : getAccountBalance,
      getProcessPaymentStatus   : getProcessPaymentStatus,
      postInstallmentPlan       : postInstallmentPlan,
      getInstallmentPlanChecks  : getInstallmentPlanChecks
    };

    function getInvoices(params, refresh) {
      params = params || {};

      var paramsString = '';
      angular.forEach(params, function(value, key){
        paramsString += key + '_' + value + '_';
      });

      var deferred = $q.defer();

      if (angular.isUndefined(_aInvoices[paramsString]) || refresh) {
        _customerRoute.one(storage('customer_number')).getList('invoice', params).then(function (response) {
          _aInvoices[paramsString] = response;
          _parseData(response);
        });
      } else {
        _parseData(_aInvoices[paramsString]);
      }

      function _parseData(response) {
        var len = response.length;
        for (var i = 0; i < len; i++) {
          if (response[i].selectable) {
            response[i].selected = false;
          }
        }
        deferred.resolve(response);
      }

      return deferred.promise;
    }

    function getProcessPaymentStatus(id) {
      return Restangular.all('/payment').customGET('postback', {id: id});
    }

    function getInstallmentPlanChecks(contractAccountId) {
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).one('installment-plan-checks').get();
    }

    function postInstallmentPlan(contractAccountId, paymentArrangement, invoice) {
      var data = {
        paymentArrangement: paymentArrangement,
        invoice: invoice
      };
      return _customerRoute.one(storage('customer_number')).one('contract-account', contractAccountId).all('installment-plan').post(data);
    }

    function getInvoiceDetail(id) {
      return Restangular.all('/customer').one(storage('customer_number')).one('invoice', id).get();
    }

    function getAccountBalance() {
      if(!cache.account_balance) {
        cache.account_balance = _customerRoute.one(storage('customer_number')).one('account-balance').get();
      }
      return cache.account_balance;
    }

    function startPayment(invoices) {
      return _customerRoute.one(storage('customer_number')).customPOST({
        invoices  : invoices
      }, 'invoice/pay');
    }
  }

  angular.module('wml.portal.invoices')
    .factory('invoicesService', invoicesService);

})();


