(function () {
  'use strict';

  /** @ngInject */
  function appointmentConfirmationController($state, $translate, ivAuthenticate, $rootScope) {
    var vm = this;

    // private variables
    var _goToState = null;

    // Public variables

    // Public function definitions
    vm.next = next;

    // Public functions
    function next() {
      $state.go(_goToState);
    }

    // Private functions

    activate();

    function activate() {
      vm.mode = $state.current.name.split('appointmentConfirmation')[1];
      if (ivAuthenticate.isAuthenticated()) {
        $translate('msa.confirmation.btn_dashboard').then(function (translation) {
          vm.btnTxt = translation;
        });
        _goToState = 'dashboard';
      } else {
        $translate('msa.confirmation.btn_home').then(function (translation) {
          vm.btnTxt = translation;
        });
        _goToState = 'home';
      }
    }

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

  }

  angular
    .module('wml.portal.appointment')
    .controller('appointmentConfirmationController', appointmentConfirmationController);
})();
