(function() {
  'use strict';

  /** @ngInject */
  function premisesRegisterNewPremisesController($scope, $state, customerService, addressService, ivAuthenticate, premiseRegisterService) {

    var vm = this;
    // private variables

    // Public variables
    vm.form = null;
    vm.data = premiseRegisterService.data;
    vm.loading = false;

    // Public function definitions
    vm.save = save;

    // Public functions
    function save() {
      if (vm.form.$valid) {
        premiseRegisterService.storeData();
        if (vm.data.correspondence.bSetNew) {
          $state.go('premisesRegisterCorrespondenceAddress');
        } else {
          $state.go('premisesRegisterSummary');
        }
      }
    }

    function getAddress() {
      customerService.getAddress().then(getAddressesSuccess);
    }
    function getAddressesSuccess(response) {
      vm.data.currentCorrespondenceAddress = response;
    }

    activate();

    function activate() {
      getAddress();
    }
  }

  angular.module('wml.portal.premisesRegister')
    .controller('premisesRegisterNewPremisesController', premisesRegisterNewPremisesController);

})();
