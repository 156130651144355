(function () {
  'use strict';

  /** @ngInject */
  function equalHeight($window, $timeout) {
    return equalHeight = {
      restrict: 'A',
      groups: {},
      link: function (scope, element, attrs) {
        $timeout(getHighest); // make sure angular has proceeded the binding
        angular.element($window).bind('resize', getHighest);

        function getHighest() {
          if (!equalHeight.groups[attrs.equalHeight]) { // if not exists then create the group
            equalHeight.groups[attrs.equalHeight] = {
              height: 0,
              elems:[]
            };
          }

          equalHeight.groups[attrs.equalHeight].elems.push(element);
          element[0].style.height = 'auto'; // make sure we capture the origin height

          if (equalHeight.groups[attrs.equalHeight].height < element[0].offsetHeight) {
            equalHeight.groups[attrs.equalHeight].height = element[0].offsetHeight;
          }

          angular.forEach(equalHeight.groups[attrs.equalHeight].elems, function(elem){
            elem[0].style.height = equalHeight.groups[attrs.equalHeight].height + 'px';
          });

          if(angular.isDefined(attrs.equalNumItems) && parseInt(attrs.equalNumItems) == equalHeight.groups[attrs.equalHeight].elems.length){
            equalHeight.groups[attrs.equalHeight].height = 0;
            equalHeight.groups[attrs.equalHeight].elems = [];
          }
        }
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('equalHeight', equalHeight)
})();
