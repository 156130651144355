(function(){
  'use strict';

  function videoListService(RestangularExt){
    var url = '/instellingen/website-blok-bodem';
    var _cache = null;

    function getBlockContent(){
      if(_cache === null){
        _cache = RestangularExt.all('proxy').customGET(null, {url: url});
      }
      return _cache;
    }

    return {
      getBlockContent : getBlockContent
    }

  }

  angular
    .module('wml.portal.videoList')
    .factory('videoListService', videoListService);

})();
