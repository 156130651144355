(function(){
  'use strict';

  function standpipeService(RestangularExt, moment){
    var _standpipeRoute  = RestangularExt.all('/form/standpipe');

    return {
      send: send
    };

    function send(data) {
      var _data = angular.copy(data);

      if(angular.isDefined(_data.duration) && angular.isDefined(_data.duration.start_date)){
        _data.duration.start_date = moment(_data.duration.start_date).format('YYYY-MM-DD');
      }

      if(angular.isDefined(_data.duration) && angular.isDefined(_data.duration.end_date)){
        _data.duration.end_date = moment(_data.duration.end_date).format('YYYY-MM-DD');
      }

      return _standpipeRoute.post(_data);
    }
  }

  angular
    .module('wml.portal.standpipe')
    .factory('standpipeService', standpipeService);

})();
