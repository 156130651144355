(function() {
  'use strict';

  /** @ngInject */
  function profilePasswordController($state, $timeout, profileService, storage) {

    var vm = this;


    // private variables

    // Public variables
    vm.form = null;
    vm.bShowCurrentPassword = true;
    vm.sendingData          = false;

    // Public function definitions
    vm.save = save;

    // Public functions
    function save() {
      vm.form.$commitViewValue();
      if(vm.form.$valid) {
        vm.sendingData = true;
        profileService.changePassword(vm.form).then(function(){
          storage('activation_query', null);
          $timeout(function() {
            $state.go('profile');
          }, 2000);
        }).finally(function(){
          vm.sendingData = false;
        });
      }
    }

    // Private functions
    activate();

    function activate(){
      if(storage('activation_query')){
        vm.bShowCurrentPassword = false;
      }
    }
  }

  angular
    .module('wml.portal.profile')
    .controller('ProfilePasswordController', profilePasswordController);
})();
