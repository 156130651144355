(function() {
  'use strict';

  /** @ngInject */
  function matchCorporationFilter() {
    return function (items, searchValue) {
      if (searchValue) {
        searchValue = searchValue.replace(/\s/g, '').toLowerCase();
        return items.filter(function (item) {
          return (item.searchkey.indexOf(searchValue) !== -1);
        });
      }
      return items;
    };
  }

  angular.module('wml.portal.corporation')
    .filter('matchCorporationFilter', matchCorporationFilter);

})();
