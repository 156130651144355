(function() {
  'use strict';

  /** @ngInject */
  function RegisterPersonalInfoController($state, enumService, registerService, moment, $rootScope) {
    var vm = this;

    // public variables
    vm.data = registerService.data;
    vm.emailInvoice = true;
    vm.customerSurvey = false;
    vm.salutations = [];
    vm.dateNow = moment().subtract(18, 'years').format('DD-MM-YYYY');

    // @TODO-mmy this is currently done through data.connectionAddress.customerType in view
    // @TODO-mmy should be data.personalInformation.customerType in view
    // @TODO-mmy also need to update API
    if (vm.data.personalInformation.customerType === null) {
      vm.data.personalInformation.customerType = 'residence';
    }

    // public functions
    vm.save = save;
    vm.toggleEmailInvoices = toggleEmailInvoices;
    vm.toggleCustomerSurvey = toggleCustomerSurvey;
    vm.previousState = previousState;

    // toggle functions
    function toggleEmailInvoices() {
      vm.emailInvoice = !vm.emailInvoice;
    }

    function toggleCustomerSurvey() {
      vm.customerSurvey = !vm.customerSurvey;
    }

    // save function
    function save() {
      registerService.storeData();
      // if not in future or token, go to meter readin
      var bIsInFuture = vm.data.bIsInFuture;
      if (!bIsInFuture || vm.data.credentials.future_move_token) { $state.go('registerMeterReading'); }
      if (bIsInFuture) {
        vm.data.personalInformation.emailInvoice = false;
        vm.data.personalInformation.customerSurvey = false;
        registerService.storeData();
        $state.go('registerSummary');
      }
    }

    // previous state function
    function previousState(){
      if (vm.data.connectionAddress.hasDifferentCorrespondence === true) {
        $state.go('registerCorrespondence');
      } else {
        $state.go('register');
      }
    }

    // enum service functions
    function getSalutations() {
      enumService.getSalutations().then(function(data){
        vm.salutations = data;
      });
    }
    function getInsertions() {
      enumService.getInsertions().then(function(data) {
        vm.data.insertions = data;
      });
    }

    function init(){
      getSalutations();
      getInsertions();
      if (vm.data.credentials.future_move_token && vm.data.connectionAddress.customerType === 'company') {
        vm.data.personalInformation.companyName = vm.data.personalInformation.lastName;
      }
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }

    init();
  }

  angular
    .module('wml.portal.register')
    .controller('RegisterPersonalInfoController', RegisterPersonalInfoController);
})();
