(function() {
  'use strict';

  /** @ngInject */
  function faqService(RestangularExt) {

    //url to FAQs
    var _faqRoute  = RestangularExt.all('/proxy');
    var _faq = null;

    return{
      getFaqs : getFaqs
    };

    //get FAQ's from url. stateName can be send from controller to get specific FAQs
    function getFaqs(){
      if(_faq === null){
        _faq = _faqRoute.customGET(null, {url  : '/instellingen/faq'})
      }
      return _faq;
    }

  }

  angular.module('wml.portal.faq')
    .factory('faqService', faqService);

})();
