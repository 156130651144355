(function() {
  'use strict';

  /** @ngInject */
  function RegisterMeterReadingController($document, $timeout, $scope, $state, $stateParams, calendarsService, registerService, $rootScope, Upload, meterReadingService, MOVE_REASON) {
    var vm = this;

    vm.sendingData = false;
    vm.currentMeterReading = false;
    // public variables
    vm.data = registerService.data;
    vm.showDatepicker = false;
    vm.datepickerFormat = "dd-MM-yyyy";
    vm.datepickerCurrentMonth = new Date();
    vm.datepickerOptions = {
      customClass: datePickerClass,
      maxDate: null,
      minDate: null
    };

    if (!(vm.data.meterReading.date instanceof Date)) {
      vm.data.meterReading.date = new Date(vm.data.meterReading.date);
    }

    vm.fileToUpload = null;

    // public functions
    vm.openDatepicker = openDatepicker;
    vm.save = save;
    vm.cancel = cancel;
    vm.fileChange = fileChange;

    $scope.$on('datepicker.monthChanged', function (event, newVal) {
      vm.datepickerCurrentMonth = newVal;
    });

    $scope.$on('datepicker.format', function (event, mode) {
      datepickerFormat(mode);
    });

    function prospectToUser() {
      var bIsInFuture = vm.data.bIsInFuture;
      if (!bIsInFuture && vm.data.credentials.future_move_token) {
        vm.sendingData = true;
        registerService.prospectToUser().then(function (data) {
          if (angular.isDefined(data.type) && data.type === 'message' && data.level !== 'success') {
            if (angular.isDefined(data.level) && (data.level === 'warning' || data.level === 'info')) {
              $rootScope.$broadcast('wml.portal.message', data);
            }
          } else {
            $state.go('registerPaymentMethod');
          }
        }).catch(function () {
          // Notification: register api call failed contact customer support
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12106});
        }).finally(function () {
          vm.sendingData = false;
        });
      }
      $state.go('registerPaymentMethod');
    }

    function datepickerFormat(mode) {
      if (mode === 'day') {
        $timeout(function () {
          var uibWeeks = $document[0].getElementsByClassName('uib-weeks');
          for (var i = 0; i < uibWeeks.length; i += 1) {
            var uibBans = uibWeeks[i].getElementsByClassName('uib-ban');
            if (uibBans.length === 7) {
              uibWeeks[i].style.display = 'none';
            }
            if (uibBans.length !== 7) {
              uibWeeks[i].style.display = 'table-row';
            }
          }
        }, 100);
      }
    }

    function datePickerClass(data) {
      var date = data.date;
      var mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        if (new Date(dayToCheck).getMonth() !== vm.datepickerCurrentMonth.getMonth()) {
          if (new Date(dayToCheck) > vm.datepickerCurrentMonth) {
            return 'uib-ban';
          }
          return 'uib-nope';
        }
        return 'uib-yeah';
      }
      return '';
    }

    function openDatepicker() {
      vm.datepickerCurrentMonth = (vm.data.meterReading.date) ? vm.data.meterReading.date : new Date();
      vm.showDatepicker = true;
      datepickerFormat('day');
    }

    function getCalendarSettingsFromImanager() {
      calendarsService.getCalendars('register').then(function (response) {
        var max = response.meterReadingMaxDate;
        var min = response.meterReadingMinDate;
        vm.datepickerOptions.maxDate = (isNaN(max)) ? null : new Date(new Date().setDate(new Date().getDate() + max));
        vm.datepickerOptions.minDate = (isNaN(min)) ? null : new Date(new Date().setDate(new Date().getDate() - min));
      });
    }

    function fileChange(file, errFiles) {
      $rootScope.$broadcast('wml.portal.message.cancel');
      if (errFiles.length > 0) {
        if (errFiles[0].$error === "maxSize") {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        } else {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        }
      }
      if (file) {
        vm.data.meterReading.file_upload = null;
        vm.fileToUpload = file;
      }
    }

    // meter reading
    function watchMeterNumbers(){
      vm.currentMeterReading = vm.data.meterReading.meter_reading;

      $scope.$watch(function () { return vm.data.meterReading.meter_reading; }, function (newValue) {
        if (angular.isString(newValue)) {
          if (vm.data.meterReading.meter_reading && vm.currentMeterReading && vm.currentMeterReading !== newValue) {
            vm.data.forced_meter_reading = false;
            vm.currentMeterReading = vm.data.meterReading.meter_reading;
          }
          var data = newValue.split('');
          var length = data.length;
          if(length < 5) {
            for (var j=0; j < (5-length); j++) {
              data.unshift('0');
            }
          }
          for (var k=0; k < data.length; k++) {
            var key = 'num' + (k+1);
            vm.data.meterReading[key]  = data[k];
          }
        } else {
          for (var l=1; l <= 5; l++) {
            vm.data.meterReading['num' + l]  = 0;
          }
        }
      });
    }

    // save functions
    function save() {
      vm.sendingData = true;
      if (vm.form.$valid) {
        Upload.base64DataUrl(vm.fileToUpload)
          .then(function (dataUrl) {
            if (dataUrl) {
              vm.data.meterReading.file_upload = {
                file: dataUrl,
                name: vm.fileToUpload.name,
                type: vm.fileToUpload.type
              };
            }
          })
          .finally(function () {
            var today = new Date().setHours(0, 0, 0, 0);
            var moveInDate = new Date(vm.data.meterReading.date).setHours(0, 0, 0, 0);
            vm.data.bIsInFuture = (moveInDate > today);
            registerService.storeData();
            vm.currentMeterReading = vm.data.meterReading.meter_reading;
            if (vm.data.forced_meter_reading) {
              if (!vm.data.bIsInFuture) {
                prospectToUser();
              }
              if (vm.data.bIsInFuture) {
                vm.data.personalInformation.emailInvoice = false;
                vm.data.personalInformation.customerSurvey = false;
                registerService.storeData();
                $state.go('registerSummary');
              }
            } else {
              checkMeterReading();
            }
          });
      }
    }

    function checkMeterReadingSuccess(response) {
      if (angular.isDefined(response)) {
        if (angular.isDefined(response.level) && response.level === 'warning') {
          vm.data.forced_meter_reading = true;
          registerService.storeData();
          $state.go('registerMeterReading', {meterError: response.code});
        } else {
          if (!vm.data.bIsInFuture) {
            prospectToUser();
            $state.go('registerPaymentMethod');
          }
          if (vm.data.bIsInFuture) {
            vm.data.personalInformation.emailInvoice = false;
            vm.data.personalInformation.customerSurvey = false;
            registerService.storeData();
            $state.go('registerSummary');
          }
        }
      }
    }

    function checkMeterReading() {
      meterReadingService.meterReadingCheck(vm.data.connectionAddress.postal_code, vm.data.meterReading.meter_reading, vm.data.meterReading.date, vm.data.connectionAddress.contract_id, vm.data.connectionAddress.device_id, MOVE_REASON.MOVE_IN).then(function (response) {
        checkMeterReadingSuccess(response);
      }).catch(function (error) {
        if (error === null) {
          return;
        }


        var params = (angular.isDefined(error.fields)) ? error.fields : {};

        if (angular.isDefined(error.code)) {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
        } else if (error.data.code === 11011) { // exception - on register give generic warning and set force on
          vm.data.forced_meter_reading = true;
          registerService.storeData();
          $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 12004});
          $state.go('registerMeterReading', {meterError: 12004});
        } else {
          // Notification, no mail address available to send confirmation (12006)
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12006'});
        }
      }).finally(function () {
        vm.sendingData = false;
      });
    }

    function cancel() {
      $state.go('registerPersonalInfo');
    }

    activate();
    function activate() {
      getCalendarSettingsFromImanager();
      watchMeterNumbers();
      if (vm.data.forced_meter_reading && ($stateParams.meterError === 12004 || $stateParams.meterError === 12008 || $stateParams.meterError === 11004 || $stateParams.meterError === 11003)) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: $stateParams.meterError});
      }
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }
  }

  angular
    .module('wml.portal.register')
    .controller('RegisterMeterReadingController', RegisterMeterReadingController);
})();
