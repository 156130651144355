(function () {
  'use strict';

  function waterEstafetteVoteController(cmsService,$rootScope, detailService, waterEstafetteService, storage){
    var vm                  = this;

    //public functions
    vm.sendingData                = false;
    vm.bShowForm                  = true;
    vm.cities                     = [];
    vm.selectedCity               = null;
    vm.registrations              = null;

    vm.formData = {
      id  : null
    };


    // Public function definitions
    vm.send                       = send;
    vm.getRegistrations           = getRegistrations;


    // Public functions
    function send() {
      if (vm.form.$valid) {
        vm.sendingData = true;
        waterEstafetteService.vote(vm.formData)
          .then(function () {
            vm.bShowForm = false;
            storage('waterestafette_voted', true)
          })
          .finally(function () {
            vm.sendingData = false;
          });
      }
    }

    function getRegistrations() {
      waterEstafetteService.getRegistrations(vm.selectedCity).then(function(registrations){
        vm.registrations = registrations;
      })
    }


    cmsService.loadData().then(function(data) {
      vm.data = data;

      cmsService.setSEO(data);

      $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
    });

    // Private functions

    function _getCities() {
      waterEstafetteService.getRegistrationCities().then(function(cities){
        vm.cities = cities;
      })
    }

    activate();

    function activate() {
      _getCities();

      if(storage('waterestafette_voted')){
        vm.bShowForm = false;
      }

      cmsService.loadData().then(function (data) {
        vm.data = data;

        cmsService.setSEO(data);

        $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
      });

      detailService.getBlockContent().then(function (response) {
        vm.bottomBlock = response.plain();
      });

    }
  }

  angular
    .module('wml.portal.water-estafette')
    .controller('waterEstafetteVoteController',waterEstafetteVoteController)

})();
