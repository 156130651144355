(function() {
  'use strict';

  function limitNumberRange() {
    return {
      require   : 'ngModel',
      restrict  : 'A',
      scope: {
        number : '=ngModel',
        lowerLimit: "=lowerLimit",
        upperLimit: "=upperLimit"
      },
      link: function(scope, elm, attrs, ctrl) {
        scope.$watch('number', function() {
          if(scope.number){
            var parsedNumberWithoutDots = scope.number.replace(".", "");
            var parsedNumber = parseFloat(parsedNumberWithoutDots.replace(",", "."));
            if(parsedNumber && parsedNumber >= scope.lowerLimit && parsedNumber <= scope.upperLimit){
              ctrl.$setValidity('limitNumberRange', true);
            } else {
              ctrl.$setValidity('limitNumberRange', false);
            }
          }
        });
      }
    };
  };

  angular.module('wml.portal.generic')
    .directive('limitNumberRange', limitNumberRange)
})();
