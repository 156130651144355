(function() {
  'use strict';

  /** @ngInject */
  function ListPageController(cmsService) {
    var vm  = this;

    // Public variables
    vm.currentPage = 1;

    // private variables

    // Public function definitions
    vm.changePage = changePage;

    // Public functions
    function changePage(){
      activate();
    }

    // Private functions
    activate();

    function activate (){
      var querystring = '?page=' + vm.currentPage;
      cmsService.loadData(querystring).then(function(data) {
        vm.data = data.plain();
        vm.currentPage = vm.data.Pagination.CurrentPage;
        cmsService.setSEO(data);
      });
    }
  }

  angular
    .module('wml.portal.listPage')
    .controller('ListPageController', ListPageController);
})();
