(function() {
  'use strict';

  /** @ngInject */
  function profileController($rootScope, $stateParams, profileService) {

    var vm = this;
    vm.data = {};

    activate();

    function activate() {
      if ($stateParams.message) {
        $rootScope.$broadcast('wml.portal.message', $stateParams.message);
      }

      profileService.getProfile().then(function(response) {
        vm.data = response;
      });
    }

  }

  angular
    .module('wml.portal.profile')
    .controller('ProfileController', profileController);
})();
