(function() {
  'use strict';

  /** @ngInject */
  function contactPersonService($q, $rootScope, Restangular, RestangularExt, storage, customerService) {
    var _customerRoute  = RestangularExt.all('/customer');

    var cacheServiceName  = 'contactperson';
    var cache = {
      contactperson   : null
    };

    var updated = {};

    $rootScope.$on("clear-cache", function(evt, evtData) {
      var key;
      if(angular.isObject(evtData)) {
        if(evtData[cacheServiceName]) {
          if(typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for(key in cache) {
              cache[key] = null;
            }
          } else if(angular.isObject(evtData[cacheServiceName])) {
            for(key in cache) {
              if(typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key]  = null;
              }
            }
          }
        }
      } else {
        for(key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      load                            : load,
      save                            : save,
      remove                          : remove,
      addUpdate                       : addUpdate,
      getUpdated                      : getUpdated,
      getAdministrationContactPerson  : getAdministrationContactPerson
    };

    function load(accountId, withLoader) {
      withLoader  = (typeof withLoader === 'boolean') ? withLoader : false;

      var restangularRef  = (withLoader) ? RestangularExt : Restangular;

      accountId = accountId || storage('customer_number');

      var key =  'account_' + accountId;

      cache.contactperson = cache.contactperson || {};

      if(!cache.contactperson[key]) {
        cache.contactperson[key]  = restangularRef.one('customer', accountId).get();
      }

      return cache.contactperson[key].then(function(data) {

        function checkForUpdate(accountId, data) {
          if(updated[accountId]) {
            for(var key in updated[accountId]) {
              data[key] = updated[accountId][key];
            }
            data.customer_number  = updated[accountId].relation_account_id;

            var email;
            if(angular.isString(updated[accountId].email)) {
              email = updated[accountId].email;
            } else if(angular.isObject(updated[accountId].email) && updated[accountId].email.correspondence) {
              email = updated[accountId].email.correspondence;
            }

            data.email  = {
              correspondence  : email
            };

            return data;
          }

          return data;
        }

        return checkForUpdate(accountId, data);
      });
    }

    function getAdministrationContactPerson() {
      var deferred2 = $q.defer();

      load(undefined, true).then(function (response) {
        var deferred  = $q.defer();

        deferred.promise.then(function(data) {
          deferred2.resolve(data);
        }, function() {
          customerService.getCustomer().then(function(response) {
            deferred2.resolve(response);
          });
        });

        if(response.contact_persons && response.contact_persons.length) {
          var bFound  = false;
          for(var i = 0; i < response.contact_persons.length; i++) {
            if(response.contact_persons[i].account_relationship_type === 'administration') {
              bFound= true;
              load(response.contact_persons[i].related_account_id, true).then(function(data) {
                for(var key in updated) {
                  if(updated[key].relation_type === 'administration') {
                    data.customer_number  = key;
                    data.email  = updated[key].email;
                  }
                }

                var email = null;
                if(angular.isString(data.email)) {
                  email = data.email;
                } else if(angular.isObject(data.email) && data.email.correspondence) {
                  email = data.email.correspondence;
                }

                data.email  = {
                  correspondence  : email
                };

                deferred.resolve(data);
              });
            }
          }
          if(bFound === false) {
            deferred.reject();
          }
        } else {

          for(var key in updated) {
            if(updated[key].relation_type === 'administration') {
              response.customer_number  = key;
              response.email  = updated[key].email;
            }
          }

          var email = null;
          if(angular.isString(response.email)) {
            email = response.email;
          } else if(angular.isObject(response.email) && response.email.correspondence) {
            email = response.email.correspondence;
          }

          response.email  = {
            correspondence  : email
          };
          deferred.resolve(response);
        }
      });

      return deferred2.promise;
    }

    function getUpdated() {
      return updated;
    }

    function save(data, mode) {
      if(!angular.isObject(data)) {
        $q.reject();
      }

      if (mode === 'EDIT') {
        return _customerRoute.one(storage('customer_number')).all('contact-person').customPUT(data);
      } // else 'ADD'
      return _customerRoute.one(storage('customer_number')).all('contact-person').customPOST(data);
    }

    function remove(data) {
      if (!angular.isObject(data)) {
        $q.reject();
      }
      return _customerRoute.one(storage('customer_number')).customDELETE('contact-person', data);
    }

    function addUpdate(accountId, data) {
      updated[accountId]  = data;
    }
  }

  angular.module('wml.portal.contactperson')
    .factory('contactPersonService', contactPersonService);

})();
