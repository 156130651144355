(function() {
  'use strict';

  /** @ngInject */
  function restBackend(RestangularExt) {
    var _getSapStatusRoute = RestangularExt.all('/sap-status');
    var _getCrisisMsgRoute = RestangularExt.all('/crisis-msg');

    return {
      getSapStatus: getSapStatus,
      getCrisisMsg: getCrisisMsg
    };

    function getSapStatus() {
      return _getSapStatusRoute.customGET();
    }

    function getCrisisMsg() {
      return _getCrisisMsgRoute.customGET();
    }
  }

  angular.module('wml.portal.restBackend')
    .factory('restBackend', restBackend);

})();
