(function() {
  'use strict';

  /** @ngInject */
  function CorrespondenceAddressEditController($scope, $state, customerService, addressService) {
    var vm  = this;

    // private variables
    var _currentAddress   = null;
    var _lastAutofill     = null;
    var _lastAutofillPo   = null;

    // Public variables
    vm.form                   = null;
    vm.draft                  = {};
    vm.premises               = [];
    vm.bShowOther             = false;
    vm.sendingData            = false;
    vm.bShowPremisesSelection = true;
    vm.loading                = false;

    // Public function definitions
    vm.autofillHouseNo    = autofillHouseNo;
    vm.autofillPoBox      = autofillPoBox;
    vm.showOther          = showOther;
    vm.save               = save;
    vm.changeCountry      = changeCountry;
    vm.changeType         = changeType;


    // Public functions
    function autofillHouseNo() {
      if(vm.draft.country !== 'NL'){
        return false;
      }

      if(!angular.isString(vm.draft.address_id) && angular.isString(vm.draft.postal_code) && angular.isString(vm.draft.house_no)) {
        vm.draft.postal_code = vm.draft.postal_code.toUpperCase();
        if(/^[0-9]{4}[A-Z]{2}$/.test(vm.draft.postal_code)) {

          var data = {
            postal_code : vm.draft.postal_code,
            house_no : vm.draft.house_no,
            house_no_addition : vm.draft.house_no_addition
          };

          var autofillKey = data.postal_code + '_' + data.house_no;

          if (angular.isString(vm.draft.house_no_addition)) {
            data.house_no_addition = vm.draft.house_no_addition;
            autofillKey = autofillKey + '_' + data.house_no_addition;
          }

          if(autofillKey === _lastAutofill) {
            return false;
          }

          vm.draft.city        = null;
          vm.draft.street      = null;
          vm.loading           = true;

          _lastAutofill = autofillKey;

          autoFill(data);
        }
      }
    }

    function autofillPoBox() {
      if(vm.draft.country !== 'NL'){
        return false;
      }

      if(!angular.isString(vm.draft.address_id) && angular.isString(vm.draft.po_box_postal_code) && angular.isString(vm.draft.po_box)) {
        vm.draft.po_box_postal_code = vm.draft.po_box_postal_code.toUpperCase();
        if(/^[0-9]{4}[A-Z]{2}$/.test(vm.draft.po_box_postal_code)) {

          var data = {
            po_box_postal_code  : vm.draft.po_box_postal_code,
            po_box              : vm.draft.po_box
          };

          var autofillKey = data.po_box_postal_code + '_' + data.po_box;

          if(autofillKey === _lastAutofillPo) {
            return false;
          }

          vm.draft.city        = null;
          vm.loading           = true;

          _lastAutofillPo = autofillKey;

          autoFill(data);
        }
      }
    }

    function showOther(bShowOther){
      vm.bShowOther = bShowOther;
    }

    function save() {
      if(vm.form.$valid) {
        vm.sendingData = true;
        customerService.saveAddress(vm.draft).then(function(){
          customerService.clearAddressCache();
          $state.go('customerSaved');
        }).finally(function(){
          vm.sendingData = false;
        });
      }
    }

    function changeCountry() {
      resetForm();
      if(vm.draft.country !== 'NL'){
        vm.draft.type = 'address';
      } else {
        vm.draft.type = '';
      }
    }

    function changeType() {
      resetForm();
    }

    // Private functions
    function resetForm() {
      _lastAutofillPo = null;
      _lastAutofill   = null;

      vm.draft.postal_code        = null;
      vm.draft.po_box_postal_code = null;
      vm.draft.po_box             = null;
      vm.draft.street             = null;
      vm.draft.house_no           = null;
      vm.draft.house_no_addition  = null;
      vm.draft.city               = null;
    }

    function getPremises() {
      customerService.getPremises().then(getPremiseSuccess);
    }

    function getPremiseSuccess(response) {
      vm.premises = response.filter(function(item){
        var _address = item.address.house_no + item.address.house_no_addition + item.address.postal_code;
        return (_address !== _currentAddress && item.is_correspondence_option);
      });

      if(vm.premises.length === 0){
        vm.bShowPremisesSelection = false;
      }
    }

    function getAddress() {
      customerService.getAddress().then(getAddressesSuccess);
    }

    function getAddressesSuccess(response) {
      vm.correspondenceAddress = response;
      _currentAddress = vm.correspondenceAddress.house_no + vm.correspondenceAddress.house_no_addition + vm.correspondenceAddress.postal_code;
      getPremises();
    }

    function autoFill(data) {
      addressService.autofill(data).then(function(response) {
        var address = angular.copy(response.plain());
        vm.loading  = false;

        if(address.street){
          vm.draft.street = address.street;
        }
        if(address.house_no_addition){
          vm.draft.house_no_addition = address.house_no_addition;
        }
        if(address.city){
          vm.draft.city = address.city;
        }
      });
    }

    activate();

    function activate() {
      resetForm();
      getAddress();

      $scope.$watch(function() {return vm.draft.address_id}, function(newValue) {
        if(angular.isDefined(newValue) && angular.isObject(newValue)) {
          vm.draft.country              = newValue.country;
          vm.draft.city                 = newValue.city;
          vm.draft.postal_code          = newValue.postal_code;
          vm.draft.street               = newValue.street;
          vm.draft.house_no             = newValue.house_no;
          vm.draft.house_no_addition    = newValue.house_no_addition;
        } else {
          resetForm();
        }
      });
    }
  }

  angular.module('wml.portal.correspondence')
    .controller('CorrespondenceAddressEditController', CorrespondenceAddressEditController);

})();



