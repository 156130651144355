(function() {
  'use strict';

  /** @ngInject */
  function RoutesController($state) {
    var vm = this;

    vm.list = $state.get();
  }

  angular.module('wml.portal.routes')
    .controller('RoutesController', RoutesController);

})();
