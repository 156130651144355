(function() {
  'use strict';

  /** @ngInject */
  function breadcrumbs() {

    var _this     = this;
    var _mappings = {};

    function validateRegisterObj(obj) {
      if(angular.isObject(obj)) {
        var requiredProperties  = ['path', 'states'];
        for(var i=0; i < requiredProperties.length; i++) {
          if(angular.isDefined(obj[requiredProperties[i]]) && angular.isArray(obj[requiredProperties[i]])) {
            return true;
          }
        }
      }
      return false;
    }

    _this.register  = function(registerObj) {
      if(validateRegisterObj(registerObj) === false) {
        throw new Error('Invalid breadcrumb register object');
      }

      var key = registerObj.path.join('_');

      if(angular.isDefined(_mappings[key])) {
        throw new Error('Breadcrumb path is already defined');
      }

      _mappings[key]  = registerObj;

      return _this;
    };


    /** @ngInject */
    _this.$get  = function($state, $translate) {
      return {
        getPath : getPath
      };

      function getPath() {
        for(var key in _mappings) {
          if(_mappings[key].states.indexOf($state.current.name) !== -1) {
            var aPath = _mappings[key].path.map(function(item) {
              return {
                state : item,
                label : $translate.instant('breadcrumb.' + item)
              }
            });

            // aPath.push({
            //   state : $state.current.name,
            //   label : $translate.instant('breadcrumb.' + $state.current.name)
            // });

            return aPath;
          }
        }
        return null;
      }
    };

  }

  angular.module('wml.portal.breadcrumbs')
    .provider('breadcrumbs', breadcrumbs);

})();
