(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterMoveOutDateController($rootScope, $timeout, $document, $state, $scope, $filter, calendarsService, moment, premiseUnregisterService, customerService, ivAuthenticate, premisesService, storage) {
    var vm = this;

    // Public variables
    vm.form = null;
    vm.data = premiseUnregisterService.data;

    vm.showDatepicker = false;
    vm.datepickerFormat = "dd-MM-yyyy";
    vm.datepickerCurrentMonth = new Date();
    vm.datepickerOptions = {
      customClass: datePickerClass,
      maxDate: null,
      minDate: null
    };

    // Public function definitions
    vm.next = next;
    vm.cancel = cancel;
    vm.openDatepicker = openDatepicker;

    activate();

    $scope.$on('datepicker.monthChanged', function (event, newVal) {
      vm.datepickerCurrentMonth = newVal;
    });

    $scope.$on('datepicker.format', function (event, mode) {
      datepickerFormat(mode);
    });

    function datepickerFormat(mode) {
      if (mode === 'day') {
        $timeout(function () {
          var uibWeeks = $document[0].getElementsByClassName('uib-weeks');
          for (var i = 0; i < uibWeeks.length; i += 1) {
            var uibBans = uibWeeks[i].getElementsByClassName('uib-ban');
            if (uibBans.length === 7) {
              uibWeeks[i].style.display = 'none';
            }
            if (uibBans.length !== 7) {
              uibWeeks[i].style.display = 'table-row';
            }
          }
        }, 100);
      }
    }

    function datePickerClass(data) {
      var date = data.date;
      var mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        if (new Date(dayToCheck).getMonth() !== vm.datepickerCurrentMonth.getMonth()) {
          if (new Date(dayToCheck) > vm.datepickerCurrentMonth) {
            return 'uib-ban';
          }
          return 'uib-nope';
        }
        return 'uib-yeah';
      }
      return '';
    }

    function openDatepicker() {
      vm.datepickerCurrentMonth = (vm.data.meter_reading.date) ? vm.data.meter_reading.date : new Date();
      vm.showDatepicker = true;
      datepickerFormat('day');
    }

    function getCalendarSettingsFromImanager() {
      calendarsService.getCalendars('premisesUnregister').then(function (response) {
        var max = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.moveOutMaxDateCorporation : response.moveOutMaxDate);
        var min = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.moveOutMinDateCorporation : response.moveOutMinDate);
        vm.datepickerOptions.maxDate = (isNaN(max)) ? null : new Date(new Date().setDate(new Date().getDate() + max));
        vm.datepickerOptions.minDate = (isNaN(min)) ? null : new Date(new Date().setDate(new Date().getDate() - min));
      });
    }

    function next() {
      if (vm.form.$valid) {

        var today = new Date().setHours(0, 0, 0, 0);
        var moveOutDate = new Date(vm.data.meter_reading.date).setHours(0, 0, 0, 0);
        vm.data.bIsInFuture = (moveOutDate > today);

        premiseUnregisterService.storeData();
        if (ivAuthenticate.isAuthenticated()) {
          $state.go('premisesUnregister');
        } else {
          $state.go('premisesUnregisterLogin');
        }
      }
    }

    function cancel() {
      premisesService.clearProcesStatus();
      premiseUnregisterService.clearData();
      if (ivAuthenticate.isAuthenticated()) {
        premiseUnregisterService.clearData();
        $state.go('customer');
      } else {
        $rootScope.$broadcast('clear-cache', {customer: true});
        premiseUnregisterService.clearData();
        $state.go('home');
      }
    }

    function activate() {
      if (storage('corporation')) {
        $state.go('premisesUnregister');
      }
      getCalendarSettingsFromImanager();
      if (vm.data.meter_reading.date !== null) {
        vm.data.meter_reading.date = new Date(vm.data.meter_reading.date);
      }
    }
  }

  angular
    .module('wml.portal.premisesUnregister')
    .controller('PremisesUnregisterMoveOutDateController', PremisesUnregisterMoveOutDateController);
})();
