(function() {
  'use strict';

  /** @ngInject */
  function usageChartService(customerService, $filter, moment) {

    var chartConfig = {
      chart: {
        type: 'column',
        height: '500',
        spacingTop: 40,
        spacingBottom: 40
      },
      colors: ['#abd4e9'],
      xAxis: {
        title: {
          text: ''
        },
        labels: {
          useHTML: true,
          style: {
            display: 'flex',
            flexDirection: 'column'
          },
        },
        categories: []
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        labels: {
          useHTML: true,
          format: '{value} m<sup>3</sup>'
        },
        tickInterval: 50,
        maxPadding: 0.5,
        stackLabels: {
          enabled: true,
          verticalAlign: 'middle',
          align: 'center',
          y: 0,
          useHTML:true,
          formatter: function() {return '<div class="chart-stackedLabel">'+this.total+' m<sup>3</sup></div>';},
          style: {
            fontSize: '10px',
            fontWeight: 'normal',
            color: '#fff',
            textShadow: "none",
            textOutline: "none"
          }
        },
        plotLines: [{
          value: 150,
          id: 'usagePlotline',
          color: '#000',
          width: 1,
          zIndex: 5
        }]
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            y: -20,
            shape: 'callout',
            borderRadius: 5,
            borderWidth: 1,
            borderColor:  '#0098db',
            backgroundColor: '#0098db',
            color: '#fff',
            align:'center',
            crop:false,
            overflow:'none',
            style:  {
              color: "#fff",
              fontSize: "13px",
              fontWeight: "normal",
              textOutline: "none"
            },
            useHTML:  true,
            inside:false,
            enabled: true
          }
        }
      },
      series: [{
        data: []
      }],
      title: {
        text: ''
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      }
    };

    var chartExtraConfig = {
      bShowChart              : false,
      selectedChartAddress    : {},
      chartFilterAddresses    : [],
      meter_reading_results   : [],
      exchanged               : false,
      exchangeDate            : null,
      isExchangeDateRecent    : false,
      smartMeter              : false
    };

    return {
      getConfig               : chartConfig,
      chartExtraConfig        : chartExtraConfig,
      initChart               : initChart,
      getConsumption          : getConsumption
    };

    function initChart(connections) {
      chartExtraConfig.chartFilterAddresses = connections.map(function (item) {
        if (item.device.serial_number) { // exclude if no serial_number. without you can't get consumption
          var option = {};
          option.label = _formatAddressString(item);
          option.value = {
            contract_account_id: item.contract_account_id,
            contract_id: item.contract_id,
            smart_meter: item.smart_meter
          };
          return option;
        }
      }).filter(function (stripEmpty) {
        return stripEmpty;
      });

      chartExtraConfig.selectedChartAddress = chartExtraConfig.chartFilterAddresses[0].value;

      getConsumption(chartExtraConfig.selectedChartAddress);
    }

    function getConsumption(address) {
      chartExtraConfig.smartMeter = address.smart_meter;
      if (!chartExtraConfig.smartMeter && address.contract_account_id && address.contract_id) {
        customerService.getConsumption(address.contract_account_id, address.contract_id, 'periodic').then(function(response) {
          chartExtraConfig.meter_reading_results = [];

          if (response[0]) {
            if (response[0].meter_reading_results) {
              chartExtraConfig.meter_reading_results = response[0].meter_reading_results.filter(function (item) {
                return !item.is_open;
              });
            }
            chartExtraConfig.exchanged = response[0].exchanged;
            chartExtraConfig.exchangeDate = response[0].exchange_date;
            if (chartExtraConfig.exchangeDate) {
              chartExtraConfig.isExchangeDateRecent = moment().subtract(3, 'years').isBefore(chartExtraConfig.exchangeDate);
            }
          }

          _composeChart(chartExtraConfig.meter_reading_results);
        });
      }
    }

    function _composeChart(data) {
      chartExtraConfig.bShowChart = true;

      data.reverse();
      var xAxisLabels = [];
      var aConsumptions = [];

      for(var i = 0; i < data.length; i++){
        if(data[i].previous_reading_date_time && data[i].consumption > 0){
          var label = $filter('date')(data[i].previous_reading_date_time, "dd-MM-yyyy") + '<br/>' + $filter('date')(data[i].reading_date_time, "dd-MM-yyyy");

          xAxisLabels.push(label);

          var formatted = '<div class="chart-label-text">' +
            '<strong>' + Math.round(data[i].daily_consumption_in_liter) + '</strong><br/>' +
            '<strong>liter</strong><br/>' +
            '<div class="chart-small-label-text">per dag</div>' +
            '</div>';

          aConsumptions.push(
            {
              y: data[i].consumption,
              dataLabels: {
                format: formatted
              }
            }
          );

        }
      }

      chartConfig.series[0].data = aConsumptions.slice(-3);
      chartConfig.xAxis.categories = xAxisLabels.slice(-3);
    }

    function _formatAddressString(item){
      if (angular.isDefined(item.address.house_no_addition) && item.address.house_no_addition.length) {
        return item.address.street + ' ' + item.address.house_no + ' ' + item.address.house_no_addition + ', ' + item.address.city;
      } else {
        return item.address.street + ' ' + item.address.house_no + ', ' + item.address.city;
      }
    }

  }

  angular.module('wml.portal.usage')
    .factory('usageChartService', usageChartService);

})();
