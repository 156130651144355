(function () {
  'use strict';

  function waterEstafetteRegistrationController(cmsService,$rootScope, detailService, waterEstafetteService){
    var vm                  = this;
    var _maxNumberOfPersons = 15;

    //public functions
    vm.sendingData                = false;
    vm.bShowForm                  = true;
    vm.bShowNumberOfPersonsError  = false;
    vm.form                       = null;
    vm.personsOptions             = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

    vm.types = [
      {
        label : 'Hardlopen',
        value:  'running'
      },
      {
        label : 'Wielrennen',
        value:  'cycling'
      }
    ];

    vm.distances = {
      running : [5,10,15],
      cycling : [40,60,90]
    };


    vm.formData = {
      association  : {
        name              : '',
        type              : '',
        postal_code       : '',
        city              : ''
      },
      contact      : {
        name       : '',
        email      : '',
        phone      : ''
      },
      competitors: [
        {
          type                 : 'cycling',
          number_of_persons    : 1,
          distance             : 90
        },
        {
          type                 : 'running',
          number_of_persons    : 1,
          distance             : ''
        }
      ]
    };


    // Public function definitions
    vm.send           = send;
    vm.addCompetitor  = addCompetitor;
    vm.removeRow      = removeRow;


    // Public functions
    function addCompetitor(){
      vm.formData.competitors.push({
        type                 : '',
        number_of_persons    : '',
        distance             : ''
      });
    }

    function removeRow(index){
      if(index > 0){
        vm.formData.competitors.splice(index, 1);
      }
    }

    function send() {
      if (vm.form.$valid) {
        vm.sendingData = true;
        if(_validateNumberOfPersons() <= _maxNumberOfPersons){
          vm.bShowNumberOfPersonsError = false;
          waterEstafetteService.register(vm.formData)
            .then(function () {
              vm.bShowForm = false;
            })
            .finally(function () {
              vm.sendingData = false;
            });
        } else {
          vm.bShowNumberOfPersonsError = true;
          vm.sendingData = false;
        }
      }
    }


    cmsService.loadData().then(function(data) {
      vm.data = data;

      cmsService.setSEO(data);

      $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
    });

    // Private functions

    function _validateNumberOfPersons(){
      var count = 0;
      for(var i = 0; i < vm.formData.competitors.length; i++){
        count = count + vm.formData.competitors[i].number_of_persons;
      }
      return count;
    }

    activate();

    function activate() {

      cmsService.loadData().then(function (data) {
        vm.data = data;

        cmsService.setSEO(data);

        $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
      });

      detailService.getBlockContent().then(function (response) {
        vm.bottomBlock = response.plain();
      });

    }
  }

  angular
    .module('wml.portal.water-estafette')
    .controller('waterEstafetteRegistrationController',waterEstafetteRegistrationController)

})();
