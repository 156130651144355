(function() {
  'use strict';

  /** @ngInject */
  function readspeakerForm($transitions, $rootScope) {
    return {
      restrict: 'E',
      templateUrl : 'app/components/generic/directives/readspeaker/views/readspeaker-form.html',
      link: function (scope, elem, attrs, _ctrl) {

        var isClicked = false;
        scope.isPlaying = false;
        scope.currentLocation = encodeURIComponent(window.location);

        // Listen for route change and stop reader if needed
        $transitions.onStart({}, function() {
        });

        $rootScope.$on('readspeaker.text.isPlaying', function(evt, isPlaying) {
          if (isPlaying && scope.isPlaying) {
            stopPlaying();
          }
        });

        scope.toggleReadspeaker = function() {
          if (scope.isPlaying) {
            stopPlaying();
          } else {
            startPlaying();
          }
        };

        function startPlaying() {

          if (!isClicked) {
            if(typeof frInit === 'function') {
              frInit();
            }
            isClicked = true;
          }

          var rsButton = elem[0].querySelectorAll('.fr_play_button');
          if (rsButton.length > 0) {
            clickElement(rsButton[0]);
            scope.isPlaying = true;
            $rootScope.$broadcast('readspeaker.form.isPlaying', scope.isPlaying);
          }
        }

        function stopPlaying() {
          var rsButton = elem[0].querySelectorAll('.fr_play_button');
          if (rsButton.length > 0) {
            clickElement(rsButton[0]);
            scope.isPlaying = false;
            $rootScope.$broadcast('readspeaker.form.isPlaying', scope.isPlaying);
          }
        }

        function clickElement(element) {
          var event;
          if (typeof(Event) === 'function') {
            event = new Event('click');
          } else {
            event = document.createEvent('Event');
            event.initEvent('click', true, true);
          }
          element.dispatchEvent(event);
        }


      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('readspeakerForm', readspeakerForm);
})();
