(function () {
  'use strict';

  /** @ngInject */
  function calendarsService(RestangularExt) {
    // url to imanager
    var _calendarRoute = RestangularExt.all('/proxy');
    var _calendar = null;
    var _calendarSettings = {};

    return {
      getCalendars: getCalendars
    };

    // get calendars's from url
    function getCalendars(type) {
      if (_calendar === null) {
        _calendar = _calendarRoute.customGET(null, {url: '/instellingen/calendars'});
      }
      return _calendar.then(function (response) {
        _calendarSettings = {
          premisesUnregister: {
            moveOutMaxDate: Number(response.premesis_unregister_max_date_move_out.data),
            moveOutMinDate: Number(response.premesis_unregister_min_date_move_out.data),
            meterReadingMaxDate: Number(response.premesis_unregister_max_date_meter_reading.data),
            meterReadingMinDate: Number(response.premesis_unregister_min_date_meter_reading.data),
            moveOutMaxDateCorporation: Number(response.premesis_unregister_max_date_move_out_corporation.data),
            moveOutMinDateCorporation: Number(response.premesis_unregister_min_date_move_out_corporation.data),
            meterReadingMaxDateCorporation: Number(response.premesis_unregister_max_date_meter_reading_corporation.data),
            meterReadingMinDateCorporation: Number(response.premesis_unregister_min_date_meter_reading_corporation.data)
          },
          premisesRegister: {
            moveInMaxDate: Number(response.premesis_register_max_date_move_in.data),
            moveInMinDate: Number(response.premesis_register_min_date_move_in.data),
            meterReadingMaxDate: Number(response.premesis_register_max_date_meter_reading.data),
            meterReadingMinDate: Number(response.premesis_register_min_date_meter_reading.data),
            moveInMaxDateCorporation: Number(response.premesis_register_max_date_move_in_corporation.data),
            moveInMinDateCorporation: Number(response.premesis_register_min_date_move_in_corporation.data),
            meterReadingMaxDateCorporation: Number(response.premesis_register_max_date_meter_reading_corporation.data),
            meterReadingMinDateCorporation: Number(response.premesis_register_min_date_meter_reading_corporation.data)
          },
          register: {
            moveInMaxDate: Number(response.register_max_date.data),
            moveInMinDate: Number(response.register_min_date.data),
            meterReadingMaxDate: Number(response.register_max_date_meter_reading.data),
            meterReadingMinDate: Number(response.register_min_date_meter_reading.data)
          },
          meterReading: {
            maxDate: Number(response.meter_reading_max_date.data),
            minDate: Number(response.meter_reading_min_date.data)
          }
        };
        return _calendarSettings[type];
      });
    }
  }

  angular.module('wml.portal.calendars')
    .factory('calendarsService', calendarsService);
})();
