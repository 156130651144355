(function() {
  'use strict';

  /** @ngInject */
  function appInfoService(RestangularExt, $http, storage) {

    var _cache  = {
      api       : null,
      frontend  : null
    };

    var _appInfo  = {
      api       : {
        version : null
      },
      frontend  : {
        version : null
      }
    };

    var _observers  = {
      api       : [],
      frontend  : []
    };

    if(storage('app_info')) {
      _appInfo  = storage('app_info');
    }

    return {
      getApiInfo              : getApiInfo,
      getFrontendInfo         : getFrontendInfo,
      onApiVersionChange      : onApiVersionChange,
      onFrontendVersionChange : onFrontendVersionChange,
      versionCompare          : versionCompare
    };

    function getApiInfo() {
      if(!_cache.api) {
        _cache.api = RestangularExt.all('app-info').customGET().then(function(response) {
          if(_appInfo.api.version !== response.version) {
            for(var i = 0; i < _observers.api.length; i++) {
              _observers.api[i].apply(null, [response.version, _appInfo.api.version]);
            }

            _appInfo.api.version  = response.version;
            storage('app_info', _appInfo);
          }

          return response;
        });
      }

      return _cache.api;
    }

    function getFrontendInfo() {
      if(!_cache.frontend) {
        _cache.frontend = $http.get('/app-info.json').then(function(response) {
          if(_appInfo.frontend.version !== response.data.version) {
            for(var i = 0; i < _observers.frontend.length; i++) {
              _observers.frontend[i].apply(null, [response.data.version, _appInfo.frontend.version]);
            }

            _appInfo.frontend.version  = response.data.version;
            storage('app_info', _appInfo);
          }

          return response;
        });
      }

      return _cache.frontend;
    }

    function onApiVersionChange(observerFn) {
      if(typeof observerFn === 'function') {
        _observers.api.push(observerFn);
      }
    }


    function onFrontendVersionChange(observerFn) {
      if(typeof observerFn === 'function') {
        _observers.frontend.push(observerFn);
      }
    }

    function versionCompare(v1, v2) {
      if(angular.isString(v1) === false || angular.isString(v2) === false) {
        // Could net determine
        return 0;
      }

      var regex = /^v((?:[0-9]+\.){2}[0-9]{1,})$/;

      var v1Match = v1.match(regex);
      var v2Match = v2.match(regex);

      if(!v1Match || !v2Match) {
        // Could not determine. Invalid version string
        return 0;
      }

      var v1Parts = v1Match[1].split('.').map(function(number) { return parseInt(number) });
      var v2Parts = v2Match[1].split('.').map(function(number) { return parseInt(number) });

      for(var i = 0; i < v1Parts.length; i++) {
        if(v1Parts[i] < v2Parts[i]) {
          return -1;
        } else if(v1Parts[i] > v2Parts[i]) {
          return 1;
        }
      }

      return 0;
    }

  }

  angular.module('wml.portal.app-info')
    .factory('appInfoService', appInfoService);

})();
