(function(){
  'use strict';

  function damageFormService(){
    var _defaults = {
      customer_number       : '',
      name                  : '',
      initials              : '',
      street                : '',
      house_number          : '',
      house_number_addition : '',
      postal_code           : '',
      city                  : '',
      phone                 : '',
      email                 : '',
      iban                  : '',
      date                  : '',
      time                  : '',
      location              : '',
      about                 : '',
      img                   : null,
      report_insurance      : false,
      insurance_agency      : '',
      polis                 : '',
      damage_cause          : '',
      damagedItems          : [
        {
          description       : '',
          purchase_date     : '',
          purchase_price    : '',
          day_value         : '',
          estimated_damage  : ''
        }
      ],
      accept_declaration    : false
    };

    var _data = angular.copy(_defaults);

    function clearData(){
      return _data = angular.extend(_data, _defaults);
    }

    return {
      data      : _data,
      clearData : clearData
    }
  }
  angular
    .module('wml.portal.damage-form')
    .factory('damageFormService', damageFormService);

})();
