(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterEmailController($state, $rootScope, $timeout, customerService, premisesService, premiseUnregisterService, premiseRegisterService, $stateParams, ivAuthenticate, storage) {
    var vm = this;

    vm.form = null;
    vm.data = {};
    vm.sendingData = false;
    vm.data = premiseUnregisterService.data;

    // Public function definitions
    vm.save = save;
    vm.cancel = cancel;

    // Public functions
    function save() {
      if (vm.form.$valid) {
        if (vm.data.afterNewRegister) {
          vm.data.correspondence.bSetAsNewEmail = true;
        }
        premiseUnregisterService.storeData();
        $state.go('premisesUnregisterSummary');
      }
    }

    function cancel() {
      if (vm.data.afterNewRegister) {
        $state.go('premisesUnregisterSummary');
      } else if (vm.data.correspondence.bSetNew === true) {
        $state.go('premisesUnregisterCorrespondenceAddress');
      } else {
        $state.go('premisesUnregisterMeterReading');
      }
    }

    function activate() {
    }
    activate();
  }

  angular.module('wml.portal.premisesUnregister')
    .controller('PremisesUnregisterEmailController', PremisesUnregisterEmailController);

})();
