(function() {
  'use strict';

  /** @ngInject */
  function incidentService(RestangularExt, $q, $translate) {

    var _cache = null;

    return {
      load  : load
    };

    function load() {
      if(_cache === null) {
        var deferred  = $q.defer();
        _cache  = deferred.promise;

        RestangularExt.all('incidents').getList().then(function(response) {
          var malfunctions  = response.filter(function(item) {
            return item.is_malfunction;
          });

          var planned_incidents = response.filter(function(item) {
            return (!item.is_malfunction);
          });
          var translationOnePromise = (malfunctions.length === 1) ? $translate('header.incidents.malfunctions_prefix_single') : $translate('header.incidents.malfunctions_prefix_plural');
          // eslint-disable-next-line no-negated-condition
          var translationTwoPromise = (malfunctions.length !== 1) ? $translate('header.incidents.malfunctions_plural') : '';

          $q.all(
            [
              translationOnePromise,
              translationTwoPromise
            ]
          ).then(function (malfunctionTranslations) {
            deferred.resolve({
              total: {
                malfunctions: malfunctions.length,
                planned_incidents: planned_incidents.length,
                combined: (malfunctions.length + planned_incidents.length)
              },
              malfunctions: malfunctions,
              planned_incidents: planned_incidents,
              malfunctions_prefix: malfunctionTranslations[0],
              malfunctions_plural: malfunctionTranslations[1]
            });
          });
        }, function(error) {
          deferred.reject(error);
        });
      }

      return _cache;
    }

  }

  angular.module('wml.portal.incidents')
    .factory('incidentService', incidentService);

})();
