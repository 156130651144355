(function () {
  'use strict';

  /** @ngInject */
  function UsageInstallmentChangedController($stateParams, $rootScope, customerService, cancelLink) {
    var vm = this;

    // private variables

    // Public variables
    vm.contract_account_id    = $stateParams.contract_account_id;
    vm.contract_id            = $stateParams.contract_id;
    vm.premise_id             = $stateParams.premise_id;

    // Public function definitions

    // Public functions

    // Private functions

    activate();

    function activate() {
      customerService.getPremise(vm.premise_id).then(function(response){
        if (angular.isDefined(response.address.house_no_addition) && response.address.house_no_addition.length) {
          vm.premise = response.address.street + ' ' + response.address.house_no + ' ' + response.address.house_no_addition + ', ' + response.address.postal_code + ' ' + response.address.city;
        } else {
          vm.premise = response.address.street + ' ' + response.address.house_no + ', ' + response.address.postal_code + ' ' + response.address.city;
        }

        $rootScope.$broadcast('clear-cache');
      });

      customerService.getBudgetBillingPlan($stateParams.contract_account_id, $stateParams.contract_id).then(function(response) {
        vm.budgetBillingPlan = response;
      });

      vm.cancelLink = cancelLink;
    }
  }

  angular
    .module('wml.portal.usage')
    .controller('UsageInstallmentChangedController', UsageInstallmentChangedController);
})();
