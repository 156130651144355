(function () {
  'use strict';

  /** @ngInject */
  function UsageMeterReadingsController($state, $scope, $stateParams, $filter, moment, customerService, usageMeterReadingService, cancelLink, goToLink) {
    var vm = this;

    // private variables
    var _budgetBillingPlan = {};

    // Public variables
    vm.form        = null;
    vm.sendingData = false;
    vm.consumption = null;
    vm.meter_number = null;
    vm.premise_id  = $stateParams.premise_id;

    vm.data = {
      date              : moment().format('DD-MM-YYYY'),
      meter_reading     : '',
      num1              : '',
      num2              : '',
      num3              : '',
      num4              : '',
      num5              : '',
      num6              : '',
      num7              : ''
    };

    // Public function definitions
    vm.save   = save;
    vm.cancel = cancel;

    // Public functions
    function save() {
      var combinedReadingNumbers = vm.data.num1 + vm.data.num2 + vm.data.num3 + vm.data.num4 + vm.data.num5 + vm.data.num6 + vm.data.num7;
      var meterReading = {reading_result: combinedReadingNumbers};

      if(vm.form.$valid) {
        var data = meterReading;
        usageMeterReadingService.setMeterReading(data);
        if(angular.isDefined(_budgetBillingPlan.budget_billing_plan_id) && _budgetBillingPlan.is_changeable){
          vm.sendingData = true;
          customerService.setReadingResult($stateParams.contract_account_id, $stateParams.contract_id, _budgetBillingPlan.budget_billing_plan_id, meterReading).then(function(response) {
            data = angular.extend(response.plain(), meterReading);
            usageMeterReadingService.setMeterReading(data);
          }).finally(function(){
            vm.sendingData = false;
            $state.go(goToLink, $stateParams);
          });
        } else {
          $state.go(goToLink, $stateParams);
        }
      }
    }

    function cancel() {
      $state.go(cancelLink, $stateParams);
    }

    // Private functions

    activate();

    function activate() {
      customerService.getBudgetBillingPlan($stateParams.contract_account_id, $stateParams.contract_id).then(function (response) {
        _budgetBillingPlan = response;
      });

      customerService.getConsumption($stateParams.contract_account_id, $stateParams.contract_id).then(function (response) {
        for (var i = 0; i < response[0].meter_reading_results.length; i++) {
          if (response[0].meter_reading_results[i].is_open === false) {
            response[0].meter_reading_results[i].reading_date_time = $filter('date')(response[0].meter_reading_results[i].reading_date_time, 'longDate');
            vm.consumption = response[0].meter_reading_results[i];
            vm.meter_number = response[0].serial_number;
            break;
          }
        }
      });

      customerService.getPremise(vm.premise_id).then(function(response){

        vm.numberOfMeterNumbers = response.device.pre_decimal || 5;

        vm.aNumberOfMeterNumbers  = [];
        for(var i=0; i < vm.numberOfMeterNumbers; i++){
          vm.aNumberOfMeterNumbers[i] = i + 1;
        }

        $scope.$watch(function() {return vm.data.meter_reading;}, function(newValue) {
          if(angular.isString(newValue)) {
            var data    = newValue.split('');
            var length  = data.length;
            if(length < vm.numberOfMeterNumbers) {
              for(var j=0; j < (vm.numberOfMeterNumbers-length); j++) {
                data.unshift('0');
              }
            }
            for(var k=0; k < data.length; k++) {
              var key = 'num' + (k+1);
              vm.data[key]  = data[k];
            }
          } else {
            for(var l=1; l <= vm.numberOfMeterNumbers; l++) {
              vm.data['num' + l]  = 0;
            }
          }
        });
      });
    }
  }

  angular
    .module('wml.portal.usage')
    .controller('UsageMeterReadingsController', UsageMeterReadingsController);
})();
