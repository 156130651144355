(function() {
  'use strict';

  /** @ngInject */
  function registerService(storage, RestangularExt) {

    var _defaults = {
      insertions            : [],
      connectionAddress     : {
        premise_id                 : null,
        customerType               : 'residence',
        number_of_persons          : null,
        postal_code                : null,
        house_no                   : null,
        house_no_addition          : null,
        street                     : null,
        city                       : null,
        contract_id                : false,
        device_id                  : false,
        hasDifferentCorrespondence : false,
        meter_number              : false
      },
      correspondenceAddress : {
        country             : null,
        type                : null,
        postal_code         : null,
        house_no            : null,
        house_no_addition   : null,
        street              : null,
        city                : null,
        po_box_postal_code  : null,
        po_box              : null
      },
      personalInformation   : {
        customerType        : null,
        salutation          : null,
        initials            : null,
        insertion           : null,
        lastName            : null,
        birthday            : null,
        companyName         : null,
        phone               : null,
        mobile_phone        : null,
        email               : null,
        emailInvoice        : true,
        customerSurvey      : false
      },
      meterReading          : {
        date                : new Date(),
        deviation_code      : null,
        deviation_reason    : null,
        meter_reading       : '',
        num1                : 0,
        num2                : 0,
        num3                : 0,
        num4                : 0,
        num5                : 0,
        file_upload: {
          file: null,
          name: null,
          type: null,
        },
      },
      paymentMethod         : {
        payment_method      : {
          label: null,
          value: null
        },
        payment_frequency   : {
          label: null,
          value: null
        },
        payment_date        : {
          label: null,
          value: null
        },
        iban   : null
      },
      portalAccount           : {
        email                 : null,
        email_conformation    : null,
        password              : null,
        password_confirmation : null,
        isSet                 : null
      },
      summary                 : {
        conditionAccepted     : false
      },
      forced_meter_reading  : false,
      prospect: {
        exits: null,
        moveDate: null,
        premise: null
      },
      bIsInFuture: null,
      credentials: {
        postal_code: null,
        house_no: null,
        house_no_addition: null,
        future_move_token: ''
      }
    };

    var _registerData;

    if(storage('register_customer') !== null) {
      _registerData = storage('register_customer');
    } else {
      _registerData = angular.copy(_defaults);
    }

    return {
      data      : _registerData,
      storeData : storeData,
      clearData : clearData,
      save      : save,
      prospectToUser: prospectToUser
    };

    function storeData() {
      // do not save the file upload data to the cookie, base64 will become too big.
      var copy = angular.copy(_registerData);
      copy.fileUpload = null;
      // file object does not need to get saved in cookie
      if (copy.meterReading && copy.meterReading.file_upload) {
        copy.meterReading.file_upload.file = null;   // base64 can get too big to save in Fcookie.
      }
      storage('register_customer', copy);
    }

    function clearData() {
      _resetField(_registerData);
      storage('register_customer', null);
    }

    function save() {
      return RestangularExt.all('register').post(_registerData);
    }

    function prospectToUser() {
      return RestangularExt.all('register/prospect-to-user').post(_registerData);
    }

    function _resetField(field, keyPath) {
      keyPath = keyPath || [];

      if(angular.isObject(field)) {
        for(var key in field) {
          if(field.hasOwnProperty(key)) {
            keyPath.push(key);
            if(angular.isObject(field[key]) && angular.isObject(_defaultForKey(keyPath))) {
              // keyPath.pop();
              _resetField(field[key], keyPath);
            } else {
              field[key]  = _defaultForKey(keyPath);
              keyPath.pop();
            }
          }
        }
        keyPath.pop();
      }
    }

    function _defaultForKey(keyPath) {
      var tmpValue  = angular.copy(_defaults);
      for(var i=0; i < keyPath.length; i++) {
        if(angular.isDefined(tmpValue[keyPath[i]])) {
          tmpValue  = tmpValue[keyPath[i]]
        } else {
          return null;
        }
      }
      return tmpValue;
    }
  }

  angular.module('wml.portal.register')
    .factory('registerService', registerService);

})();
