(function() {
  'use strict';

  /** @ngInject */
  function InvoicesPaymentArrangementController($q, $stateParams, enumService, $window, $state, $rootScope, customerService, invoicesService, ivAuthenticate) {
    var vm = this;

    // private variables
    vm.contract_account_id = null;

    // Public variables
    vm.connectionAddresses    = [];
    vm.form                   = null;
    vm.bPaymentMethodDisabled = false;
    vm.draft                  = {
      automatic_collection: false
    };
    vm.sendingData            = false;
    vm.paymentFrequency       = [];

    // Public function definitions
    vm.save = save;
    vm.updatePaymentFrequencyAmount = updatePaymentFrequencyAmount;

    // Public functions
    function save() {
      invoicesService.postInstallmentPlan(vm.contract_account_id, vm.draft).then(function () {
        $state.go('invoicesDashboard', {refresh: true});
      });
    }

    // update the payment amount per frequency
    function updatePaymentFrequencyAmount() {
      vm.draft.payment_frequency_amount = vm.draft.total_amount / vm.draft.payment_frequency;
    }

    // get iban number of contract-account
    function getCurrentPaymentMethod() {
      customerService.getContractAccount().then(function(response) {
        if (!angular.isArray(response) || response.length < 1) { return;}
        for (var i = 0; i < response.length; i += 1) {
          if (angular.isDefined(response[i].incoming_bank_account) && response[i].incoming_bank_account !== null) {
            // use first available iban
            vm.draft.iban = response[i].incoming_bank_account.iban;
            return;
          }
        }
      });
    }

    activate();

    function activate() {
      customerService.getPremises().then(function (data) {
        if (data.length > 0 && data[0].contract_account_id) {
          vm.contract_account_id = data[0].contract_account_id;
          invoicesService.getInstallmentPlanChecks(vm.contract_account_id).then(function (data) {
            // make array for terms options of select box, from 1 - data.terms.
            var counter = 1;
            while (counter < data.terms) {
              vm.paymentFrequency.push(counter += 1);
            }

            vm.draft.total_amount = data.total_amount;
            vm.draft.payment_frequency = 2;
            vm.updatePaymentFrequencyAmount();
          });
        }
      });

      getCurrentPaymentMethod();
    }

  }
  angular
    .module('wml.portal.invoices')
    .controller('InvoicesPaymentArrangementController', InvoicesPaymentArrangementController);
})();
