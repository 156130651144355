(function() {
  'use strict';

  function unavailable($rootScope) {

    return {
      restrict  : 'EA',
      templateUrl : 'app/components/generic/directives/unavailable/views/overlay.html',
      replace     : false,
      link: function(scope, iElem, iAttrs) {
        $rootScope.unavailable = false;

        scope.$on('wml.unavailable.start', function() {
          $rootScope.unavailable = true;
        });

        scope.$on('wml.unavailable.stop', function() {
          $rootScope.unavailable = false;
        });
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('unavailable', unavailable)
})();
