(function () {
  'use strict';

  function uibDatepickerMonth($provide) {
    $provide.decorator('uibDatepickerDirective', ['$delegate', '$document', function ($delegate, $document) {
      var doc = $document[0];
      var directive = $delegate[0];
      // get a copy of the directive's original compile function
      var directiveCompile = directive.compile;

      // overwrite the original compile function
      directive.compile = function (tElement, tAttrs) {
        // call the directive's compile with apply to send the original 'this' and arguments to it
        var link = directiveCompile.apply(this, arguments);

        // here's where the magic starts
        return function (scope, element, attrs, ctrls) {
          // call the original link
          link.apply(this, arguments);

          // var uibWeeks = doc.getElementsByClassName('uib-weeks');

          // move is the internal function called when you click
          // on the chevrons for previous and next month
          var originalMove = scope.move;
          scope.move = function (direction) {
            originalMove.apply(this, arguments);
            // when move is called, the 'this' object contains a 'title' property with the current month!
            var currentMonth = this.$parent.activeDt.date;

            // emit the event to parent scopes
            scope.$emit('datepicker.monthChanged', currentMonth);

            scope.datepicker.refreshView();

            scope.$emit('datepicker.format', scope.datepickerMode);
          };

          var originalToggleMode = scope.toggleMode;
          scope.toggleMode = function (direction) {
            originalToggleMode.apply(this, arguments);
            // when move is called, the 'this' object contains a 'title' property with the current month!
            var current = this.title;
            // emit the event to parent scopes
            scope.$emit('datepicker.toggleModeChanged', current);
          };

          var originalSelect = scope.select;
          scope.select = function (date) {
            originalSelect.apply(this, arguments);
            // when move is called, the 'this' object contains a 'title' property with the current month!
            var currentMonth = this.dt.date;
            // emit the event to parent scopes
            scope.$emit('datepicker.monthChanged', currentMonth);

            scope.datepicker.refreshView();

            scope.$emit('datepicker.format', scope.datepickerMode);
          };
        };
      };

      return $delegate;
    }]);
  }

  angular.module('wml.portal.generic').config(['$provide', uibDatepickerMonth]);
})();
