(function () {
  'use strict';

  /** @ngInject */
  function meterReadingThankYouController($state, ivAuthenticate, customerService, $rootScope, moment, meterReadingService, $stateParams) {
    var vm = this;
    vm.data = meterReadingService.data;

    // private variables

    // Public variables

    // Public function definitions

    vm.next = next;
    vm.save = save;

    vm.accept_conditions = false;
    vm.amount = 5;
    vm.custom_amount = 5;
    vm.wflSuccess = false;
    vm.isAuthenticated = false;
    // Public functions

    function next() {
      meterReadingService.clearData();
      if (vm.isAuthenticated) {
        $state.go('dashboard');
      } else {
        $state.go('home');
      }
    }

    // Private functions

    activate();

    function activate() {
      if (ivAuthenticate.isAuthenticated()) {
        vm.isAuthenticated = true;
      }
      meterReadingService.getWFL($stateParams).then(function (response) {
        vm.data.wfl_amount = response.wfl_amount;
        vm.data.wfl_month = response.wfl_month;
        vm.data.wfl_possible = response.wfl_possible;
      });
    }

    function save() {
      // if optie 'anders' [amount = 0] use vm.custom_amount [min 5];
      if (vm.accept_conditions) {
        if (vm.amount === 0) {
          vm.data.wfl_amount = vm.custom_amount;
        } else {
          vm.data.wfl_amount = vm.amount;
        }

        if (vm.data.wfl_amount >= 5) {
          vm.data.wfl_month = moment().format('M');

          meterReadingService.setWFL($stateParams, vm.data.wfl_amount, vm.data.wfl_month).then(function (response) {
            if (response) {
              vm.wflSuccess = true;
              $rootScope.$broadcast('clear-cache', {customer: {customer: true}});
              $rootScope.$broadcast('wml.portal.custom_message', {level: 'success', code: '1000'});
            }
          });
        }
      }
    }
  }

  angular
    .module('wml.portal.meterReading')
    .controller('meterReadingThankYouController', meterReadingThankYouController);
})();
