(function() {
  'use strict';

  /** @ngInject */
  function SideMenuController($state, navigationService) {
    var vm  = this;

    vm.currentState     = $state.current.name;
    vm.menuItems        = navigationService.getMenu.header;
    vm.getItems         = getItems;

    function getItems(level, parent) {
      parent = parent || null;
      return vm.menuItems.filter(function(item) {
        if(!parent) {
          return (parseInt(item.parent, 10) === parseInt(level, 10) && item.active && item.bVisible);
        } else {
          return (parseInt(item.parent, 10) === parseInt(level, 10) && parseInt(item.parentId, 10) === parseInt(parent, 10) && item.bVisible);
        }
      });
    }

  }

  angular
    .module('wml.portal.sideMenu')
    .controller('SideMenuController', SideMenuController);
})();
