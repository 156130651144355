(function() {
  'use strict';

  /** @ngInject */
  function PortalHeaderController($state, $rootScope, $window, customerService, storage, ivAuthenticate, incidentService, navigationService, breadcrumbs, headerService, $location) {
    var vm  = this;
    // private variables

    // Public variables
    vm.customers                = null;
    vm.currentCustomer          = null;
    vm.isNavCollapsed           = true;
    vm.isCompany                = false;
    vm.breadcrumbs              = breadcrumbs.getPath();
    vm.headerContent            = null;
    vm.currentState             = $state.current.name;
    vm.searchLink               = $state.href("search");
    vm.searchQuery              = '';
    vm.bCorporation             = storage('corporation') || false;

    // Public function definitions
    vm.changeCustomer = changeCustomer;
    vm.logout         = logout;
    vm.showIncidents  = showIncidents;
    vm.goToIncident   = goToIncident;
    vm.search         = search;

    navigationService.load();

    // Public functions
    function search(){
      $location.url(vm.searchLink).search('q',vm.searchQuery);
    }

    function changeCustomer(customerNumber) {
      storage('customer_number', customerNumber);
      $state.go('dashboard');
    }

    function logout() {
      ivAuthenticate.logout().then(function() {
        $rootScope.$broadcast('clear-cache');
        $window.location.href = $state.href('login');
      });
    }

    // Private functions
    function getCustomers() {
      customerService.getCustomers().then(getCustomersSuccess, function() {
        vm.bAuthenticated = false;
      });
    }

    function getCustomersSuccess(response) {
      vm.customers = response;

      var currentCustomerNumber = storage('customer_number');

      vm.currentCustomer = _.find(vm.customers, function(item){
        return item.customer_number === currentCustomerNumber;
      });

      if(vm.currentCustomer.is_company === true){
        vm.isCompany        = true;
      }
    }
    activate();

    function activate(){
      headerService.loadData().then(function(response){
        vm.headerContent = response;
      });

      if(ivAuthenticate.isAuthenticated()){
        vm.bAuthenticated = true;
        getCustomers();
      } else {
        vm.bAuthenticated = false;
      }
      incidentService.load().then(handleIncidents);
    }

    function handleIncidents(response) {
      vm.incidents  = response;
    }

    function showIncidents() {
      $window.open('https://www.waterstoring.nl/wml', '_blank');
    }

    function goToIncident(link) {
      $window.open(link, '_blank');
    }
  }

  angular
    .module('wml.portal.header')
    .controller('PortalHeaderController', PortalHeaderController);
})();
