(function() {
  'use strict';

  /** @ngInject */
  function billingInformationService(RestangularExt, storage, $rootScope) {

    var cacheServiceName  = 'billingInformation';
    var cache = {
      sendcontrol : null
    };

    var updated = {};

    $rootScope.$on("clear-cache", function(evt, evtData) {
      var key;
      if(angular.isObject(evtData)) {
        if(evtData[cacheServiceName]) {
          if(typeof evtData[cacheServiceName] === 'boolean' && evtData[cacheServiceName] === true) {
            for(key in cache) {
              cache[key] = null;
            }
          } else if(angular.isObject(evtData[cacheServiceName])) {
            for(key in cache) {
              if(typeof evtData[cacheServiceName][key] === 'boolean' && evtData[cacheServiceName][key] === true) {
                cache[key]  = null;
              }
            }
          }
        }
      } else {
        for(key in cache) {
          cache[key] = null;
        }
      }
    });

    return {
      getSendControl    : getSendControl,
      updateSendControl : updateSendControl
    };

    function getSendControl() {
      if(cache.sendcontrol === null) {
        cache.sendcontrol = RestangularExt.one('customer', storage('customer_number')).customGET('send-control');
      }
      return cache.sendcontrol;
    }

    function updateSendControl(sendControl) {
      return RestangularExt.one('customer', storage('customer_number')).customPUT({sendcontrol: sendControl}, 'send-control').then(function(response) {
        cache.sendcontrol = null;
        return response;
      });
    }

  }

  angular.module('wml.portal.billingInformation')
    .factory('billingInformationService', billingInformationService);

})();
