(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterThankYouController($rootScope, premiseUnregisterService, ivAuthenticate) {

    var vm = this;
    vm.data = premiseUnregisterService.data;
    vm.isAuth = false;

    vm.isInFuture = null;
    vm.inFutureTranslation = '';
    vm.moveOutDate = '';

    // private variables

    // Public variables

    // Public function definitions

    // Public functions

    // Private functions

    activate();

    function activate() {
      if (ivAuthenticate.isAuthenticated()) {
        vm.isAuth = true;
      }
      vm.isInFuture = vm.data.bIsInFuture;
      vm.inFutureTranslation = (vm.isInFuture) ? 'future_' : '';
      vm.moveOutDate = vm.data.meter_reading.date;
      premiseUnregisterService.clearData();
      $rootScope.$broadcast('clear-cache', {customer: true});
    }
  }

  angular
    .module('wml.portal.premisesRegister')
    .controller('PremisesUnregisterThankYouController', PremisesUnregisterThankYouController);
})();
