(function() {
  'use strict';

  /** @ngInject */
  function newsService(RestangularExt) {

    return {
      addComment: addComment
    };

    function addComment(name, email, message, content_index) {
      return RestangularExt.all('news/comment').post({
        name         : name,
        email        : email,
        message      : message,
        content_index: content_index
      });
    }
  }

  angular.module('wml.portal.news')
    .factory('newsService', newsService);

})();
