(function () {
  'use strict';

  /** @ngInject */
  function PremisesRegisterLoginController($state, $rootScope, $timeout, $location, customerService, premiseRegisterService, $stateParams, ivAuthenticate, storage, jwtHelper) {
    var vm = this;

    vm.form = null;
    vm.data = premiseRegisterService.data;
    vm.sendingData = false;

    // Public function definitions
    vm.doLogin = doLogin;

    function doLogin() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        $rootScope.$broadcast('wml.portal.message.cancel');
        $rootScope.$broadcast('wml.loader.start');

        vm.sendingData = true;

        vm.data.credentials.customer_number = vm.form.customer_number;
        vm.data.credentials.postal_code = vm.form.postal_code;
        vm.data.credentials.house_no = vm.form.house_no;
        vm.data.credentials.house_no_addition = vm.form.house_no_addition;

        ivAuthenticate.shortTermLogin(vm.data.credentials).then(function (response) {
          var claims = jwtHelper.decodeToken(response.data.access_token);

          if (angular.isDefined(claims)) {
            if (angular.isDefined(claims.corporation)) {
              storage('corporation', claims.corporation);
            }
            if (angular.isDefined(claims.company)) {
              storage('company', claims.company);
            }
          }

          if (angular.isDefined(claims) && angular.isDefined(claims.aud)) {
            storage('customer_number', claims.aud);

            // if hosFutureMove token query param
            if (vm.data.credentials.future_move_token.length) {

              customerService.getFutureMove(vm.data.credentials).then(function (futureMoveRes) {
                var futureMove = futureMoveRes[0];
                vm.data.prospect = {
                  exists: Boolean(futureMove.customer),
                  moveDate: futureMove.date || '',
                  premise: {
                    postal_code: futureMove.postal_code,
                    house_no: futureMove.house_num_1,
                    house_no_addition: futureMove.house_num_2
                  }
                };
                vm.data.address.house_no = futureMove.house_num_1;
                vm.data.address.house_no_addition = futureMove.house_num_2;
                vm.data.address.postal_code = futureMove.postal_code;
                // if prospect exists
                if (vm.data.prospect.exists) {
                  // we are on the right track
                  vm.data.meter_reading.date = new Date(vm.data.prospect.moveDate).setHours(0, 0, 0, 0);
                  premiseRegisterService.storeData();
                  $state.go('premisesRegister');
                } else {
                  // Notification: This token has no future move request assigned, contact customer service
                  $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
                }
              }).catch(function () {
                // Notification: Failed to get future move data, contact customer service
                $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12101});
              });
            } // else if no token available, this will be caught in active()
          } else {
            // Notification: invalid credentials
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 2000});
          }
        }, function (error) {
          if (error === null) { return; }

          var params = (angular.isDefined(error.fields)) ? error.fields : {};

          if (angular.isDefined(error.code)) {
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params : params});
          } else {
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '2014'});
          }
        }).finally(function () {
          $timeout(function () {
            $rootScope.$broadcast('wml.loader.stop');
          }, 1000);

          vm.sendingData = false;
        });
      } else {
        // not valid form
        $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1001'});
      }
    }

    function activate() {
      // this controller is only used with future move token
      var queryParams = $location.search();
      if (queryParams.fm && queryParams.fm.length) {
        // date not set && future move token exists
        if (/^\w+$/gi.test(queryParams.fm)) {
          customerService.getFutureMoveCustomerNr(queryParams.fm).then(function (futureMoveCustomerNr) {
            if (futureMoveCustomerNr) {
              vm.form.customer_number = futureMoveCustomerNr;
              vm.data.credentials.future_move_token = queryParams.fm;
              return;
            }
            throw new Error(); // Notification: invalid token format
          }).catch(function () {
            // Notification: invalid token format
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12100});
            return;
          });
        } else {
          // Notification: invalid token format, contact customer service.
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12100});
          return;
        }
      } else {
        // Notification: no token available, contact customer service.
        $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12105});
      }
    }
    activate();
  }

  angular.module('wml.portal.register')
    .controller('PremisesRegisterLoginController', PremisesRegisterLoginController);

})();
