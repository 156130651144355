(function() {
  'use strict';

  /** @ngInject */
  function bannerService(RestangularExt) {

    var cache = {};

    return {
      loadData  : loadData
    };


    function loadData(contentIndexes) {
      var url = '/instellingen/banners';

      if(angular.isArray(contentIndexes) && contentIndexes.length){
        url = url+'?contentindexes=' + contentIndexes.join(',');
      }

      if(!cache[url]) {
        cache[url] = RestangularExt.all('proxy').customGETLIST(null, {url: url});
      }

      return cache[url];
    }

  }

  angular.module('wml.portal.banners')
    .factory('bannerService', bannerService);

})();
