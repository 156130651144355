(function () {
  'use strict';

  /** @ngInject */
  function appointmentEmailController($state, appointmentService, ivAuthenticate) {
    var vm = this;

    // private variables


    // Public variables
    vm.form         = null;
    vm.data         = {};
    vm.appointment  = null;
    vm.isScheduled  = false;
    vm.mode = '';

    // Public function definitions

    vm.next = next;
    vm.cancel = cancel;

    // Public functions
    function next() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        appointmentService.storeAppointment(vm.data);
        $state.go('appointmentCalendar' + vm.mode);
      }
    }

    function cancel(){
      appointmentService.clearAppointment();
      if (ivAuthenticate.isAuthenticated()) {
        $state.go('dashboard');
      } else {
        $state.go('appointmentFormCreate');
      }
    }

    // Private functions

    activate();

    function activate() {
      vm.mode = $state.current.name.split('appointmentEmail')[1];

      var appointmentData = appointmentService.getAppointment();
      if (angular.isObject(appointmentData)) {
        if (appointmentData.device) {
          vm.meterTypes = appointmentData.device.future_qn;
        }
        vm.isScheduled = appointmentData.is_scheduled;
        vm.data = appointmentData;
        if (!vm.isScheduled) {
          if (angular.isUndefined(vm.data.meter_type)) {
            vm.data.meter_type = appointmentData.device.preselected || 'meter_default';
          }
        }
      }
    }
  }

  angular
    .module('wml.portal.appointment')
    .controller('appointmentEmailController', appointmentEmailController);
})();
