(function() {
  'use strict';

  /** @ngInject */
  function readspeakerText($rootScope, $transitions, $cookies) {
    return {
      restrict: 'E',
      replace: false,
      templateUrl : 'app/components/generic/directives/readspeaker/views/readspeaker-text.html',
      link: function (scope, elem, attrs, _ctrl) {

        if (!$cookies.get('_rspkrLoadCore')) {
          window.ReadSpeaker.loadCore();
        }

        scope.readClass = 'content';

        // Listen for changes for the classes to read
        $rootScope.$on('readspeaker.readclass', function(evt, data) {
          scope.readClass = data;
          if(data === '') {
            document.getElementById('readspeak').classList.add('hidden');
          }
        });

        // Listen for route change and stop reader if needed
        $transitions.onStart({}, function() {
          stopPlaying();
          var _readSpeaker = elem[0].querySelectorAll('#readspeaker_button1');
          if(_readSpeaker[0].classList.contains('rsdetached')){
            _readSpeaker[0].classList.remove('rsdetached');
            _readSpeaker[0].style.top = "auto";
            _readSpeaker[0].style.left = "auto";
          }
        });

        $rootScope.$on('readspeaker.form.isPlaying', function(evt, isPlaying) {
          if (isPlaying && scope.isPlaying) {
            stopPlaying();
          }
        });

        scope.currentLocation = encodeURIComponent(window.location);
        scope.isPlaying = false;

        scope.toggleReadspeaker = function() {
          if (scope.isPlaying) {
            stopPlaying();
          } else {
            startPlaying();
          }
        };

        function startPlaying() {
          var playButton = elem[0].querySelectorAll('.rsbtn_play');
          if (playButton.length > 0) {
            clickElement(playButton[0]);
            scope.isPlaying = true;
            $rootScope.$broadcast('readspeaker.text.isPlaying', scope.isPlaying);
          }
        }

        function stopPlaying() {
          var stopButton = elem[0].querySelectorAll('.rsbtn_closer');
          if (stopButton.length > 0) {
            clickElement(stopButton[0]);
            scope.isPlaying = false;
            $rootScope.$broadcast('readspeaker.text.isPlaying', scope.isPlaying);
          }
        }

        function clickElement(element) {
          var event;
          if (typeof(Event) === 'function') {
            event = new Event('click');
          } else {
            event = document.createEvent('Event');
            event.initEvent('click', true, true);
          }
          element.dispatchEvent(event);
        }

        frInit();
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('readspeakerText', readspeakerText);
})();
