(function() {
  'use strict';

  function match() {
    return {
      require   : 'ngModel',
      restrict  : 'A',
      scope: {
        modelValue : '=ngModel',
        otherModelValue: "=match"
      },
      link: function(scope, elm, attrs, ctrl) {
        scope.$watchGroup(['modelValue', 'otherModelValue'], function() {
          if((angular.isDefined(scope.modelValue) && angular.isString(scope.modelValue) && scope.modelValue.length) && (angular.isDefined(scope.otherModelValue) && angular.isString(scope.otherModelValue) && scope.otherModelValue.length)){
            if(scope.modelValue === scope.otherModelValue){
              ctrl.$setValidity('match', true);
            } else {
              ctrl.$setValidity('match', false);
            }
          }
        });
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('match', match)
})();
