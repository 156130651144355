(function() {
  'use strict';

  /** @ngInject */
  function passwordValidator(passwordService) {

    return {
      restrict: 'EA',
      require: 'ngModel',
      scope: {
        password: '=ngModel'
      },
      link: function(scope, elem, attrs, ctrl) {
        scope.$watch('password', function(e) {
          ctrl.$setValidity('password', passwordService.validateInput(scope.password, true));
        });
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('passwordValidator', passwordValidator)
})();
