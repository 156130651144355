(function() {
  'use strict';

  /** @ngInject */
  function RegisterController($scope, $state, addressService, registerService, customerService, $rootScope) {
    var vm = this;

    // private variables
    var _lastAutofill = null;
    vm.data = registerService.data;

    // public variables
    vm.loading  = false;
    vm.noValidAddress = true;

    // public functions
    vm.save = save;
    vm.cancel = cancel;
    vm.autofillHouseNo = autofillHouseNo;
    vm.hideNotification = hideNotification;

    $scope.$watch(function() {return vm.data.connectionAddress.customerType;}, function(newValue, oldValue) {
      if(newValue === 'company') {
        vm.data.connectionAddress.number_of_persons = 1;
      }
    });

    // save functions
    function save() {
      if (vm.form.$valid) {
        registerService.storeData();

        var futureMoveCheck = {
          postal_code: vm.data.connectionAddress.postal_code,
          house_no: vm.data.connectionAddress.house_no,
          house_no_addition: vm.data.connectionAddress.house_no_addition,
        };

        // always check if address has future move
        customerService.hasFutureMove(futureMoveCheck).then(function (isProspect) {
          // api Type Enum = MOVE_OUT:'O', MOVE_IN: 'I', MOVE_IN_OUT:'X';
          var moveInAlreadyExists = (isProspect.exists && (isProspect.move_in_out === 'I' || isProspect.move_in_out === 'X'));
          if (moveInAlreadyExists && !vm.data.credentials.future_move_token.length) {
            // notification: is already a prospect, contact customer service
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12103'});
            return;
          }

          if (!vm.data.connectionAddress.hasDifferentCorrespondence) {
            $state.go('registerPersonalInfo');
          } else {
            $state.go('registerCorrespondence');
          }
        })
          .catch(function () {
            // notification: failed to check if has future move data, contact customer service.
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12104'});
            return;
          });
      }
    }

    function cancel() {
      // make decision based on future move token, go to premisses-register login
      if (vm.data.credentials.future_move_token.length) {
        $state.go('registerLogin', {fm: vm.data.credentials.future_move_token});
      } else {
        $state.go('registerMoveInDate');
      }
    }

    function hideNotification() {
      $rootScope.$broadcast('wml.portal.message.cancel');
    }

    // autofill functions
    function autofillHouseNo() {
      if(angular.isString(vm.data.connectionAddress.postal_code) && angular.isString(vm.data.connectionAddress.house_no)) {
        vm.data.connectionAddress.postal_code = vm.data.connectionAddress.postal_code.toUpperCase();

        if (/^[0-9]{4}[A-Z]{2}$/.test(vm.data.connectionAddress.postal_code.replace(/\s/g, ''))) {
          var data = {
            postal_code       : vm.data.connectionAddress.postal_code,
            house_no          : vm.data.connectionAddress.house_no,
            house_no_addition : null
          };

          if(angular.isString(vm.data.connectionAddress.house_no_addition) && vm.data.connectionAddress.house_no_addition.length > 0) {
            data.house_no_addition  = vm.data.connectionAddress.house_no_addition;
          }

          var autofillKey = data.postal_code + '_' + data.house_no + '_' + data.house_no_addition;
          if(autofillKey === _lastAutofill) {
            return false;
          }
          vm.noValidAddress   = true;
          vm.loading                            = true;
          vm.data.connectionAddress.city        = null;
          vm.data.connectionAddress.street      = null;

          _lastAutofill = autofillKey;

          autoFill(data);
        }
      }
    }

    function autoFill(data) {
      addressService.autofill(data).then(function(response) {
        var address = angular.copy(response.plain());

        vm.data.connectionAddress.premise_id  = address.premise_id || null;
        vm.loading = false;
        vm.noValidAddress   = false;

        // $rootScope.$broadcast('wml.portal.message.cancel');

        if(typeof address.valid_for_subscription === 'boolean' && !address.valid_for_subscription){
          vm.noValidAddress = true;
          $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1005'});
        }

        if(address.city === null){
          vm.noValidAddress = true;
          $rootScope.$broadcast('wml.portal.custom_message', {level: 'error', code: '1004'});
        } else {
          vm.hideNotification();
        }

        if(address.street){
          vm.data.connectionAddress.street = address.street;
        }

        if(address.house_no_addition){
          vm.data.connectionAddress.house_no_addition = address.house_no_addition;
        }

        if(address.city){
          vm.data.connectionAddress.city = address.city;
        }

        if (address.meter_number) {
          vm.data.connectionAddress.meter_number = address.meter_number;
        }

        if (address.contract_id) {
          vm.data.connectionAddress.contract_id = address.contract_id;
        }

        if (address.device_id) {
          vm.data.connectionAddress.device_id = address.device_id;
        }
      });
    }

    activate();

    function activate() {
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

      if (angular.isDefined(vm.data.prospect.exists) && vm.data.prospect.exists && vm.data.credentials.future_move_token.length) {
        vm.data.connectionAddress.postal_code = vm.data.prospect.premise.postal_code;
        vm.data.connectionAddress.house_no = vm.data.prospect.premise.house_no;
        vm.data.connectionAddress.house_no_addition = vm.data.prospect.premise.house_no_addition;
      }

      if (vm.data.connectionAddress.postal_code && vm.data.connectionAddress.house_no) {
        vm.autofillHouseNo();
      }

      if(vm.data.connectionAddress.city !== null){
        vm.noValidAddress = false;
      }
    }

  }

  angular
    .module('wml.portal.register')
    .controller('RegisterController', RegisterController);
})();
