(function() {
  'use strict';

  /** @ngInject */
  function activationService(RestangularExt) {
    var _activationRoute = RestangularExt.all('/customer/activate');

    return {
      activate                : activate,
      requestNewActivation    : requestNewActivation
    };

    function activate(activationFields) {
      return _activationRoute.post(activationFields);
    }

    //renew activation
    function requestNewActivation(activationFields) {
      return _activationRoute.get(activationFields);
    }

  }

  angular.module('wml.portal.authentication')
    .factory('activationService', activationService);

})();
