(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterController($state, $rootScope, customerService, premisesService, premiseUnregisterService, premiseRegisterService, $stateParams, ivAuthenticate, storage, moment) {
    var vm = this;

    vm.data = premiseUnregisterService.data;
    vm.registrablePremises = []; // all addresses that can be unregistered
    vm.activePremises = []; // address that are not active but can ub unregistered
    vm.premisesAlreadyUnregistered = []; // address that are not active but can ub unregistered
    vm.unregistrablePremises = []; // addresses that can not be unregistered

    // Public function definitions
    vm.next = next;
    vm.getEmail = getEmail;
    vm.cancel = cancel;
    vm.setAddress = setAddress;
    vm.setInfoForInactivePremises = setInfoForInactivePremises;
    vm.getInfo = getInfo;
    vm.preSetInfoForInactiveAddressAndNav = preSetInfoForInactiveAddressAndNav;
    vm.activateCorporation = activateCorporation;
    vm.activateStandardCustomer = activateStandardCustomer;

    // Public functions
    function next() {
      if (vm.form.$valid) {
        premiseUnregisterService.storeData();

        var futureMoveCheck = {
          postal_code: vm.data.address.address.postal_code,
          house_no: vm.data.address.address.house_no,
          house_no_addition: vm.data.address.address.house_no_addition
        };

        // check if future move already exists
        customerService.hasFutureMove(futureMoveCheck).then(function (hasFutureMoveRes) {
          // api Type Enum = MOVE_OUT:'O', MOVE_IN: 'I', MOVE_IN_OUT:'X';
          var moveOutAlreadyExists = (hasFutureMoveRes.exists && (hasFutureMoveRes.move_in_out === 'O' || hasFutureMoveRes.move_in_out === 'X'));
          if (moveOutAlreadyExists && !vm.data.credentials.future_move_token.length) {
            // notification: has a future move, contact customer service
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12107'});
            return;
          }
          var bIsInFuture = vm.data.bIsInFuture;
          if (!bIsInFuture || vm.data.credentials.future_move_token) { $state.go('premisesUnregisterMeterReading'); }
          if (bIsInFuture) {
            if (vm.data.email.correspondence_exists) {
              $state.go('premisesUnregisterSummary');
            } else {  // future unregister - no correspondence email address known, which is needed.
              $state.go('premisesUnregisterFutureCorrespondenceEmail');
            }
          }
        })
          .catch(function () {
            // notification: failed to check if has future move data, contact customer service.
            $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12104'});
            return;
          });
      }
    }

    function cancel() {
      if (vm.data.credentials.future_move_token.length || !ivAuthenticate.isAuthenticated()) {
        $state.go('premisesUnregisterLogin', {fm: vm.data.credentials.future_move_token});
      } else if (storage('corporation')) {
        $state.go('dashboard');
      } else {
        $state.go('premisesUnregisterMoveOutDate');
      }
    }

    function setAddress() { // order matters
      if (vm.data.address && vm.data.address.premise_id !== null) { // if unregister address already in session
        for (var i = 0; i < vm.registrablePremises.length; i++) {
          if (vm.data.address.premise_id === vm.registrablePremises[i].premise_id) {
            return vm.registrablePremises[i];
          }
        }
      } else if (vm.data.futureMoveOut.exists) { // hasFutureMoveOut.exists
        var fmop = vm.data.futureMoveOut.premise;
        var futurMoveResAddress = fmop.house_no + (fmop.house_no_addition || '') + fmop.postal_code;
        futurMoveResAddress = futurMoveResAddress.split(' ').join('').toUpperCase();
        for (var j = 0; j < vm.registrablePremises.length; j++) {
          var jRegPremise = vm.registrablePremises[j];
          var jRegistrablePremiseAddress = jRegPremise.address.house_no + (jRegPremise.address.house_no_addition || '') + jRegPremise.address.postal_code;
          jRegistrablePremiseAddress = jRegistrablePremiseAddress.split(' ').join('').toUpperCase();
          if (jRegistrablePremiseAddress === futurMoveResAddress) {
            return vm.registrablePremises[j];
          }
        }
      } else if (vm.data.credentials.customer_number !== null) { // credentials for short term token exist
        var creds = vm.data.credentials;
        var credsAddress = creds.house_no + (creds.house_no_addition || '') + creds.postal_code;
        credsAddress = credsAddress.split(' ').join('').toUpperCase();
        for (var k = 0; k < vm.registrablePremises.length; k++) {
          var kRegPremise = vm.registrablePremises[k];
          var kRegistrablePremiseAddress = kRegPremise.address.house_no + (kRegPremise.address.house_no_addition || '') + kRegPremise.address.postal_code;
          kRegistrablePremiseAddress = kRegistrablePremiseAddress.split(' ').join('').toUpperCase();
          if (kRegistrablePremiseAddress === credsAddress) {
            return vm.registrablePremises[k];
          }
        }
      }
      /**
       * @NOTE disabled by request
       *
       * Issuelog #220 FO62
       * Aangemeld scenario : in het overzichtsscherm waar de klant een adres kan selecteren om af te melden (blok 1),
       * willen we dat de klant ALTIJD zelf een adres selecteert,
       * ook al is er maar 1 adres. Dus nooit een bolletje al aanvinken.
       */
      // else { // isAuthenticated && !futureMoveOut, take the first
      //   if (vm.registrablePremises.length === 1) {
      //     return vm.registrablePremises[0];
      //   }
      // }
    }

    function preSetInfoForInactiveAddressAndNav(info) {
      vm.data.afterNewRegister = true;
      vm.data.meter_reading.meter_reading = info.meter_reading;
      vm.data.meter_reading.last_meter_reading_date = info.meter_reading_datetime;
      vm.data.correspondence.city = info.address.city;
      vm.data.correspondence.postal_code = info.address.postal_code;
      vm.data.correspondence.po_box_postal_code = info.address.po_box_postal_code;
      vm.data.correspondence.po_box = info.address.po_box;
      vm.data.correspondence.street = info.address.street;
      vm.data.correspondence.house_no = info.address.house_no;
      vm.data.correspondence.house_no_addition = info.address.house_no_addition;
      vm.data.correspondence.confirmationEmail = info.correspondence_email;
      vm.data.email.correspondence = info.correspondence_email;
      vm.data.correspondence.type = vm.data.correspondence.po_box ? 'po_box' : 'address';
      premiseUnregisterService.storeData();

      // future unregister - no correspondence email address known, which is needed.
      if (vm.data.bIsInFuture && !vm.data.email.correspondence_exists) {
        $state.go('premisesUnregisterFutureCorrespondenceEmail');
      } else {
        $state.go('premisesUnregisterSummary');
      }
    }

    function activateCorporation() {
      var _currentAddress = storage('corporation_address');
      var currentAddress = {
        premise_id: _currentAddress.premise_id,
        contract_id: _currentAddress.contract_id,
        device: {
          id: _currentAddress.device_id,
          serial_number: _currentAddress.serial_number_id
        },
        address: {
          address_no: _currentAddress.address_number,
          postal_code: _currentAddress.postal_code,
          house_no: _currentAddress.house_number,
          house_no_addition: _currentAddress.house_number_addition,
          city: _currentAddress.city,
          street: _currentAddress.street
        }
      };
      vm.registrablePremises.push(currentAddress);
      vm.activePremises.push(currentAddress);
      vm.data.address = vm.registrablePremises[0];
    }

    function getInfo() {
      customerService.getInfo({
        contract_account_id: vm.data.address.contract_account_id,
        contract_id: vm.data.address.contract_id
      }).then(function (info) {
        vm.data.email.correspondence = info.correspondence_email;
        premiseUnregisterService.storeData();
      });
    }

    function setInfoForInactivePremises() {
      customerService.getInfo({
        contract_account_id: vm.data.address.contract_account_id,
        contract_id: vm.data.address.contract_id
      }).then(function (info) {
        vm.preSetInfoForInactiveAddressAndNav(info);
      });
    }

    function activateStandardCustomer(premises) {
      var _premises = angular.copy(premises);
      vm.unregistrablePremises = _premises.filter(function (item) {
        // can not unregister these (unregistrablePremises)
        // must call customer support
        return !item.is_subscription_option && item.is_active;
      });

      var credsAddress = null;
      // when logged in via short term - only show address you logged in with.
      if (!ivAuthenticate.isAuthenticated() && vm.data.credentials && vm.data.credentials.house_no && vm.data.credentials.postal_code) {
        var creds = vm.data.credentials;
        credsAddress = creds.house_no + (creds.house_no_addition || '') + creds.postal_code;
        credsAddress = credsAddress.split(' ').join('').toUpperCase();
      }
      // get all addresses with subscription option; address you can unregister
      vm.registrablePremises = _premises.filter(function (item) {
        if (credsAddress) {
          var itemAddress = item.address.house_no + (item.address.house_no_addition || '') + item.address.postal_code;
          itemAddress = itemAddress.split(' ').join('').toUpperCase();
          var isMatchingAddress = credsAddress === itemAddress;
          return item.is_subscription_option && isMatchingAddress;
        }
        return item.is_subscription_option;
      });
      // get all active addresses with subscription option.
      vm.activePremises = _premises.filter(function (item) {
        if (credsAddress) {
          var itemAddress = item.address.house_no + (item.address.house_no_addition || '') + item.address.postal_code;
          itemAddress = itemAddress.split(' ').join('').toUpperCase();
          var isMatchingAddress = credsAddress === itemAddress;
          return item.is_subscription_option && item.is_active && isMatchingAddress;
        }
        return item.is_subscription_option && item.is_active;
      });
      // get all inactive addresses with subscription option, not older than 3 months (#62)
      vm.premisesAlreadyUnregistered = _premises.filter(function (item) {
        if (credsAddress) { return false; }
        var isNotOlderThanThreeMonths = moment(item.move_out_date).add(3, 'months') > moment();
        return !item.is_active && isNotOlderThanThreeMonths;
      });

      // select address based on user auth, session, future move token
      vm.data.address = vm.setAddress();

      if (vm.data.address) {
        if (vm.data.credentials.customer_number === null && vm.data.address.is_active) {
          vm.getInfo();
        } else if (!vm.data.address.is_active) {
          // If not active, pre set info, go to summary (#62).
          // only for short login
          vm.setInfoForInactivePremises();
        }
      }
    }

    function getEmail() {
      customerService.getEmail().then(getEmailSuccess);
    }

    function getEmailSuccess(response) {
      vm.data.email.correspondence_exists = response.correspondence_email;
      premiseUnregisterService.storeData();
    }

    function activate() {

      if ($stateParams.clearCache === 'true') {
        premiseUnregisterService.clearData();
        premiseRegisterService.clearData();
        premisesService.clearProcesStatus();
      }

      if (angular.isUndefined(premisesService.getProcesStatus()) || premisesService.getProcesStatus() === null) {
        premisesService.storeProcesStatus('moving_out');
      }

      if (storage('corporation') && angular.isObject(storage('corporation_address'))) {
        vm.activateCorporation();
      } else {
        customerService.getPremises({getInactive: true}).then(function (premises) {
          vm.activateStandardCustomer(premises);
        });
      }
      vm.getEmail();
    }

    activate();
  }

  angular.module('wml.portal.premisesUnregister')
    .controller('PremisesUnregisterController', PremisesUnregisterController);

})();
