(function(){
  'use strict';

  function vacancyDetailService(){

    var _defaults = {
      gender              : '',
      name                : '',
      street              : '',
      house_no            : '',
      house_no_addition   : '',
      postal_code         : '',
      city                : '',
      country             : 'NL',
      email               : '',
      phone               : '',
      vacancy_found_at    : '',
      motivation_file     : '',
      resume_file         : ''
    };

    var _data;
    _data = angular.copy(_defaults);

    return {
      data: _data
    }
  }

  angular
    .module('wml.portal.detailPage')
    .factory('vacancyDetailService', vacancyDetailService);

})();
