(function() {
  'use strict';

  /**
   * @ngInject
   * Gets called when future move in and no correspondence e-mail is known.
   *
   * */
  function premisesRegisterFutureCorrespondenceEmailController($scope, $state, customerService, addressService, ivAuthenticate, premiseRegisterService, $rootScope) {

    var vm = this;

    // Public variables
    vm.form = null;
    vm.data = premiseRegisterService.data;
    vm.loading = false;

    // Public function definitions
    vm.next = next;
    vm.cancel = cancel;

    // Public functions

    function next() {
      if (vm.form.$valid) {
        vm.data.email.bSetNew = true;
        premiseRegisterService.storeData();
        $state.go('premisesRegisterSummary');
      }
    }

    function cancel() {
      $state.go('premisesRegister');
    }
  }

  angular.module('wml.portal.premisesRegister')
    .controller('premisesRegisterFutureCorrespondenceEmailController', premisesRegisterFutureCorrespondenceEmailController);

})();
