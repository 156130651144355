(function() {
  'use strict';

  /**
   * @ngInject
   * Gets called when future move in and no correspondence e-mail is known.
   *
   * */
  function premisesUnregisterFutureCorrespondenceEmailController($scope, $state, customerService, addressService, ivAuthenticate, premiseUnregisterService, $rootScope) {

    var vm = this;

    // Public variables
    vm.form = null;
    vm.data = premiseUnregisterService.data;
    vm.loading = false;

    // Public function definitions
    vm.next = next;
    vm.cancel = cancel;

    // Public functions
    function next() {
      if (vm.form.$valid) {
        vm.data.email.bSetNew = true;
        premiseUnregisterService.storeData();
        $state.go('premisesUnregisterSummary');
      }
    }

    function cancel() {
      $state.go('premisesUnregister');
    }

    activate();

    function activate() {
    }
  }

  angular.module('wml.portal.premisesUnregister')
    .controller('premisesUnregisterFutureCorrespondenceEmailController', premisesUnregisterFutureCorrespondenceEmailController);

})();
