(function() {
  'use strict';

  /** @ngInject */
  function premisesRegisterCorrespondenceAddressController($scope, $state, customerService, addressService, ivAuthenticate, premiseRegisterService, $rootScope) {

    var vm  = this;
    // private variables
    var _lastAutofill = null;
    var _lastAutofillPo = null;

    // Public variables
    vm.form = null;
    vm.premises = [];
    vm.bShowOther = false;
    vm.bShowPremisesSelection = true;
    vm.data = premiseRegisterService.data;
    vm.loading = false;

    // Public function definitions
    vm.autofillHouseNo = autofillHouseNo;
    vm.autofillPoBox = autofillPoBox;
    vm.showOther = showOther;
    vm.save = save;
    vm.changeCountry = changeCountry;
    vm.changeType = changeType;

    // Public functions
    function autofillHouseNo() {
      if(vm.data.correspondence.country !== 'NL'){
        return false;
      }

      if(!angular.isString(vm.data.correspondence.address_id) && angular.isString(vm.data.correspondence.postal_code) && angular.isString(vm.data.correspondence.house_no)) {
        vm.data.correspondence.postal_code = vm.data.correspondence.postal_code.toUpperCase();

        if(/^[0-9]{4}[A-Z]{2}$/.test(vm.data.correspondence.postal_code)) {

          var data = {
            postal_code : vm.data.correspondence.postal_code,
            house_no : vm.data.correspondence.house_no
          };

          var autofillKey = data.postal_code + '_' + data.house_no;

          if (angular.isString(vm.data.correspondence.house_no_addition)) {
            data.house_no_addition = vm.data.correspondence.house_no_addition;
            autofillKey = autofillKey + '_' + vm.data.correspondence.house_no_addition;
          }

          if(autofillKey === _lastAutofill) {
            return false;
          }

          vm.data.correspondence.city        = null;
          vm.data.correspondence.street      = null;
          vm.loading                         = true;

          _lastAutofill = autofillKey;

          autoFill(data);
        }
      }
    }

    function autofillPoBox() {
      if(vm.data.correspondence.country !== 'NL'){
        return false;
      }

      if(!angular.isString(vm.data.correspondence.address_id) && angular.isString(vm.data.correspondence.po_box_postal_code) && angular.isString(vm.data.correspondence.po_box)) {
        vm.data.correspondence.po_box_postal_code = vm.data.correspondence.po_box_postal_code.toUpperCase();

        if(/^[0-9]{4}[A-Z]{2}$/.test(vm.data.correspondence.po_box_postal_code)) {

          var data = {
            po_box_postal_code  : vm.data.correspondence.po_box_postal_code,
            po_box              : vm.data.correspondence.po_box
          };

          var autofillKey = data.po_box_postal_code + '_' + data.po_box;

          if(autofillKey === _lastAutofillPo) {
            return false;
          }

          vm.data.correspondence.city = null;
          vm.loading                    = true;

          _lastAutofillPo = autofillKey;

          autoFill(data);
        }
      }
    }

    function showOther(bShowOther){
      vm.bShowOther = bShowOther;
    }

    function save() {
      if (vm.form.$valid) {
        if (vm.data.correspondence.type === null || !vm.data.correspondence.type.length) {
          vm.data.correspondence.type = 'address';
        }

        premiseRegisterService.storeData();
        $state.go('premisesRegisterSummary');
      }
    }

    function changeCountry() {
      resetForm();
      if(vm.data.correspondence.country !== 'NL'){
        vm.data.correspondence.type = 'address';
      } else {
        vm.data.correspondence.type = '';
      }
    }

    function changeType() {
      resetForm();
    }

    // Private functions
    function resetForm() {
      _lastAutofillPo = null;
      _lastAutofill   = null;

      vm.data.correspondence.postal_code        = null;
      vm.data.correspondence.po_box_postal_code = null;
      vm.data.correspondence.po_box             = null;
      vm.data.correspondence.street             = null;
      vm.data.correspondence.house_no           = null;
      vm.data.correspondence.house_no_addition  = null;
      vm.data.correspondence.city               = null;
    }

    function getPremises() {
      customerService.getPremises().then(getPremiseSuccess);
    }

    function getPremiseSuccess(response) {
      vm.premises = response;

      if (vm.premises.length === 0) {
        vm.bShowPremisesSelection = false;
      }
    }

    function autoFill(data) {
      addressService.autofill(data).then(function(response) {
        var address = angular.copy(response.plain());
        vm.loading = false;
        vm.data.correspondence.street = address.street || '';
        vm.data.correspondence.house_no_addition = address.house_no_addition || '';
        vm.data.correspondence.city = address.city || '';
      });
    }

    activate();

    function activate() {
      getPremises();

      if (vm.data.correspondence.address_id === null) {
        vm.showOther(true);
      }

      $scope.$watch(function() {return vm.data.correspondence.address_id}, function(newValue) {
        if (angular.isDefined(newValue) && angular.isObject(newValue)) {
          vm.data.correspondence.country = newValue.country;
          vm.data.correspondence.city = newValue.city;
          vm.data.correspondence.postal_code = newValue.postal_code;
          vm.data.correspondence.street = newValue.street;
          vm.data.correspondence.house_no = newValue.house_no;
          vm.data.correspondence.house_no_addition = newValue.house_no_addition;
        }
      });
    }
  }

  angular.module('wml.portal.premisesRegister')
    .controller('premisesRegisterCorrespondenceAddressController', premisesRegisterCorrespondenceAddressController);

})();
