(function() {
  'use strict';

  /** @ngInject */
  function premiseRegisterService($rootScope, storage, RestangularExt) {
    var _premiseRegisterRoute = RestangularExt.all('/customer');

    var _defaults = {
      address: {
        premise_id: null,
        postal_code       : null,
        house_no          : null,
        house_no_addition : null,
        city              : null,
        street            : null,
        meter_number      : null,
        contract_id       : false,
        device_id         : false
      },
      currentCorrespondenceAddress: {
        country     : null,
        city        : null,
        postal_code : null,
        po_box_postal_code : null,
        po_box            : null,
        street            : null,
        house_no          : null,
        house_no_addition : null
      },
      correspondence: {
        address_id  : false,
        bSetNew     : false,
        type        : null,
        country     : null,
        city        : null,
        postal_code : null,
        po_box_postal_code : null,
        po_box            : null,
        street            : null,
        house_no          : null,
        house_no_addition : null
      },
      meter_reading:{
        date : null,
        deviation_code  : null,
        deviation_reason  : null,
        meter_reading: '',
        num1  : 0,
        num2  : 0,
        num3  : 0,
        num4  : 0,
        num5  : 0,
        file_upload: {
          file: null,
          name: null,
          type: null,
        },
      },
      forced_meter_reading: false,
      prospect: {
        exits: null,
        moveDate: null,
        premise: null
      },
      bIsInFuture: null,
      credentials: {
        postal_code: null,
        house_no: null,
        house_no_addition: null,
        future_move_token: ''
      },
      email: {
        correspondence: null,
        update_account_email: false,
        digital: false,
        bSetNew: false
      }
    };

    $rootScope.$on("clear-cache", function () {
      clearData();
    });

    var _registerData;

    if(storage('premises_register') !== null){
      _registerData = storage('premises_register');
    } else {
      _registerData = angular.copy(_defaults);
    }

    return {
      storeData : storeData,
      clearData : clearData,
      // isProspectCheck : isProspectCheck,
      data      : _registerData,
      register  : register
    };

    function storeData() {
      // do not save the file upload data to the cookie, base64 will become too big.
      var copy = angular.copy(_registerData);
      copy.fileUpload = null;
      // file object does not need to get saved in cookie
      if (copy.meter_reading && copy.meter_reading.file_upload) {
        copy.meter_reading.file_upload.file = null;   // base64 can get too big to save in Fcookie.
      }
      storage('premises_register', copy);
    }

    function clearData(){
      storage('premises_register', null);
      _resetField(_registerData);
      sessionStorage.removeItem('short_token');
    }

    function register() {
      return _premiseRegisterRoute.one(storage('customer_number')).one('premise', _registerData.address.premise_id).post('move-in', _registerData);
    }

    // function isProspectCheck(postalCode, houseNumber, houseNumberAddition) {
    //     //   return RestangularExt.one('prospect').customGET(null, {postal_code: postalCode, house_number: houseNumber, house_number_addition: houseNumberAddition});
    //     // }

    function _resetField(field, keyPath) {
      keyPath = keyPath || [];

      if(angular.isObject(field)) {
        for(var key in field) {
          if(field.hasOwnProperty(key)) {
            keyPath.push(key);
            if(angular.isObject(field[key]) && angular.isObject(_defaultForKey(keyPath))) {
              // keyPath.pop();
              _resetField(field[key], keyPath);
            } else {
              field[key]  = _defaultForKey(keyPath);
              keyPath.pop();
            }
          }
        }
        keyPath.pop();
      }
    }

    function _defaultForKey(keyPath) {
      var tmpValue  = angular.copy(_defaults);
      for(var i=0; i < keyPath.length; i++) {
        if(angular.isDefined(tmpValue[keyPath[i]])) {
          tmpValue  = tmpValue[keyPath[i]]
        } else {
          return null;
        }
      }
      return tmpValue;
    }
  }

  angular.module('wml.portal.premisesRegister')
    .factory('premiseRegisterService', premiseRegisterService);

})();
