(function () {
  'use strict';

  /** @ngInject */
  function HeaderSwitchController($rootScope, $uibModal, $scope, $window, storage, $state, ivAuthenticate) {
    var vm = this;
    // private variables

    // Public variables
    vm.isInsecureState = ivAuthenticate.isInsecureState($state.current.name);
    vm.isShortTermState = ivAuthenticate.isShortTermState($state.current.name);
    vm.bAuthenticated = false;
    vm.headerType = null;

    // Public function definitions

    activate();

    function activate() {
      // 1. check if homepage. need home header
      if ($state.current.name === 'home') {
        vm.headerType = 'homeHeader';
      } else {
        // 2. check if authenticated
        if (ivAuthenticate.isAuthenticated()) {
          // 3. need portal.header
          vm.headerType = 'portalHeader';
          vm.bAuthenticated = true;
        } else {
          // 3. need main.header
          vm.headerType = 'mainHeader';
          vm.bAuthenticated = false;
        }
      }
    }
  }

  angular
    .module('wml.portal.header')
    .controller('HeaderSwitchController', HeaderSwitchController);
})();
