(function(){
  'use strict';
  /** @ngInject */
  function notFoundController(error404Service){

    var vm  = this;
    vm.data = null;

    activate();

    function activate() {
      error404Service.getData().then(function(response){
        vm.data = response;
      });
    }
  }
  angular
    .module('wml.portal.404')
    .controller('notFoundController', notFoundController);
})();
