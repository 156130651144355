(function() {
  'use strict';

  function initials() {
    return {
      restrict  : 'A',
      require   : 'ngModel',
      link: function (scope, elm, attrs, ctrl) {
        function fromUser(text) {
          if (text) {
            var formatted = text.replace(/[^A-Za-z]/g, '').toUpperCase().split('').join('.');
            ctrl.$setViewValue(formatted);
            if (formatted) {
              ctrl.$setViewValue(formatted + '.');
            }
            ctrl.$render();
          }
        }
        scope.$watch(attrs.ngModel, fromUser);
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('initials', initials)
})();
