(function () {
  'use strict';

  /** @ngInject */
  function PaymentMethodController($q, $rootScope, $stateParams, $state, enumService, customerService, $scope) {

    var vm = this;

    // private variables
    var _payment_dates          = [];
    var _paymentFrequency       = [];

    var _currentFrequency       = null;

    // Public variables
    vm.form                   = null;
    vm.bPaymentMethodDisabled = false;
    vm.draft                  = {};
    vm.paymentFrequency       = [];
    vm.sendingData            = false;

    // Public function definitions
    vm.save               = save;
    vm.filterPaymentDays  = filterPaymentDays;
    vm.filterFrequencies  = filterFrequencies;

    // Public functions
    function save() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        vm.sendingData = true;
        customerService.savePaymentDetails($stateParams.contract_account_id, $stateParams.contract_id, vm.draft).then(function(){
          $rootScope.$broadcast('clear-cache');
          $state.go('invoicesDashboardSaved');
        }).finally(function(){
          vm.sendingData = false;
        });
      }
    }

    function filterPaymentDays() {
      //20170323 - mike@ivengi.com: When payment frequency is not monthly (01), filter available payment days. Show current payment day and option 14D which relates to 16th of the month
      vm.payment_dates = [];
      if(vm.draft.payment_frequency !== '01'){
        vm.payment_dates = _payment_dates.filter(function(item){
          return item.value === vm.draft.payment_date || item.value === '14D';
        });
      } else {
        vm.payment_dates = _payment_dates;
      }

    }

    // Private functions
    function getPaymentMethods() {
      return enumService.getPaymentMethods().then(function (data) {
        vm.paymentMethods = data;
      });
    }

    function getPaymentFrequency() {
      return enumService.getPaymentFrequency().then(function (data) {
        _paymentFrequency = data;
      });
    }

    function filterFrequencies() {
      vm.paymentFrequency = _paymentFrequency.filter(function(item){
        if(vm.draft.payment_method === 'automatic_collection'){
          return item.value === '01' || item.value === '03' || item.value === _currentFrequency;
        } else {
          return item.value === '03' || item.value === _currentFrequency;
        }
      });

      filterPaymentDays();
    }

    function getPaymentDates() {
      enumService.getPaymentDates().then(function (data) {
        _payment_dates = data;
      });
    }

    function getCurrentPaymentMethod(){
      customerService.getContractAccount($stateParams.contract_account_id).then(function(response) {

        var contract = null;
        for(var i=0; i < response.contracts.length; i++ ) {
          if(response.contracts[i].contract_id === $stateParams.contract_id) {
            contract  = response.contracts[i];
            break;
          }
        }

        _currentFrequency = contract.cycle || null;

        vm.draft = {
          payment_method    : response.incoming_payment_method_id,
          payment_frequency : _currentFrequency,
          payment_date      : response.payment_terms
        };

        if(angular.isDefined(response.incoming_bank_account) && response.incoming_bank_account !== null){
          vm.draft.iban = response.incoming_bank_account.iban;
        }

        filterFrequencies();

        if(vm.draft.payment_method === 'automatic_collection'){
          vm.bPaymentMethodDisabled = true;
        }
      });
    }



    activate();

    function activate() {
      $q.all([getPaymentFrequency(), getPaymentMethods(), getPaymentDates()]).then(function() {
        getCurrentPaymentMethod();
      });
    }
  }

  angular
    .module('wml.portal.paymentMethod')
    .controller('PaymentMethodController', PaymentMethodController);
})();
