(function() {
  'use strict';

  /** @ngInject */
  function DetailPageController($scope, $state, cmsService, $interval, $rootScope, detailService, newsService, Restangular, vcRecaptchaService, $timeout) {
    var vm  = this;
    vm.bottomBlock = {};

    // public variables
    vm.sendingData = false;
    vm.form        = null;
    vm.formData    = {};
    vm.photoSubNav = [];
    vm.conversionItems = [];
    vm.showSocial = true;

    // public functions
    vm.send   = send;
    vm.afterSend = afterSend;
    vm._getCmsData = _getCmsData;
    vm._getContent = _getContent;

    function _getCmsData() {
      cmsService.loadData().then(function (data) {
        vm.data = data;

        var showPhotoNav = parseInt(vm.data.Content.show_photo_nav.index, 10) === 1;
        if (showPhotoNav) {
          var photoSubnavPath;
          if (angular.isDefined(vm.data.Content.photo_subnav_path.data)) {
            var pathValue = vm.data.Content.photo_subnav_path.data.trim();
            if (pathValue) {
              photoSubnavPath = pathValue;
            }
          }

          cmsService.loadPhotoSubNav(photoSubnavPath).then(function (childNav) {
            var chunkSize = parseInt(vm.data.Content.photo_nav_columns.index, 10);
            for (var i = 0; i < childNav.length; i += chunkSize) {
              var chunk = childNav.slice(i, i + chunkSize);
              vm.photoSubNav.push(chunk);
            }
          });
        }

        cmsService.setSEO(data);

        $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);

        $timeout(function() {
          var topTapPhones = document.querySelectorAll('.toptap-phone');
          var topTapEmails = document.querySelectorAll('.toptap-email');
          var topTapPhone = (topTapPhones && topTapPhones.length > 0) ? topTapPhones[0] : null;
          var topTapEmail = (topTapEmails && topTapEmails.length > 0) ? topTapEmails[0] : null;

          if (topTapPhone) {
            topTapPhone.addEventListener('click', doLinkedInConversion);
          }

          if (topTapEmail) {
            topTapEmail.addEventListener('click', doLinkedInConversion);
          }
        }, 2000);
      });
    }

    function _getContent() {
      detailService.getBlockContent().then(function(response){
        vm.bottomBlock = response.plain();
      });
    }

    function afterSend() {
      doLinkedInConversion();
    }

    function doLinkedInConversion() {
      if (vm.data && vm.data.Content && vm.data.Content.moduleid && vm.data.Content.moduleid === 387) {
        var linkedInPixelConversion = '<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=993148&conversionId=865508&fmt=gif" />';
        if (vm.conversionItems.indexOf(linkedInPixelConversion) === -1) {
          $timeout(function() {
            vm.conversionItems.push(linkedInPixelConversion);
          }, 0);
        }
      }
    }

    function send(){
      if(vm.form.$valid) {
        vm.sendingData = true;
        newsService.addComment(vm.formData.name, vm.formData.email, vm.formData.message, vm.data.CurrentId)
          .then(function() {
            var d = new Date();

            vm.data.Responses.push({
              name  : {data: vm.formData.name},
              message: {data: vm.formData.message},
              time: {Date: d.getDate() + '-' + (d.getMonth()+1) + '-' + d.getFullYear()}
            });

            vm.form.$setPristine();
            vm.formData.name = null;
            vm.formData.message = null;
            vm.formData.email = null;

          }).finally(function() {
          vm.sendingData  = false;
        });
      }
    }

    function activate() {
      vm.showSocial = $state.current.url.indexOf('/over-wml/werken-bij-wml') !== -1 || $state.current.url.indexOf('/over-wml/nieuws') !== -1;

      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

      vm._getCmsData();
      vm._getContent();
    }

    activate();
  }

  angular
    .module('wml.portal.detailPage')
    .controller('DetailPageController', DetailPageController);
})();
