(function() {
  'use strict';

  /** @ngInject */
  function authenticationService(RestangularExt) {
    var _forgotPasswordRoute = RestangularExt.all('/authenticate/forgot-password');

    return {
      forgotPassword : forgotPassword
    };

    function forgotPassword(email) {
      return _forgotPasswordRoute.post({email: email});
    }
  }

  angular.module('wml.portal.authentication')
    .factory('authenticationService', authenticationService);

})();
