(function () {
  'use strict';

  /** @ngInject */
  function appointmentSummaryController($rootScope, $state, appointmentService, enumService, ivAuthenticate) {
    var vm = this;

    // private variables


    // Public variables
    vm.data         = {};
    vm.sendingData  = false;
    vm.summary      = {};
    vm.mode = '';

    // Public function definitions

    vm.save       = save;
    vm.cancel     = cancel;

    // Public functions

    function save() {
      if (vm.form.$valid) {
        vm.sendingData = true;
        if (vm.mode !== 'Delete') {
          appointmentService.saveAppointment(vm.summary).then(function () {
            $rootScope.$broadcast('clear-cache', {appointment : true});
            appointmentService.clearAppointment();
            $state.go('appointmentConfirmation'+vm.mode);
          }).finally(function () {
            vm.sendingData = false;
          });
        }
        if (vm.mode === 'Delete') {
          appointmentService.deleteAppointment(vm.summary).then(function () {
            $rootScope.$broadcast('clear-cache', {appointment : true});
            appointmentService.clearAppointment();
            $state.go('appointmentConfirmationDelete');
          }).finally(function () {
            vm.sendingData = false;
          });
        }
      }
    }

    function cancel() {
      appointmentService.clearAppointment();
      if (ivAuthenticate.isAuthenticated()) {
        $state.go('dashboard');
      } else {
        $state.go('appointmentFormCreate');
      }
    }

    // Private functions

    activate();

    function activate() {
      vm.mode = $state.current.name.split('appointmentSummary')[1];
      vm.summary = appointmentService.getAppointment();
      vm.isScheduled = vm.summary.is_scheduled;
      if (angular.isObject(vm.summary)) {
        var aSelectedMeter = vm.summary.device.future_qn.filter(function (item) {
          return item.value === vm.summary.meter_type;
        });
        vm.summary.meter_type = aSelectedMeter[0];

        if (vm.summary.appointment !== 'other') {
          if (vm.mode !== 'Delete' ) {
            appointmentService.getAppointmentSlots().then(function (response) {
              response = response.filter(function (item) {
                return item.start === vm.summary.appointment;
              });
              // todo error when slot not available
              vm.summary.appointment = response[0];
            });
          }
          if (vm.mode === 'Delete') {
            vm.summary.appointment = {
              start: vm.summary.start,
              end: vm.summary.end,
            };
          }
        }
        if(angular.isDefined(vm.summary.appointment_other)){
          enumService.getWeekdays().then(function (response) {
            response = response.filter(function (item) {
              return item.value === vm.summary.appointment_other.weekday;
            });
            vm.summary.appointment_other.weekday = response[0];
          });

          enumService.getDayparts().then(function (response) {
            response = response.filter(function (item) {
              return item.value == vm.summary.appointment_other.timeslot;
            });
            vm.summary.appointment_other.timeslot = response[0];
          });
        }

      }

      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    }
  }

  angular
    .module('wml.portal.appointment')
    .controller('appointmentSummaryController', appointmentSummaryController);
})();
