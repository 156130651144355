(function() {
  'use strict';

  /** @ngInject */
  function strReplace() {
    return function (text, find, replacement) {
      text = text || '';
      find = find || '';
      replacement = replacement || '';
      return text.replace(new RegExp(find, 'ig'), replacement);
    };
  }

  angular.module('wml.portal.generic')
    .filter('strReplace', strReplace);

})();
