(function () {
  'use strict';

  /** @ngInject */
  function PremisesUnregisterSummaryController($state, premiseUnregisterService, premisesService, customerService, $rootScope, ivAuthenticate, storage) {
    var vm = this;

    // private variables

    // Public variables
    vm.sendingData      = false;
    vm.form             = null;
    vm.data             = premiseUnregisterService.data;

    // Public function definitions
    vm.save                          = save;
    vm.cancel                        = cancel;

    // Public functions
    function save() {
      // check if vm.data.file_upload.name is set
      // if no more recollection of fileUpload, then file is gone due to refresh.
      if (vm.data && vm.data.meter_reading && vm.data.meter_reading.file_upload && vm.data.meter_reading.file_upload.name && !vm.data.meter_reading.file_upload.file) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 11013});
        vm.data.meter_reading.file_upload = null;
        premiseUnregisterService.storeData();
        return;
      }
      vm.sendingData = true;
      if(vm.data.correspondence.bSetNew === false) {
        customerService.getAddress().then(function (response) {
          var data = response.plain();
          var bSetAsNewEmail = vm.data.correspondence.bSetAsNewEmail;
          var confirmationEmail = vm.data.correspondence.confirmationEmail;
          vm.data.correspondence = data;
          vm.data.correspondence.bSetAsNewEmail = bSetAsNewEmail;
          vm.data.correspondence.confirmationEmail = confirmationEmail;
          vm.data.correspondence.country = response.country_id; // set country to two digit code
          vm.data.correspondence.type = 'address';

          if(data.po_box.length){
            vm.data.correspondence.type = 'po_box';
          }

          vm.data.correspondence.bSetNew = false;
          premiseUnregister();
        });
      } else {
        premiseUnregister();
      }
    }

    function premiseUnregister(){
      premiseUnregisterService.unregister().then(function(response) {
        if(vm.data.correspondence.bSetNew === true){
          customerService.clearAddressCache();
        }
        unregisterSuccess(response);
      }).catch(function (error) {
        if (error === null) { return; }

        var params = (angular.isDefined(error.fields)) ? error.fields : {};

        if (angular.isDefined(error.code)) {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: error.code, params: params});
        } else {
          // Notification, no mail address available to send confirmation (12006)
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: '12006'});
        }
      }).finally(function () {
        vm.sendingData = false;
      });
    }

    function unregisterSuccess(response) {
      if (vm.data.afterNewRegister) {
        // if already unregistered and nothing changed, nav to home
        return $state.go('home');
      } else if (angular.isObject(response) && angular.isDefined(response.code) && (response.code === 12004 || response.code === 12008)) {
        $rootScope.$broadcast('wml.portal.message', response);
        vm.data.forced_meter_reading = true;
        $state.go('premisesUnregisterMeterReading', {meterError: response.code});
      } else {
        if (storage('corporation') && angular.isObject(storage('corporation_address'))) {
          clearCache();
          // 20181217 - rob@ivengi.com
          //            Redirect to chooseAddress for corporation when successfully unregistering an address
          $state.go('chooseAddress', {refresh: true});
          // $state.go('premisesUnregisterThankYou');
        } else {
          if (premisesService.getProcesStatus() === 'moving_out') {
            if (ivAuthenticate.isAuthenticated() && !vm.data.bIsInFuture) {
              premisesService.askToContinueModal().then(function () {
                $state.go('premisesRegister');
              }, function () {
                $state.go('customer');
              }).finally(function () {
                clearCache();
              });
            } else {
              $state.go('premisesUnregisterThankYou');
            }
          } else {
            $state.go('premisesUnregisterThankYou');
          }
        }
      }
    }

    function cancel() {
      var bIsInFuture = vm.data.bIsInFuture;
      if (ivAuthenticate.isAuthenticated()) {
        if (bIsInFuture) {
          if (vm.data.email.bSetNew && vm.data.email.correspondence) {
            $state.go('premisesUnregisterFutureCorrespondenceEmail');
          } else {
            $state.go('premisesUnregister');
          }
        }
        if (!bIsInFuture) {
          if (vm.data.correspondence.bSetNew === true) {
            $state.go('premisesUnregisterCorrespondenceAddress');
          } else {
            $state.go('premisesUnregisterMeterReading');
          }
        }
      } else {
        if (vm.data.afterNewRegister) {
          $rootScope.$broadcast('clear-cache');
          $state.go('home');
        }
        else if (!bIsInFuture) { $state.go('premisesUnregisterEmail'); }
        else if (bIsInFuture) { $state.go('premisesUnregister'); }
      }
    }

    function clearCache() {
      premiseUnregisterService.clearData();
      $rootScope.$broadcast('clear-cache', {customer : true});
    }

    // Private functions
    activate();

    function activate() {

    }
  }

  angular.module('wml.portal.premisesUnregister')
    .controller('PremisesUnregisterSummaryController', PremisesUnregisterSummaryController);
})();
