(function() {
  'use strict';

  /** @ngInject */
  function DashboardController($state, $stateParams, $uibModal, $scope, appointmentService, customerService, meterReadingService, profileService, invoicesService, usageChartService, billingInformationService, contactPersonService, storage, corporationService, ivAuthenticate, $window) {
    var vm  = this;
    // private variables
    var _unplannedMSA  =  [];
    var _meterReadings =  [];

    // Public variables
    vm.bShowMSAUnplannedButton  = false;
    vm.bShowMSAplannedButton    = false;
    vm.plannedMSA               = [];
    vm.personal                 = {};
    vm.profile                  = {};
    vm.correspondenceAddress    = [];
    vm.bShowMeterReadingButton  = false;
    vm.accountBalance           = [];
    vm.connectionAddresses      = [];
    vm.multipleCustomerNumbers  = false;
    vm.draft                    = {};

    vm.chartExtraConfig             = usageChartService.chartExtraConfig;
    vm.chartConfig                  = usageChartService.getConfig;
    vm.chartConfig.yAxis.plotLines  = [];

    vm.filterParams = {
      page: 1,
      limit: 5
    };

    vm.corporationAddress = storage('corporation_address');

    // Public function definitions
    vm.openMSAModal             = openMSAModal;
    vm.openPlannedMSA           = openPlannedMSA;
    vm.deletePlannedMSA         = deletePlannedMSA;
    vm.openMeterReadingsModal   = openMeterReadingsModal;
    vm.changeChart              = changeChart;
    vm.openPDF                  = openPDF;

    // Public functions
    function changeChart(){
      usageChartService.getConsumption(vm.chartExtraConfig.selectedChartAddress);
    }

    function openMSAModal(){
      if(angular.isArray(_unplannedMSA) && _unplannedMSA.length > 1){
        vm.msaConnectionAddresses = _unplannedMSA;
        vm.msaModal = $uibModal.open({
          templateUrl: 'app/components/dashboard/partials/connection-addresses.modal.partial.html',
          scope: $scope,
          size: 'lg'
        });

        vm.msaModal.result.then(function(data){
          goToMSA(data)
        });
      } else {
        var data = {service_number:_unplannedMSA[0].notify_no, postal_code : _unplannedMSA[0].address.postal_code};
        goToMSA(data)
      }
    }

    function openPlannedMSA(data) {
      data.action = 'update';
      goToMSA(data);
    }

    function openMeterReadingsModal() {
      vm.meterReadings = _meterReadings;

      if(angular.isObject(vm.meterReadings) && vm.meterReadings.length > 1){
        $scope.modal  = vm;
        vm.meterReadingsModal = $uibModal.open({
          templateUrl   : 'app/components/usage/partials/meter-readings.modal.partial.html',
          scope         : $scope,
          size          : 'lg'
        });

        vm.meterReadingsModal.result.then(function(params){
          $state.go('meterReadingRecording', params)
        });
      } else {
        var params = {assignment_number: vm.meterReadings[0].assignment_number, postal_code: vm.meterReadings[0].device.address.postal_code};
        $state.go('meterReadingRecording', params)
      }
    }

    // Private functions
    function checkMSA(){
      appointmentService.getAppointments().then(getMSASuccess);
    }

    function getMSASuccess(response) {
      if(response.length > 0){
        // Only use Z6 type appointments for the green banner to schedule. These are AMV appointments.
        _unplannedMSA = response.filter(function(item){
          return item.is_scheduled === false && item.notify_type === 'Z6';
        });

        vm.plannedMSA = response.filter(function(item){
          return item.is_scheduled === true;
        });

        if(_unplannedMSA.length > 0){
          vm.bShowMSAUnplannedButton = true;
        } else {
          vm.bShowMSAUnplannedButton = false;
        }

        if(vm.plannedMSA.length > 0){
          vm.bShowMSAplannedButton = true;
        } else {
          vm.bShowMSAplannedButton = false;
        }
      }
    }

    function goToMSA(data) {
      if (appointmentService.clearAppointment()) {
        appointmentService.verifyAppointment(data).then(function () {
          if (data.action === 'update') {
            $state.go('appointmentEmailUpdate');
          } else {
            $state.go('appointmentEmailCreate');
          }
        });
      }
    }

    // unschedule: notifno, begtstmp endtstmp
    function deletePlannedMSA(data) {
      if (appointmentService.clearAppointment()) {
        appointmentService.verifyAppointment(data).then(function () {
          $state.go('appointmentEmailDelete');
        });
      }
    }

    function checkMeterReadings() {
      meterReadingService.getMeterReadings().then(getMeterReadingsSuccess);
    }

    function getMeterReadingsSuccess(response) {
      _meterReadings = response;
      if(angular.isArray(_meterReadings) && _meterReadings.length > 0){
        vm.bShowMeterReadingButton = true;
      }
    }

    function getCustomer(){
      customerService.getCustomer().then(getCustomerSuccess)
    }

    function getCustomers() {
      customerService.getCustomers().then(function(response) {
        if(response.length > 1) {
          vm.multipleCustomerNumbers  = true;
        }
      });
    }

    function getCustomerSuccess(response) {
      // Store the export url in this success handler to make sure a valid access token is fetched in the service.
      vm.downloadPremiseCsvUrl  = corporationService.getCsvExportUrl();

      vm.personal = response;
       profileService.getProfile().then(function (response) {
         vm.profile = response;
      });

      if(storage('corporation')){
        contactPersonService.getAdministrationContactPerson().then(function(data) {
          vm.billing_email  = data.email.correspondence;

          var updatedRecords  = contactPersonService.getUpdated();
          for(var key in updatedRecords) {
            if(updatedRecords[key].relation_type === 'administration') {
              vm.contactPersonData['administration']  = updatedRecords[key];
              if(angular.isString(updatedRecords[key].email)){
                vm.billing_email  = updatedRecords[key].email;
              }
              if(angular.isObject(updatedRecords[key].email) && angular.isString(updatedRecords[key].email.correspondence)){
                vm.billing_email  = updatedRecords[key].email.correspondence;
              }
            }
          }
        });
      }
    }

    function getAddresses() {
      customerService.getAddresses();
      customerService.getAddress().then(getAddressesSuccess);
    }

    function getAddressesSuccess(response) {
      vm.correspondenceAddress = response;
    }

    function getAccountBalance(){
      invoicesService.getAccountBalance().then(function(response){
        vm.accountBalance = response;
      });
    }

    function getPremises() {
      customerService.getPremises().then(getPremisesSuccess);
    }

    function getPremisesSuccess(response) {
      if(response.length <= 2){
        vm.connectionAddresses = response;
      }

      usageChartService.initChart(response);
    }

    function getSendControl() {
      billingInformationService.getSendControl().then(getSendControlSuccess);
    }

    function getSendControlSuccess(response) {
      vm.billing_information  = response;
    }

    function getInvoices() {
      var filterParams = angular.copy(vm.filterParams);
      filterParams.outstanding = 0;
      filterParams.document_types = ['PA'].join(',');
      filterParams.address_number = vm.corporationAddress.address_number;
      invoicesService.getInvoices(filterParams).then(function(response){
        vm.invoices = response;
      });
    }

    function getRemainingInvoices() {
      var filterParams = angular.copy(vm.filterParams);
      filterParams.outstanding = 1;
      filterParams.address_number = vm.corporationAddress.address_number;
      invoicesService.getInvoices(filterParams).then(function(response){
        vm.remainingInvoices = response;
      });
    }

    function openPDF(url) {
      var pdfWindow = $window.open('about:blank', '_blank');
      pdfWindow.location.href = url + '?access_token=' + ivAuthenticate.getAccessToken();
    }

    activate();

    function activate(){
      if(storage('corporation') && angular.isObject(storage('corporation_address'))) {
        vm.downloadPremiseCsvUrl  = corporationService.getCsvExportUrl();
        getCustomer();
        getCustomers();
        getSendControl();
        getInvoices();
        getRemainingInvoices();
      } else {
        getCustomer();
        getCustomers();
        checkMSA();
        checkMeterReadings();
        getAddresses();
        getAccountBalance();
        getPremises();
      }
    }
  }

  angular
    .module('wml.portal.dashboard')
    .controller('DashboardController', DashboardController);
})();
