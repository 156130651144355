(function() {
  'use strict';

  function checkEmail() {
    var EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    return {
      require   : 'ngModel',
      restrict  : 'A',
      scope     : {
        'email'    : '=ngModel'
      },
      link: function(scope, elm, attrs, ctrl) {
        scope.$watch('email', function() {
          if(angular.isDefined(scope.email) && angular.isString(scope.email) && scope.email.length){
            ctrl.$setValidity('checkEmail', EMAIL_REGEXP.test(scope.email));
          }
        });
      }
    };
  };

  angular.module('wml.portal.generic')
    .directive('checkEmail', checkEmail)
})();
