(function() {
  'use strict';

  /** @ngInject */
  function headerService(RestangularExt, $q, navigationService) {

    var _header = null;

    return {
      loadData  : loadData
    };

    function loadData() {
      if(_header === null) {
        var deferred  = $q.defer();

        RestangularExt.all('proxy').customGET(null, {url  : '/instellingen/headers'}).then(function(response) {
          for(var i = 0; i < response.links_left.length; i++){
            response.links_left = response.links_left.map(function(item) {
              item.name = navigationService.getStateByUrl(item.link);
              return item;
            });
          }

          for(var j = 0; j < response.links_right.length; j++){
            response.links_right = response.links_right.map(function(item) {
              item.name = navigationService.getStateByUrl(item.link);
              return item;
            });
          }

          deferred.resolve(response);
        });

        _header = deferred.promise;
      }

      return _header;
    }

  }

  angular.module('wml.portal.header')
    .factory('headerService', headerService);

})();
