(function() {
  'use strict';

  /** @ngInject */
  function AccountSelectController($rootScope, $http, RestangularExt, $state, storage, customerService, profileService) {
    var vm  = this;

    vm.options  = [];

    vm.select = select;
    vm.account_email;
    vm.filter;

    function activate() {
      customerService.getCustomers().then(function(data) {
        vm.options  = data;
      });

      profileService.getProfile().then(function(data) {
        if(data.email && data.email.account) {
          vm.account_email  = data.email.account;
        }
      });
    }

    function select(option) {
      RestangularExt.setDefaultHeaders(angular.extend(RestangularExt.configuration.defaultHeaders, {'X-Customer-Number': option.customer_number}));
      $http.defaults.headers.common['X-Customer-Number']  = option.customer_number;
      storage('customer_number', option.customer_number);
      $rootScope.$broadcast('clear-cache');

      if(storage('corporation') === true){
        $state.go('chooseAddress');
      } else {
        $state.go('dashboard');
      }
    }

    activate();
  }

  angular.module('wml.portal.account')
    .controller('AccountSelectController', AccountSelectController);

})();
