/* global _:false, moment:false */
(function() {
  'use strict';

  angular.module('wml.portal')
    .constant('_', _)
    .constant('moment', moment)
    .constant('HeaderSwitchRoute', {
      templateUrl     : 'app/components/header/views/header.switch.view.html',
      controller      : 'HeaderSwitchController',
      controllerAs    : 'header'
    })
    .constant('FooterRoute', {
      templateUrl     : 'app/components/footer/views/footer.view.html',
      controller      : 'FooterController',
      controllerAs    : 'footer'
    })
    .constant('MessageRoute', {
      templateUrl     : 'app/components/message/views/message.view.html',
      controller      : 'MessageController',
      controllerAs    : 'message'
    })
    .constant('MOVE_REASON', {
      MOVE_IN: '06',
      MOVE_OUT: '03'
    });
})();


