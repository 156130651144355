(function() {
  'use strict';

  /** @ngInject */
  function premiseUnregisterService($rootScope, storage, RestangularExt) {

    var _defaults = {
      address: {
        premise_id : null,
        postal_code : null,
        house_no          : null,
        house_no_addition : null,
        city              : null,
        street            : null
      },
      meter_reading:{
        date : null,
        last_meter_reading_date: null,
        deviation_code  : null,
        deviation_reason  : null,
        meter_reading: '',
        num1  : 0,
        num2  : 0,
        num3  : 0,
        num4  : 0,
        num5  : 0,
        file_upload: {
          file: null,
          name: null,
          type: null,
        },
      },
      correspondence: {
        bSetNew     : false,
        type        : null,
        country     : null,
        city        : null,
        postal_code : null,
        po_box_postal_code : null,
        po_box            : null,
        street            : null,
        house_no          : null,
        house_no_addition : null,
        confirmationEmail: null,
        bSetAsNewEmail: false
      },
      forced_meter_reading: false,
      futureMoveOut: {
        exits: null,
        moveDate: null,
        premise: null
      },
      bIsInFuture: null,
      credentials: {
        customer_number: null,
        postal_code: null,
        house_no: null,
        house_no_addition: null,
        future_move_token: '',
        grant_type: null
      },
      email: {
        correspondence_exists: false,
        correspondence: null,
        update_account_email: false,
        digital: false,
        bSetNew: false
      },
      afterNewRegister: false
    };

    $rootScope.$on("clear-cache", function () {
      clearData();
    });

    var _unregisterData;

    if (storage('premises_unregister') !== null) {
      _unregisterData = storage('premises_unregister');
    } else {
      _unregisterData = angular.copy(_defaults);
    }

    return {
      storeData   : storeData,
      clearData   : clearData,
      data        : _unregisterData,
      unregister  : unregister
    };

    function storeData() {
      // do not save the file upload data to the cookie, base64 will become too big.
      var copy = angular.copy(_unregisterData);
      copy.fileUpload = null;
      // file object does not need to get saved in cookie
      if (copy.meter_reading && copy.meter_reading.file_upload) {
        copy.meter_reading.file_upload.file = null;   // base64 can get too big to save in Fcookie.
      }
      storage('premises_unregister', copy);
    }

    function clearData(){
      _resetField(_unregisterData);
      storage('premises_unregister', null);
      sessionStorage.removeItem('short_token');
    }

    function unregister() {
      return RestangularExt.one('customer', storage('customer_number')).one('premise', _unregisterData.address.premise_id).post('move-out', _unregisterData);
    }

    function _resetField(field, keyPath) {
      keyPath = keyPath || [];

      if(angular.isObject(field)) {
        for(var key in field) {
          if(field.hasOwnProperty(key)) {
            keyPath.push(key);
            if(angular.isObject(field[key]) && angular.isObject(_defaultForKey(keyPath))) {
              _resetField(field[key], keyPath);
            } else {
              field[key]  = _defaultForKey(keyPath);
              keyPath.pop();
            }
          }
        }
        keyPath.pop();
      }
    }

    function _defaultForKey(keyPath) {
      var tmpValue  = angular.copy(_defaults);
      for(var i=0; i < keyPath.length; i++) {
        if(angular.isDefined(tmpValue[keyPath[i]])) {
          tmpValue  = tmpValue[keyPath[i]]
        } else {
          return null;
        }
      }
      return tmpValue;
    }

  }

  angular.module('wml.portal.premisesUnregister')
    .factory('premiseUnregisterService', premiseUnregisterService);

})();
