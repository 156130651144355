(function() {
  'use strict';

  /** @ngInject */
  function InvoicesDashboardController($window, $state, $rootScope, $stateParams, customerService, invoicesService, ivAuthenticate, $location) {
    var vm  = this;

    // private variables
    vm.showPaymentArrangementButton = false;

    // Public variables
    vm.maxBalanceForPaymentArrangement = 300;
    vm.currentContactPaymentMethod = null;
    vm.connectionAddresses = null;
    vm.postalCodeFilterParam = null;
    vm.filterParams = {
      page: 1,
      limit: 5
    };

    // Public function definitions
    vm.filterOnPostalCode = filterOnPostalCode;
    vm.openPDF            = openPDF;

    // Public functions
    function filterOnPostalCode(){
      customerService.getPremises({postal_code: vm.postalCodeFilterParam}).then(getPremisesSuccess);
    }

    function openPDF(url) {
      var pdfWindow = $window.open('about:blank', '_blank');
      pdfWindow.location.href = url + '?access_token=' + ivAuthenticate.getAccessToken();
    }

    // Private functions
    function getPremises() {
      customerService.getPremises().then(getPremisesSuccess);
    }

    function getPremisesSuccess(response) {
      vm.connectionAddresses = response;
      if (vm.connectionAddresses.length > 0 && vm.connectionAddresses[0].contract_account_id) {
        invoicesService.getInstallmentPlanChecks(vm.connectionAddresses[0].contract_account_id).then(function (data) {
          vm.showPaymentArrangementButton = data.installation_plan_allowed;
        });
      }
    }

    function getInvoices() {
      var filterParams = angular.copy(vm.filterParams);
      filterParams.outstanding = 0;
      filterParams.document_types = ['PA'].join(',');
      invoicesService.getInvoices(filterParams).then(function(response){
        vm.invoices = response;
      });
    }

    function getRemainingInvoices() {
      // when returning from new payment arrangement, refresh invoice list
      var queryParams = $location.search();

      var filterParams = angular.copy(vm.filterParams);
      filterParams.outstanding = 1;
      invoicesService.getInvoices(filterParams, queryParams.refresh).then(function(response){
        vm.remainingInvoices = response;
      });
    }

    function getCurrentPaymentMethod() {
      customerService.getContractAccount($stateParams.contract_account_id).then(function(response) {
        if (!angular.isArray(response) || response.length < 1) { return;}
        response = response.shift();
        vm.currentContactPaymentMethod = response.incoming_payment_method_id;
      });
    }

    function getAccountBalance() {
      invoicesService.getAccountBalance().then(function (response) {
        vm.currentAccountBalance = response;
      });
    }

    activate();

    function activate(){
      getPremises();
      getInvoices();
      getRemainingInvoices();
      getCurrentPaymentMethod();
      getAccountBalance();

      if(angular.isDefined($state.current.name) && $state.current.name == "invoicesDashboardSaved"){
        $rootScope.$broadcast('wml.portal.custom_message', {level: 'success', code:'1000'});
      }
    }
  }

  angular
    .module('wml.portal.invoices')
    .controller('InvoicesDashboardController', InvoicesDashboardController);
})();
