(function () {
  'use strict';

  function CarafeController(cmsService, $rootScope, detailService, carafeService) {
    // private variables
    var vm = this;

    // public variables
    vm.sendingData = false;
    vm.form = null;
    vm.formData = {
      company            : {
        kvk_number           : "",
        name                 : "",
        street               : "",
        house_number         : "",
        house_number_addition: "",
        postal_code          : "",
        city                 : ""
      },
      contact_person     : {
        name : "",
        phone: "",
        email: ""
      },
      order              : {
        type   : {
          introduction: false,
          subsequent: false
        },
        amount : 0,
        remarks: ""
      },
      conditions_accepted: false
    };

    vm.bShowForm = true;

    // Public function definitions
    vm.send = send;


    //public functions
    $rootScope.$broadcast('readspeaker.readclass', 'content');

    function send() {
      if (vm.form.$valid) {
        vm.sendingData = true;

        carafeService.send(vm.formData)
          .then(function () {
            vm.bShowForm = false;
          })
          .finally(function () {
            vm.sendingData = false;
          });
      }
    }

    // Private functions
    activate();

    function activate() {

      cmsService.loadData().then(function (data) {
        vm.data = data;

        cmsService.setSEO(data);

        $rootScope.$broadcast('wml.website.getBanners', data.Content.banners.ContentIndex);
      });

      detailService.getBlockContent().then(function (response) {
        vm.bottomBlock = response.plain();
      });

    }
  }

  angular
    .module('wml.portal')
    .controller('CarafeController', CarafeController)

})();
