(function () {
  'use strict';

  angular
    .module('wml.portal')
    .config(routerConfig);

  /** @ngInject */
  function routerConfig($stateProvider, $urlRouterProvider, HeaderSwitchRoute, FooterRoute, MessageRoute) {

    /** @ngInject */
    var translationLoadedFn = function($q, $translate, $interval) {
      var deferred  = $q.defer();

      var checkInterval = $interval(function() {
        if($translate.isReady()) {
          $interval.cancel(checkInterval);
          deferred.resolve();
        }
      }, 100);

      return deferred.promise;
    };

    $urlRouterProvider.otherwise('/cms');
    $stateProvider
      .state('routes', {
        url: '/routes',
        views: {
          content: {
            templateUrl: 'app/components/routes/views/routes.view.html',
            controller: 'RoutesController',
            controllerAs: 'routes'
          }
        }
      })
      .state('home', {
        url: '/home',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/home/views/home.view.html',
            controller: 'HomeController',
            controllerAs: 'home'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('listPage', {
        url: '/list-page',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/list-page/views/list-page.view.html',
            controller: 'ListPageController',
            controllerAs: 'listPage'
          },
          'sideMenu@listPage': {
            templateUrl     : 'app/components/side-menu/views/side-menu.view.html',
            controller      : 'SideMenuController',
            controllerAs    : 'sideMenu'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('detailPage', {
        url: '/detail-page',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/detail-page/views/detail-page.view.html',
            controller: 'detailPageController',
            controllerAs: 'detailPage'
          },
          'sideMenu@detailPage': {
            templateUrl     : 'app/components/side-menu/views/side-menu.view.html',
            controller      : 'SideMenuController',
            controllerAs    : 'sideMenu'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('cms', {
        url:  '/*cms',
        views:  {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content : {
            controller  : 'CmsController'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('pageNotFound', {
        url: '/404',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/404/views/404.view.html',
            controller: 'notFoundController',
            controllerAs: 'notFound'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('search', {
        url: '/zoeken',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/search/views/search.view.html',
            controller: 'SearchController',
            controllerAs: 'search'
          },
          footer: FooterRoute
        }
      })

      // /account
      .state('profile', {
        url: '/account',
        params: {message: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/profile/views/profile.view.html',
            controller: 'ProfileController',
            controllerAs: 'profile'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('profilePassword', {
        url: '/account/wachtwoord-wijzigen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/profile/views/profile.password.view.html',
            controller: 'ProfilePasswordController',
            controllerAs: 'password'
          },
          'faq@profilePassword': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('profileEmail', {
        url: '/account/email-wijzigen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/profile/views/profile.email.view.html',
            controller: 'ProfileEmailController',
            controllerAs: 'email'
          },
          'faq@profileEmail': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })

      // /mijn-verbruik
      .state('usageDashboard', {
        url: '/mijn-verbruik',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.dashboard.view.html',
            controller: 'UsageDashboardController',
            controllerAs: 'usage'
          },
          'faq@usageDashboard': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('usageMeterReadings', {
        url: '/mijn-verbruik/meterstand-doorgeven/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        resolve: {
          cancelLink : function(){ return 'usageDashboard'; },
          goToLink      : function(){ return 'usageCompare'; },
          translationsLoaded  : translationLoadedFn
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.meter-readings-usage-scan.view.html',
            controller: 'UsageMeterReadingsController',
            controllerAs: 'meterReadings'
          },
          'faq@usageMeterReadings': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        }
      })
      .state('usageCompare', {
        url: '/mijn-verbruik/meterstand-doorgeven/verbruik-vergelijken/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        resolve: {
          cancelLink            : function(){ return 'usageMeterReadings'; },
          goToLink              : function(){ return 'installmentChanged'; },
          bShowAdvisedCheckbox  : function(){ return true; },
          translationsLoaded    : translationLoadedFn
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.compare.view.html',
            controller: 'UsageCompareController',
            controllerAs: 'usage'
          },
          'faq@usageCompare': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        }
      })
      .state('installmentChanged', {
        url: '/mijn-verbruik/meterstand-doorgeven/verbruik-vergelijken/termijnbedrag-gewijzigd/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        resolve: {
          cancelLink: function(){ return 'usageDashboard'; },
          translationsLoaded  : translationLoadedFn
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.installment-amount-changed.view.html',
            controller: 'UsageInstallmentChangedController',
            controllerAs: 'usage'
          },
          'faq@installmentChanged': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        }
      })

      // /contact
      .state('contact', {
        url: '/contactformulier',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/contact/views/contact.view.html',
            controller: 'ContactController',
            controllerAs: 'contact'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('business_contact', {
        url: '/contact-zakelijk',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/contact/views/business.contact.view.html',
            controller: 'BusinessContactController',
            controllerAs: 'contact'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('contactThankYou', {
        url: '/contactformulier/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/contact/views/contact.thank-you.view.html',
            controller: 'ContactThankYouController',
            controllerAs: 'contact'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })

      // /login
      .state('login', {
        url: '/inloggen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/login/views/login.view.html',
            controller: 'LoginController',
            controllerAs: 'login'
          },
          'faq@login': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('loginActivate', {
        url: '/inloggen/activeren',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/login/views/login.view.html',
            controller: 'LoginController',
            controllerAs: 'login'
          },
          'faq@loginActivate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('passwordForgot', {
        url: '/wachtwoord-vergeten',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/authentication/views/authentication.forgot-password.view.html',
            controller: 'PasswordForgotController',
            controllerAs: 'password'
          },
          'faq@passwordForgot': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('passwordRestore', {
        url: '/wachtwoord-herstellen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/authentication/views/authentication.restore-password.view.html',
            controller: 'PasswordRestoreController',
            controllerAs: 'password'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })

      // /klant-worden, /registreren
      .state('register', {
        url: '/klant-worden',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.view.html',
            controller: 'RegisterController',
            controllerAs: 'register'
          },
          'faq@register': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerLogin', {
        url: '/klant-worden/login?fm',
        params: {clearCache: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.login.view.html',
            controller: 'RegisterLoginController',
            controllerAs: 'registerLogin'
          },
          'faq@registerLogin': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerMoveInDate', {
        url: '/klant-worden/move-in-date',
        params: {clearCache: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.move-in-date.view.html',
            controller: 'RegisterMoveInDateController',
            controllerAs: 'registerMoveInDate'
          },
          'faq@registerMoveInDate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve: {
          translationsLoaded: translationLoadedFn
        }
      })
      .state('registerCorrespondence', {
        url: '/klant-worden/correspondentie-gegevens',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.correspondence.view.html',
            controller: 'RegisterCorrespondenceController',
            controllerAs: 'registerCorrespondence'
          },
          'faq@registerCorrespondence': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerPersonalInfo', {
        url: '/klant-worden/persoonsgegevens',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.personal-info.view.html',
            controller: 'RegisterPersonalInfoController',
            controllerAs: 'registerPersonalInfo'
          },
          'faq@registerPersonalInfo': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerMeterReading', {
        url: '/klant-worden/meterstand',
        params: {meterError: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.meter-reading.view.html',
            controller: 'RegisterMeterReadingController',
            controllerAs: 'registerMeterReading'
          },
          'faq@registerMeterReading': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerPaymentMethod', {
        url: '/klant-worden/betaalwijze',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.payment-method.view.html',
            controller: 'RegisterPaymentMethodController',
            controllerAs: 'registerPaymentMethod'
          },
          'faq@registerPaymentMethod': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerPortal', {
        url: '/klant-worden/mijn-wml',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.portal.view.html',
            controller: 'RegisterPortalController',
            controllerAs: 'registerPortal'
          },
          'faq@registerPortal': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerSummary', {
        url: '/klant-worden/overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.summary.view.html',
            controller: 'RegisterSummaryController',
            controllerAs: 'registerSummary'
          },
          'faq@registerSummary': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('registerConfirmation', {
        url: '/klant-worden/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/register/views/register.confirmation.view.html',
            controller: 'RegisterConfirmationController',
            controllerAs: 'registerConfirmation'
          },
          'faq@registerConfirmation': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('sign-up', {
        url: '/registreren',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/sign-up/views/sign-up.view.html',
            controller: 'SignUpController',
            controllerAs: 'signUp'
          },
          'faq@sign-up': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('sign-upThankYou', {
        url: '/registreren/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/sign-up/views/sign-up.thank-you.view.html',
            controller: 'SignUpThankYouController',
            controllerAs: 'signUp'
          },
          'faq@sign-upThankYou': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('accountOptions', {
        url: '/klantnummers',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/account/views/accountselect.view.html',
            controller: 'AccountSelectController',
            controllerAs: 'account'
          },
          footer: FooterRoute
        }
      })
      .state('chooseAddress', {
        url: '/adres-kiezen?refresh',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/corporation/views/choose-address.corporation.view.html',
            controller: 'CorporationAddressController',
            controllerAs: 'corporation'
          },
          footer: FooterRoute
        }
      })

      // /dashboard
      .state('dashboard', {
        url: '/mijn-overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateProvider: ['$templateRequest', 'storage',
              function($templateRequest, storage)
              {
                if(storage('corporation') === true) {
                  return $templateRequest('app/components/dashboard/views/corporation.dashboard.view.html');
                } else {
                  return $templateRequest('app/components/dashboard/views/dashboard.view.html');
                }
              }
            ],
            controller: 'DashboardController',
            controllerAs: 'dashboard'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })

      // /mijn-financiele-gegevens
      .state('invoicesDashboard', {
        url: '/mijn-financiele-gegevens?refresh',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/invoices/views/invoices.dashboard.view.html',
            controller: 'InvoicesDashboardController',
            controllerAs: 'financial'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('invoicesDashboardSaved', {
        url: '/mijn-financiele-gegevens/opgeslagen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/invoices/views/invoices.dashboard.view.html',
            controller: 'InvoicesDashboardController',
            controllerAs: 'financial'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('invoices', {
        url: '/mijn-financiele-gegevens/facturen',
        data: {
          bShowOtherQuestionsBlock: false
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/invoices/views/invoices.view.html',
            controller: 'InvoicesController',
            controllerAs: 'invoice'
          },
          'faq@invoices': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('invoicesRemaining', {
        url: '/mijn-financiele-gegevens/openstaand',
        data: {
          bShowOtherQuestionsBlock: false
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/invoices/views/invoices.remaining.view.html',
            controller: 'InvoicesController',
            controllerAs: 'invoice'
          },
          'faq@invoicesRemaining': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('invoicesPaymentArrangement', {
        url: '/mijn-financiele-gegevens/betalingsregeling',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/invoices/views/invoices.payment-arrangement.view.html',
            controller: 'InvoicesPaymentArrangementController',
            controllerAs: 'invoice'
          },
          'faq@invoicesPaymentArrangement': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('paymentMethodEdit', {
        url: '/mijn-financiele-gegevens/betaalwijze-wijzigen/{contract_account_id}/contract/{contract_id}',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/payment-method/views/payment-method.view.html',
            controller: 'PaymentMethodController',
            controllerAs: 'payments'
          },
          'faq@paymentMethodEdit': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('installmentChange', {
        url: '/mijn-financiele-gegevens/termijnbedrag-wijzigen/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.installment-amount-change.view.html',
            controller: 'UsageInstallmentChangeController',
            controllerAs: 'usage'
          },
          'faq@installmentChange': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('installmentMeterReadings', {
        url: '/mijn-financiele-gegevens/termijnbedrag-wijzigen/meterstand-doorgeven/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        resolve: {
          cancelLink : function(){ return 'invoicesDashboard'; },
          goToLink      : function(){ return 'invoicesUsageCompare'; },
          translationsLoaded  : translationLoadedFn
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.meter-readings-usage-scan.view.html',
            controller: 'UsageMeterReadingsController',
            controllerAs: 'meterReadings'
          },
          'faq@installmentMeterReadings': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        }
      })
      .state('invoicesUsageCompare', {
        url: '/mijn-financiele-gegevens/termijnbedrag-wijzigen/meterstand-doorgeven/verbruik-vergelijken/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        resolve: {
          cancelLink            : function(){ return 'installmentMeterReadings'; },
          goToLink              : function(){ return 'invoicesInstallmentChanged'; },
          bShowAdvisedCheckbox  : function(){ return false; },
          translationsLoaded    : translationLoadedFn
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.compare.view.html',
            controller: 'UsageCompareController',
            controllerAs: 'usage'
          },
          'faq@invoicesUsageCompare': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        }
      })
      .state('invoicesInstallmentChanged', {
        url: '/mijn-financiele-gegevens/termijnbedrag-wijzigen/termijnbedrag-gewijzigd/{contract_account_id}/contract/{contract_id}/adres/{premise_id}',
        resolve: {
          cancelLink: function(){ return 'invoicesDashboard'; },
          translationsLoaded  : translationLoadedFn
        },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/usage/views/usage.installment-amount-changed.view.html',
            controller: 'UsageInstallmentChangedController',
            controllerAs: 'usage'
          },
          'faq@invoicesInstallmentChanged': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        }
      })

      // /afspraak/maken, /meter-verwisselen
      .state('msaServicenumberForm', {
        url: '/meter-verwisselen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.servicenumber-form.view.html',
            controller: 'appointmentFormController',
            controllerAs: 'service'
          },
          'faq@appointmentFormCreate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentFormCreate', {
        url: '/afspraak/maken',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.servicenumber-form.view.html',
            controller: 'appointmentFormController',
            controllerAs: 'service'
          },
          'faq@appointmentFormCreate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentEmailCreate', {
        url: '/afspraak/maken/email',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.email.view.html',
            controller: 'appointmentEmailController',
            controllerAs: 'service'
          },
          'faq@appointmentEmailCreate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentCalendarCreate', {
        url: '/afspraak/maken/calendar',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.calendar.view.html',
            controller: 'appointmentCalendarController',
            controllerAs: 'service'
          },
          'faq@appointmentCalendarCreate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentSummaryCreate', {
        url: '/afspraak/maken/overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.summary.view.html',
            controller: 'appointmentSummaryController',
            controllerAs: 'service'
          },
          'faq@appointmentSummaryCreate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentConfirmationCreate', {
        url: '/afspraak/maken/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.confirmation.view.html',
            controller: 'appointmentConfirmationController',
            controllerAs: 'service'
          },
          'faq@appointmentConfirmationCreate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentFormUpdate', {
        url: '/afspraak/wijzigen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.servicenumber-form.view.html',
            controller: 'appointmentFormController',
            controllerAs: 'service'
          },
          'faq@appointmentFormUpdate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentEmailUpdate', {
        url: '/afspraak/wijzigen/email',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.email.view.html',
            controller: 'appointmentEmailController',
            controllerAs: 'service'
          },
          'faq@appointmentEmailUpdate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentCalendarUpdate', {
        url: '/afspraak/wijzigen/calendar',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.calendar.view.html',
            controller: 'appointmentCalendarController',
            controllerAs: 'service'
          },
          'faq@appointmentCalendarUpdate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentSummaryUpdate', {
        url: '/afspraak/wijzigen/overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.summary.view.html',
            controller: 'appointmentSummaryController',
            controllerAs: 'service'
          },
          'faq@appointmentSummaryUpdate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentConfirmationUpdate', {
        url: '/afspraak/wijzigen/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.confirmation.view.html',
            controller: 'appointmentConfirmationController',
            controllerAs: 'service'
          },
          'faq@appointmentConfirmationUpdate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentFormDelete', {
        url: '/afspraak/annuleren',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.servicenumber-form.view.html',
            controller: 'appointmentFormController',
            controllerAs: 'service'
          },
          'faq@appointmentFormDelete': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentEmailDelete', {
        url: '/afspraak/annuleren/email',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.email.view.html',
            controller: 'appointmentEmailController',
            controllerAs: 'service'
          },
          'faq@appointmentEmailDelete': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentCalendarDelete', {
        url: '/afspraak/annuleren/calendar',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.calendar.view.html',
            controller: 'appointmentCalendarController',
            controllerAs: 'service'
          },
          'faq@appointmentCalendarDelete': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentSummaryDelete', {
        url: '/afspraak/annuleren/overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.summary.view.html',
            controller: 'appointmentSummaryController',
            controllerAs: 'service'
          },
          'faq@appointmentSummaryDelete': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('appointmentConfirmationDelete', {
        url: '/afspraak/annuleren/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/appointment/views/appointment.confirmation.view.html',
            controller: 'appointmentConfirmationController',
            controllerAs: 'service'
          },
          'faq@appointmentConfirmationDelete': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })

      // meterstand
      .state('meterReading', {
        url: '/meterstand-doorgeven/{assignment_number}',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.recording-number.view.html',
            controller: 'meterReadingRecordingNumberController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReading': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('meterReadingExtReading', {
        url: '/meterstand-doorgeven/stand/{meter_reading}',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.recording-number.view.html',
            controller: 'meterReadingRecordingNumberController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReadingExtReading': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('meterReadingRecordingExtReading', {
        url: '/meterstand-doorgeven/stand/{meter_reading}/{assignment_number}/opname/{postal_code}',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.view.html',
            controller: 'meterReadingController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReadingRecordingExtReading': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('meterReadingRecording', {
        url: '/meterstand-doorgeven/{assignment_number}/opname/{postal_code}',
        params: {meter_reading: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.view.html',
            controller: 'meterReadingController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReadingRecording': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('meterReadingEmailConfirmation', {
        url: '/meterstand-doorgeven/{assignment_number}/confirmation',
        params: {meter_reading: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.email-confirmation.view.html',
            controller: 'meterReadingEmailConfirmationController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReadingRecordingEmailConfirmation': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('meterReadingSummary', {
        url: '/meterstand-doorgeven/{assignment_number}/summary',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.summary.view.html',
            controller: 'meterReadingSummaryController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReadingSummary': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('meterReadingThankYou', {
        url: '/meterstand-doorgeven/{assignment_number}/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/meter-readings/views/meter-reading.thank-you.view.html',
            controller: 'meterReadingThankYouController',
            controllerAs: 'meterReadings'
          },
          'faq@meterReadingThankYou': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })

      // /mijn-persoonlijke-gegevens
      .state('customer', {
        url: '/mijn-persoonlijke-gegevens',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/customer/views/customer.dashboard.view.html',
            controller: 'CustomerDashboardController',
            controllerAs: 'personal'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('customerEdit', {
        url: '/mijn-persoonlijke-gegevens/wijzigen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/customer/views/customer.edit.view.html',
            controller: 'CustomerEditController',
            controllerAs: 'customer'
          },
          'faq@customerEdit': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('customerSaved', {
        url: '/mijn-persoonlijke-gegevens/opgeslagen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/customer/views/customer.dashboard.view.html',
            controller: 'CustomerDashboardController',
            controllerAs: 'personal'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('contactPersonEdit', {
        url: '/contactpersoon/{type}/bewerken/{account_id}',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/contactperson/views/contactperson.form.view.html',
            controller: 'ContactPersonFormController',
            controllerAs: 'contactperson'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded : translationLoadedFn
        }
      })
      .state('contactPersonAdd', {
        url: '/contactpersoon/{type}/toevoegen',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/contactperson/views/contactperson.form.view.html',
            controller: 'ContactPersonFormController',
            controllerAs: 'contactperson'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded : translationLoadedFn
        }
      })
      .state('correspondenceAddress', {
        url : '/mijn-correspondentie-gegevens/adres',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/correspondence/views/correspondence.edit.view.html',
            controller: 'CorrespondenceAddressEditController',
            controllerAs: 'correspondence'
          },
          'faq@correspondenceAddress': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('billingInformation', {
        url:  '/factuurgegevens',
        views:  {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/billing-information/views/billing-information.view.html',
            controller: 'BillingInformationController',
            controllerAs: 'billinginformation'
          },
          'faq@billingInformation': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          }
        }
      })

      // persoonlijke gegevens woning
      .state('premisesActionOverview', {
        url:  '/mijn-persoonlijke-gegevens/verhuizen',
        views:  {
          header  : HeaderSwitchRoute,
          message : MessageRoute,
          content : {
            templateUrl   : 'app/components/premises/views/premises-action-overview.view.html',
            controller    : 'PremisesActionOverviewController',
            controllerAs  : 'premises'
          },
          'faq@premisesActionOverview' : {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterMoveInDate', {
        url: '/mijn-persoonlijke-gegevens/move-in-date',
        params: {clearCache: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.move-in-date.view.html',
            controller: 'PremisesRegisterMoveInDateController',
            controllerAs: 'premisesRegisterMoveInDate'
          },
          'faq@premisesRegisterMoveInDate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve: {
          translationsLoaded: translationLoadedFn
        }
      })
      .state('premisesRegisterLogin', {
        url: '/woning-aanmelden?fm',
        params : { clearCache: null },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.login.view.html',
            controller: 'PremisesRegisterLoginController',
            controllerAs: 'premisesRegisterLogin'
          },
          'faq@premisesRegisterLogin': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegister', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/',
        params : { clearCache: null },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.view.html',
            controller: 'premisesRegisterController',
            controllerAs: 'premises'
          },
          'faq@premisesRegister': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterFutureCorrespondenceEmail', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/e-mail',
        params : { clearCache: null },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.future.correpondence.email.view.html',
            controller: 'premisesRegisterFutureCorrespondenceEmailController',
            controllerAs: 'premises'
          },
          'faq@premisesRegister': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterMeterReading', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/meterstand',
        params: {meterError: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.meter-reading.view.html',
            controller: 'premisesRegisterMeterReadingController',
            controllerAs: 'premises'
          },
          'faq@premisesRegisterMeterReading': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterNewPremises', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/correspondentie',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.new-premises.view.html',
            controller: 'premisesRegisterNewPremisesController',
            controllerAs: 'premises'
          },
          'faq@premisesRegisterNewPremises': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterCorrespondenceAddress', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/correspondentie-gegevens',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.correspondence.view.html',
            controller: 'premisesRegisterCorrespondenceAddressController',
            controllerAs: 'premises'
          },
          'faq@premisesRegisterCorrespondenceAddress': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterSummary', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.summary.view.html',
            controller: 'premisesRegisterSummaryController',
            controllerAs: 'premises'
          },
          'faq@premisesRegisterSummary': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesRegisterThankYou', {
        url: '/mijn-persoonlijke-gegevens/woning-aanmelden/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-register/views/premises-register.thank-you.view.html',
            controller: 'PremisesRegisterThankYouController',
            controllerAs: 'premises'
          },
          'faq@premisesRegisterThankYou': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesUnregisterLogin', {
        url: '/afmelden-woning?fm',
        params: {clearCache: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.login.view.html',
            controller: 'PremisesUnregisterLoginController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterLogin': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve: {
          translationsLoaded: translationLoadedFn
        }
      })
      .state('premisesUnregisterMoveOutDate', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/move-out-date',
        params: {clearCache: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.move-out-date.view.html',
            controller: 'PremisesUnregisterMoveOutDateController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterMoveOutDate': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve: {
          translationsLoaded: translationLoadedFn
        }
      })
      .state('premisesUnregisterEmail', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/email',
        params: {clearCache: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.email.view.html',
            controller: 'PremisesUnregisterEmailController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterEmail': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve: {
          translationsLoaded: translationLoadedFn
        }
      })
      .state('premisesUnregister', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden',
        params : { clearCache: null },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.view.html',
            controller: 'PremisesUnregisterController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregister': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesUnregisterFutureCorrespondenceEmail', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/e-mail',
        params : { clearCache: null },
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.future.correpondence.email.view.html',
            controller: 'premisesUnregisterFutureCorrespondenceEmailController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregister': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesUnregisterMeterReading', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/meterstand',
        params: {meterError: null},
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.meter-reading.view.html',
            controller: 'PremisesUnregisterMeterReadingController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterMeterReading': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesUnregisterCorrespondenceAddress', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/correspondentie-gegevens',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.correspondence.view.html',
            controller: 'PremisesUnregisterCorrespondenceAddressController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterCorrespondenceAddress': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesUnregisterSummary', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/overzicht',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.summary.view.html',
            controller: 'PremisesUnregisterSummaryController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterSummary': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
      .state('premisesUnregisterThankYou', {
        url: '/mijn-persoonlijke-gegevens/woning-afmelden/bedankt',
        views: {
          header: HeaderSwitchRoute,
          message: MessageRoute,
          content: {
            templateUrl: 'app/components/premises-unregister/views/premises-unregister.thank-you.view.html',
            controller: 'PremisesUnregisterThankYouController',
            controllerAs: 'premises'
          },
          'faq@premisesUnregisterThankYou': {
            templateUrl     : 'app/components/faq/views/faq.view.html',
            controller      : 'FaqController',
            controllerAs    : 'faq'
          },
          footer: FooterRoute
        },
        resolve : {
          translationsLoaded  : translationLoadedFn
        }
      })
  }
})();
