(function() {
  'use strict';

  /** @ngInject */
  function profileEmailController($rootScope, $state, profileService) {

    var vm = this;

    // Public variables
    vm.form         = null;
    vm.sendingData  = false;

    // Public function definitions
    vm.save = save;

    // Public functions
    function save() {
      vm.form.$commitViewValue();
      if(vm.form.$valid) {
        vm.sendingData = true;
        profileService.changeEmail(vm.form).then(function (response) {
          if (vm.form.update_correspondence_email) {
            $rootScope.$broadcast('clear-cache', {customer: {customer: true}});
          }
          vm.form.email              = '';
          vm.form.email_confirmation = '';
          vm.form.update_correspondence_email = false;
          vm.form.$setPristine();
          $state.go('profile', {message: response});
          vm.sendingData = false;
        });
      }
    }
  }

  angular
    .module('wml.portal.profile')
    .controller('ProfileEmailController', profileEmailController);
})();
