(function() {
  'use strict';

  /** @ngInject */
  function BannersController(bannerService, $rootScope) {
    var vm  = this;

    // public variables
    vm.banners = null;

    $rootScope.$on('wml.website.getBanners', function(evt, data){
      bannerService.loadData(data).then(function(response){
        vm.banners = response;
      });
    });
  }

  angular
    .module('wml.portal.banners')
    .controller('BannersController', BannersController);
})();
