(function() {
  'use strict';

  /** @ngInject */
  function RegisterCorrespondenceController($state, addressService, registerService) {
    var vm  = this;

    var _lastAutofill     = null;
    var _lastAutofillPo   = null;

    // public variables
    vm.data = registerService.data;

    if(vm.data.correspondenceAddress.country === null){
      vm.data.correspondenceAddress.country        = 'NL';
    }
    if(vm.data.correspondenceAddress.type === null) {
      vm.data.correspondenceAddress.type           = 'address';
    }

    vm.loading             = false;

    // public function defenitions
    vm.save               = save;
    vm.previousState      = previousState;
    vm.autofillHouseNo    = autofillHouseNo;
    vm.autofillPoBox      = autofillPoBox;
    vm.changeCountry      = changeCountry;
    vm.changeType         = changeType;

    // public functions
    function save() {
      if (vm.form.$valid) {
        registerService.storeData();
        $state.go('registerPersonalInfo');
      }
    }

    function previousState() {
      $state.go('register');
    }

    function autofillHouseNo() {
      if(vm.data.correspondenceAddress.country !== 'NL'){
        return false;
      }

      if(angular.isString(vm.data.correspondenceAddress.postal_code) && angular.isString(vm.data.correspondenceAddress.house_no)) {
        vm.data.correspondenceAddress.postal_code = vm.data.correspondenceAddress.postal_code.toUpperCase();

        if(/^[0-9]{4}[A-Z]{2}$/.test(vm.data.correspondenceAddress.postal_code)) {

          var data = {
            postal_code : vm.data.correspondenceAddress.postal_code,
            house_no    : vm.data.correspondenceAddress.house_no
          };

          var autofillKey = data.postal_code + '_' + data.house_no;

          if(angular.isString(vm.data.correspondenceAddress.house_no_addition) && vm.data.correspondenceAddress.house_no_addition.length > 0) {
            data.house_no_addition  = vm.data.correspondenceAddress.house_no_addition;
            autofillKey = autofillKey + '_' + vm.data.correspondenceAddress.house_no_addition;
          }

          if(autofillKey === _lastAutofill) {
            return false;
          }

          vm.data.correspondenceAddress.city        = null;
          vm.data.correspondenceAddress.street      = null;
          vm.loading          = true;

          _lastAutofill = autofillKey;

          autoFill(data);
        }
      }
    }

    function autofillPoBox() {
      if(vm.data.correspondenceAddress.country !== 'NL'){
        return false;
      }

      if(angular.isString(vm.data.correspondenceAddress.po_box_postal_code) && angular.isString(vm.data.correspondenceAddress.po_box)) {
        vm.data.correspondenceAddress.po_box_postal_code = vm.data.correspondenceAddress.po_box_postal_code.toUpperCase();

        if(/^[0-9]{4}[A-Z]{2}$/.test(vm.data.correspondenceAddress.po_box_postal_code)) {
          var data = {
            po_box_postal_code  : vm.data.correspondenceAddress.po_box_postal_code,
            po_box              : vm.data.correspondenceAddress.po_box
          };

          var autofillKey = data.po_box_postal_code + '_' + data.po_box;

          if(autofillKey === _lastAutofillPo) {
            return false;
          }

          vm.data.correspondenceAddress.city = null;
          vm.loading     = true;

          _lastAutofillPo = autofillKey;

          autoFill(data);
        }
      }
    }

    function changeCountry() {
      resetForm();

      if(vm.data.correspondenceAddress.country !== 'NL'){
        vm.data.correspondenceAddress.type = 'address';
      } else {
        vm.data.correspondenceAddress.type = '';
      }
    }

    function changeType(){
      resetForm();
    }

    //private functions
    function autoFill(data) {
      addressService.autofill(data).then(function(response) {
        var address = angular.copy(response.plain());
        vm.loading = false;

        if(address.street){
          vm.data.correspondenceAddress.street = address.street;
        }
        if(address.house_no_addition){
          vm.data.correspondenceAddress.house_no_addition = address.house_no_addition;
        }
        if(address.city){
          vm.data.correspondenceAddress.city = address.city;
        }
      });
    }

    function resetForm() {
      _lastAutofillPo = null;
      _lastAutofill   = null;

      vm.data.correspondenceAddress.postal_code         = null;
      vm.data.correspondenceAddress.po_box_postal_code  = null;
      vm.data.correspondenceAddress.po_box              = null;
      vm.data.correspondenceAddress.street              = null;
      vm.data.correspondenceAddress.house_no            = null;
      vm.data.correspondenceAddress.house_no_addition   = null;
      vm.data.correspondenceAddress.city                = null;
    }

  }

  angular
    .module('wml.portal.register')
    .controller('RegisterCorrespondenceController', RegisterCorrespondenceController);
})();
