(function() {
  'use strict';

  /** @ngInject */
  function HomeController(navigationService, incidentService, homeService, cmsService, $rootScope, $window) {
    var vm  = this;
    vm.newsItems = {};
    vm.newsItemsLength = 0;

    // methods
    vm.showIncidents = showIncidents;
    vm.openWebsite = openWebsite;

    navigationService.load();

    incidentService.load().then(function(response) {
      vm.incidents  = response;
    });

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    homeService.getData().then(function(response){
      var aNewsItems = [];
      for (var prop in response.news.ContentItems) {
        if (response.news.ContentItems.hasOwnProperty(prop)) {
          aNewsItems.push(response.news.ContentItems[prop])
        }
      }

      vm.newsItemsLength = aNewsItems.length;

      if(vm.newsItemsLength){
        aNewsItems.sort(function(a,b){
          return new Date(b.time.UnixTime) - new Date(a.time.UnixTime);
        });
        vm.newsItems = aNewsItems;
      }

      cmsService.setSEO(response);
    });

    function showIncidents() {
      $window.open('https://www.waterstoring.nl/wml', '_blank');
    }

    function openWebsite() {
      $window.open('https://www.wml.nl/thuis/veelgestelde-vragen/storing', '_blank');
    }

  }

  angular
    .module('wml.portal.home')
    .controller('HomeController', HomeController);
})();
