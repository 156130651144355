(function () {
  'use strict';

  angular
    .module('wml.portal', [
      'ngCookies',
      'ngSanitize',
      'restangular',
      'ui.router',
      'pascalprecht.translate',
      'ui.bootstrap',
      'ui.mask',
      'tmh.dynamicLocale',
      'iv.authentication',
      'iv.iform',
      'mm.iban',
      // 'ngAria',
      '720kb.socialshare',
      'ngAnimate',
      'duScroll',
      'ngFileUpload',
      'videosharing-embed',
      'highcharts-ng',
      'vcRecaptcha',
      'wml.portal.app-info',
      'wml.portal.authentication',
      'wml.portal.calendars',
      'wml.portal.registry',
      'wml.portal.storage',
      'wml.portal.header',
      'wml.portal.footer',
      'wml.portal.home',
      'wml.portal.listPage',
      'wml.portal.detailPage',
      'wml.portal.sideMenu',
      'wml.portal.contact',
      'wml.portal.login',
      'wml.portal.register',
      'wml.portal.signup',
      'wml.portal.customer',
      'wml.portal.profile',
      'wml.portal.message',
      'wml.portal.enum',
      'wml.portal.generic',
      'wml.portal.invoices',
      'wml.portal.faq',
      'wml.portal.usage',
      'wml.portal.paymentMethod',
      'wml.portal.correspondence',
      'wml.portal.address',
      'wml.portal.dashboard',
      'wml.portal.appointment',
      'wml.portal.meterReading',
      'wml.portal.restangular',
      'wml.portal.restBackend',
      'wml.portal.cms',
      'wml.portal.navigation',
      'wml.portal.premises',
      'wml.portal.premisesRegister',
      'wml.portal.premisesUnregister',
      'wml.portal.banners',
      'wml.portal.incidents',
      'wml.portal.contactperson',
      'wml.portal.breadcrumbs',
      'wml.portal.404',
      'wml.portal.standpipe',
      'wml.portal.vacancy',
      'wml.portal.videoList',
      'wml.portal.waterhardheid',
      'wml.portal.damage-form',
      'wml.portal.billingInformation',
      'wml.portal.account',
      'wml.portal.search',
      // 'wml.portal.routes',
      'wml.portal.news',
      'wml.portal.corporation',
      'wml.portal.carafe',
      'wml.portal.water-estafette'
    ]);
})();
