(function() {
  'use strict';

  /** @ngInject */
  function RegisterPortalController($state, registerService, $rootScope) {
    var vm  = this;

    // public variables
    vm.data           = registerService.data;

    // public functions
    vm.sendingData = false;
    vm.save          = save;
    vm.cancel = cancel;
    vm.skipStep      = skipStep;

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    // function prospectToUser() {
    //   var bIsInFuture = vm.data.bIsInFuture;
    //   if (!bIsInFuture && vm.data.credentials.future_move_token) {
    //     vm.sendingData = true;
    //     registerService.prospectToUser().then(function (data) {
    //       if (angular.isDefined(data.type) && data.type === 'message' && data.level !== 'success') {
    //         if (angular.isDefined(data.level) && (data.level === 'warning' || data.level === 'info')) {
    //           $rootScope.$broadcast('wml.portal.message', data);
    //         }
    //       } else {
    //         $state.go('registerSummary');
    //       }
    //     }).catch(function () {
    //       // Notification: register api call failed contact customer support
    //       $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12106});
    //     }).finally(function () {
    //       vm.sendingData = false;
    //     });
    //   }
    //   $state.go('registerSummary');
    // }

    // save function
    function save() {
      vm.data.portalAccount.isSet = true;
      registerService.storeData();
      // prospectToUser();
    }

    function cancel() {
      $state.go('registerPaymentMethod');
    }

    function skipStep(){
      vm.data.portalAccount.email                 = null;
      vm.data.portalAccount.email_confirmation    = null;
      vm.data.portalAccount.password              = null;
      vm.data.portalAccount.password_confirmation = null;
      vm.data.portalAccount.isSet                 = false;
      registerService.storeData();
      // prospectToUser();
    }
  }

  angular
    .module('wml.portal.register')
    .controller('RegisterPortalController', RegisterPortalController);
})();
