(function() {
  'use strict';

  /** @ngInject */
  function profileService(RestangularExt, storage) {
    var _profileRoute         = RestangularExt.all('/profile');
    var _changePasswordRoute  = RestangularExt.all('/profile/password');
    var _changeEmailRoute     = RestangularExt.all('/profile/email');

    return {
      getProfile:     getProfile,
      changePassword: changePassword,
      changeEmail:    changeEmail
    };

    function getProfile() {
      return _profileRoute.customGET();
    }

    function changePassword(data) {
      var obj = {
        current_password: data.current_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      };

      if(storage('activation_query')){
        obj = angular.extend(obj, storage('activation_query'));
      }

      return _changePasswordRoute.customPUT(obj);
    }

    function changeEmail(data) {
      var obj = {
        email: data.email,
        email_confirmation: data.email_confirmation,
        email_update_correspondence: data.update_correspondence_email || false,
      };

      return _changeEmailRoute.customPUT(obj);
    }
  }

  angular.module('wml.portal.profile')
    .factory('profileService', profileService);

})();
