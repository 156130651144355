(function() {
  'use strict';

  /** @ngInject */
  function VacancyListController(cmsService) {
    var vm  = this;

    cmsService.loadData().then(function(data) {
      vm.data = data.plain();
      cmsService.setSEO(data);
    });
  }

  angular
    .module('wml.portal.vacancy')
    .controller('VacancyListController', VacancyListController);
})();
