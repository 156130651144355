(function() {
  'use strict';

  /** @ngInject */
  function cmsService(RestangularExt, $location, $rootScope) {

    var cache = {};

    return {
      loadData: loadData,
      loadPhotoSubNav: loadPhotoSubNav,
      setSEO: setSEO
    };

    function loadData(querystring) {
      var url = $location.path();

      if(angular.isDefined(querystring)){
        url = url + querystring;
      }

      if(!cache[url]) {
        cache[url] = RestangularExt.all('proxy').customGET(null, {url  : url});
      }

      return cache[url];
    }

    function loadPhotoSubNav(path, querystring) {
      var url = angular.isDefined(path) ? path : $location.path();
      url += '/photonav=1';
      if (angular.isDefined(querystring)) {
        url += querystring;
      }

      if (!cache[url]) {
        cache[url] = RestangularExt.all('proxy').customGET(null, {url: url});
      }

      return cache[url];
    }

    function setSEO(data){
      if(angular.isDefined(data.Content)){
        if(angular.isDefined(data.Content.seo_title) && data.Content.seo_title.data){
          $rootScope.seo_title = data.Content.seo_title.data;
        }
        if(angular.isDefined(data.Content.seo_description) && data.Content.seo_description.data){
          $rootScope.seo_description = data.Content.seo_description.data;
        }
        if(angular.isDefined(data.Content.seo_keywords) && data.Content.seo_keywords.data){
          $rootScope.seo_keywords = data.Content.seo_keywords.data;
        }
      } else if(angular.isDefined(data.ListContent)){
        if(angular.isDefined(data.ListContent.fs_seo_title) && data.ListContent.fs_seo_title.data){
          $rootScope.seo_title = data.ListContent.fs_seo_title.data;
        }
        if(angular.isDefined(data.ListContent.fs_seo_description) && data.ListContent.fs_seo_description.data){
          $rootScope.seo_description = data.ListContent.fs_seo_description.data;
        }
        if(angular.isDefined(data.ListContent.fs_seo_keywords) && data.ListContent.fs_seo_keywords.data){
          $rootScope.seo_keywords = data.ListContent.fs_seo_keywords.data;
        }
      }
    }

  }

  angular.module('wml.portal.cms')
    .factory('cmsService', cmsService);

})();
