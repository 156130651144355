(function() {
  'use strict';

  /** @ngInject */
  function searchResultTarget() {
    return {
      restrict: 'A',
      scope: {
        link: '='
      },
      link: function(scope, element) {
        element.attr('href', scope.link);
        var regex = /cms\/assets/;
        if (regex.test(scope.link)) {
          element.attr('target', '_blank');
        }
      }
    }
  }

  angular
    .module('wml.portal.search')
    .directive('searchResultTarget', searchResultTarget);
})();
