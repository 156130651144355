(function () {
  'use strict';

  function limitTo() {

    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, elm, attrs, ctrl) {
        var _limit      = parseInt(attrs.limitTo);

        function validateLimit(text) {
          if (!!text) {
            if (text.length > _limit) {
              text = text.substring(0, _limit);
              ctrl.$setViewValue(text);
              ctrl.$commitViewValue();
              ctrl.$render();
            }
            return text;
          }
          return '';
        }

        ctrl.$parsers.unshift(validateLimit);
      }
    };
  }

  angular.module('wml.portal.generic')
    .directive('limitTo', limitTo)
})();
