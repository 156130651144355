(function() {
  'use strict';

  function checkLetters() {
    var LETTERS_REGEXP = /^[a-zA-Z]+$/;

    return {
      require   : 'ngModel',
      restrict  : 'A',
      scope     : {
        'letters'    : '=ngModel'
      },
      link: function(scope, elm, attrs, ctrl) {
        scope.$watch('letters', function() {
          if(angular.isString(scope.letters) && scope.letters.length){
            ctrl.$setValidity('checkLetters', LETTERS_REGEXP.test(scope.letters));
          } else {
            ctrl.$setValidity('checkLetters', true);
          }
        });
      }
    };
  };

  angular.module('wml.portal.generic')
    .directive('checkLetters', checkLetters)
})();
