(function() {
  'use strict';

  /** @ngInject */
  function BillingInformationController($scope, $rootScope, enumService, billingInformationService, contactPersonService, $state) {
    var vm = this;

    var SEND_CONTROL  = {
      MAIL    : 0,
      DIGITAL : 3
    };

    vm.data = {
      account_id: null,
      email : null,
      sendcontrol: null,
      is_digital: null
    };

    vm.save = save;

    function save() {
      if(vm.data.is_digital) {
        vm.sendingData  = true;
        contactPersonService.save({
          type: 'administration',
          email: vm.data.email,
          account_id: vm.data.account_id
        }).then(function(data) {
          if(data.fields && data.fields.contact_person) {
            vm.data.account_id  = data.fields.contact_person.relation_account_id;
            contactPersonService.addUpdate(data.fields.contact_person.relation_account_id, data.fields.contact_person);
            $rootScope.$broadcast('clear-cache', {customer: {customer: true}});
          }

          billingInformationService.updateSendControl(vm.data.sendcontrol).then(function(response) {
            $state.go('customerSaved');
          });
        });
      } else {
        billingInformationService.updateSendControl(vm.data.sendcontrol).then(function(response) {
          $state.go('customerSaved');
        });
      }
    }

    function getSendControlOptions() {
      enumService.getSendControl().then(sendControlOptionsSuccess);
    }

    function sendControlOptionsSuccess(response) {
      vm.sendcontrol  = response;
    }

    function getSendControl() {
      billingInformationService.getSendControl().then(sendControlSuccess);
    }

    function sendControlSuccess(response) {
      vm.data.sendcontrol = response.sendcontrol;
    }

    function getAdministrationContactPerson() {
      contactPersonService.getAdministrationContactPerson().then(function(response) {
        vm.data.account_id  = response.customer_number;
        vm.data.email       = response.email.correspondence;
      });
    }

    function activate() {
      getSendControlOptions();
      getSendControl();
      getAdministrationContactPerson();
    }

    activate();

    var unregister  = $scope.$watch(function() {return vm.data.sendcontrol;}, function(newValue) {
      vm.data.is_digital  = (newValue === SEND_CONTROL.DIGITAL);
      if(vm.data.is_digital === false) {
        vm.data.email = null;
      }
    });

    $scope.$on('$destroy', function() {
      unregister();
    });
  }

  angular.module('wml.portal.billingInformation')
    .controller('BillingInformationController', BillingInformationController);

})();
