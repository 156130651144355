(function () {
  'use strict';

  /** @ngInject */
  function PremisesRegisterThankYouController($rootScope, premiseRegisterService) {

    var vm = this;
    vm.data = premiseRegisterService.data;

    vm.isInFuture = '';
    vm.moveInDate = '';

    // private variables

    // Public variables

    // Public function definitions

    // Public functions

    // Private functions

    activate();

    function activate() {
      vm.isInFuture = (vm.data.bIsInFuture) ? 'future_' : '';
      vm.moveInDate = vm.data.meter_reading.date;
      premiseRegisterService.clearData();
    }
  }

  angular
    .module('wml.portal.premisesRegister')
    .controller('PremisesRegisterThankYouController', PremisesRegisterThankYouController);
})();
