(function () {
  'use strict';

  /** @ngInject */
  function InvoicesController($scope, $uibModal, invoicesService, $window, $location, customerService, $state, ivAuthenticate, $translate, storage) {
    var vm = this;

    // private variables
    var _bInvoiceRemainingState   = false;
    var _aSelectedInvoices        = [];
    var _numOfSelectableInvoices  = 10;

    // Public variables
    vm.connectionAddresses        = null;
    vm.data                       = null;
    vm.currentToggledDetail       = null;
    vm.currentDetailData          = null;
    vm.amountRemaining            = null;
    vm.disableAll                 = false;
    vm.csvExportHref              = null;
    vm.yearFilters                = [];
    vm.invoiceAddresses           = {};
    vm.collapseFilter             = true;
    vm.numberOfPayableItems       = 0;

    vm.enableIDEAL                = true;

    vm.corporationAddress         = storage('corporation_address');
    vm.bCorporation               = storage('corporation') || false;

    vm.filterParams = {
      page: 1,
      limit: 10
    };

    // Public function definitions
    vm.toggleDetail     = toggleDetail;
    vm.openPDF          = openPDF;
    vm.openCSV          = openCSV;
    vm.calculateAmount  = calculateAmount;
    vm.changePage       = changePage;
    vm.filter           = filter;
    vm.doPayment        = doPayment;

    // Public functions
    function toggleDetail(id, bGetDetailedInfo) {
      vm.currentDetailData  = null;
      if (vm.currentToggledDetail === id) {
        vm.currentToggledDetail = null;
      } else {
        if(bGetDetailedInfo === true){
          invoicesService.getInvoiceDetail(id).then(function (response) {
            vm.currentDetailData = response;
          });
        }
        vm.currentToggledDetail = id;
      }
    }

    function openPDF(url) {
      var pdfWindow = $window.open('about:blank', '_blank');
      pdfWindow.location.href = url + '?access_token=' + ivAuthenticate.getAccessToken();
    }

    function openCSV() {
      $window.location.href = vm.csvExportHref;
    }

    function calculateAmount(invoice, index) {

      // 20171121 - rob@ivengi.com - Disabled on clients request
      // for(var i = 0; i < vm.data.length; i++) {
      //   if(i > index) {
      //     vm.data[i].selected = false;
      //   }
      // }

      if (invoice.selected) {
        _aSelectedInvoices.push(invoice.invoice_id);
      } else {
        var index = _aSelectedInvoices.indexOf(invoice.invoice_id);
        _aSelectedInvoices.splice(index, 1);
      }

      if (_aSelectedInvoices.length === _numOfSelectableInvoices) {
        vm.disableAll = true;
      } else if (_aSelectedInvoices.length < _numOfSelectableInvoices) {
        vm.disableAll = false;
      }

      vm.amountRemaining = 0;
      angular.forEach(vm.data, function (item) {
        if (item.amount_remaining > 0 && item.selected) {
          vm.amountRemaining += item.amount_remaining;
        }
      });
    }

    function filter() {
      vm.filterParams.page  = 1;
      vm.amountRemaining    = 0;
      getInvoices();
    }

    function doPayment() {
      if (_aSelectedInvoices.length <= _numOfSelectableInvoices) {
        invoicesService.startPayment(_aSelectedInvoices).then(function(result) {
          if(angular.isDefined(result.redirect_url)) {
            $window.location.href = result.redirect_url;
          }
        });
      }
    }

    function changePage() {
      getInvoices();
    }

    // Private functions
    function getInvoices() {
      invoicesService.getInvoices(vm.filterParams).then(function (response) {
        vm.yearFilters    = (response.meta.years.length > vm.yearFilters.length) ? response.meta.years : vm.yearFilters;
        vm.data           = response;
        vm.csvExportHref  = response.meta.export.csv + '&access_token=' + ivAuthenticate.getAccessToken();

        // Keep track of possible payable invoices. If none found, disable iDeal
        for(var i = 0; i < vm.data.length; i += 1) {
          if(vm.data[i].selectable === true) {
            vm.numberOfPayableItems += 1;
            vm.data[i].selected = true;
            vm.calculateAmount(vm.data[i], i);
          }
        }
        if(vm.numberOfPayableItems === 0) {
          vm.enableIDEAL = false;
        }

        if(storage('customer_number')) {
          vm.csvExportHref = vm.csvExportHref + '&customer=' + storage('customer_number');
        }
      });
    }

    function getAddresses() {
      customerService.getPremises({inactive: 1, unique_addresses: 1}).then(getAddressesSuccess);
    }

    function getAddressesSuccess(response) {
      vm.connectionAddresses = response.map(function(item) {
        var option = {};
        option.label = formatAddressString(item);
        option.value = item.address.address_no;

        return option;
      });

      // Set invoice address, used in tooltip when hovering invoices
      angular.forEach(response,function (item) {
        if(angular.isUndefined(vm.invoiceAddresses[item.address.address_no])){
          vm.invoiceAddresses[item.address.address_no] = formatAddressString(item);
        }
      });

      var availableAddressNumbers = vm.connectionAddresses.map(function(item) {
        return item.value;
      });

      vm.connectionAddresses.push({
        label : $translate.instant('general.remaining'),
        value : 'not:' + availableAddressNumbers.join(',')
      });

      if(angular.isDefined(vm.connectionAddresses[0])){
        if(vm.connectionAddresses.length === 1){
          vm.filterParams.address_number = vm.connectionAddresses[0].value;
          filter();
        }
      }

      // if(angular.isDefined(vm.connectionAddresses[0]) && !_bInvoiceRemainingState){ todo _bInvoiceRemainingState still needed?
      //   //vm.filterParams.address_number = vm.connectionAddresses[0].value;
      //   filter();
      // }

      getInvoices();
    }

    function formatAddressString(item){
      if (angular.isDefined(item.address.house_no_addition) && item.address.house_no_addition.length) {
        return item.address.street + ' ' + item.address.house_no + ' ' + item.address.house_no_addition + ', ' + item.address.city;
      } else {
        return item.address.street + ' ' + item.address.house_no + ', ' + item.address.city;
      }
    }

    function formatCorporationAddressString(item){
      if (angular.isDefined(item.house_number_addition) && item.house_number_addition.length) {
        return item.street + ' ' + item.house_number + ' ' + item.house_number_addition + ', ' + item.city;
      } else {
        return item.street + ' ' + item.house_number + ', ' + item.city;
      }
    }

    activate();

    function activate() {
      if(angular.isDefined($state.current.name) && $state.current.name === "invoicesRemaining") {
        _bInvoiceRemainingState = true;
        vm.filterParams.outstanding = 1;
      }

      if(storage('corporation') && angular.isObject(storage('corporation_address'))) {
        vm.filterParams.address_number = vm.corporationAddress.address_number;
        vm.invoiceAddresses[vm.corporationAddress.address_number] = formatCorporationAddressString(vm.corporationAddress);
        getInvoices();

        vm.connectionAddresses = [{
          label: vm.invoiceAddresses[vm.corporationAddress.address_number],
          value: vm.corporationAddress.address_number
        }, {
          label: $translate.instant('general.remaining'),
          value: 'not:' + vm.corporationAddress.address_number
        }];
      } else {
        var paymentId = angular.copy($location.search().id);
        if(paymentId && paymentId.length) {
          $location.search('id', '');
          invoicesService.getProcessPaymentStatus(paymentId).then(function(response) {
            var modalScope = $scope.$new();
            modalScope.response = response;
            modalScope.modal = $uibModal.open({
              templateUrl   : 'app/components/invoices/partials/payment-status.partial.html',
              scope         : modalScope,
              size          : 'lg'
            });
            getAddresses();
          }, function() {
            getAddresses();
          });
        } else {
          getAddresses();
        }
      }
    }
  }

  angular
    .module('wml.portal.invoices')
    .controller('InvoicesController', InvoicesController);
})();
