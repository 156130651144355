(function() {
  'use strict';

  /** @ngInject */
  function contactFormService(RestangularExt, $rootScope) {

    var _url_contact = '/instellingen/contact';
    var _url_business = '/instellingen/contact-zakelijk';
    var _cache = {
      contact : null,
      business : null
    };

    return {
      getCustomer      : getCustomer,
      getContactTypes  : getContactTypes,
      send             : send,
      getData          : getData,
      sendBusinessForm : sendBusinessForm,
      getBusinessData  : getBusinessData
    };

    $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    function getContactTypes(type) {
      type = type || '';
      return RestangularExt.all('form/contact/type').customPOST({
        type: type
      });
    }

    function getCustomer(customerNumber, houseNumber, houseNumberAddition, postalCode) {
      houseNumberAddition = houseNumberAddition || '';
      return RestangularExt.all('form/contact/customer').customPOST({
        customer_number       : customerNumber,
        house_number          : houseNumber,
        house_number_addition : houseNumberAddition,
        postal_code           : postalCode
      });
    }

    function send(data) {
      if(typeof data.premises.postal_code !== 'string' && (data.premises.postal_code.numbers && data.premises.postal_code.letters)) {
        data.premises.postal_code  = data.premises.postal_code.numbers + data.premises.postal_code.letters;
      }

      return RestangularExt.all('form/contact').customPOST(data);
    }

    function sendBusinessForm(data) {
      return RestangularExt.all('form/business-contact').customPOST(data);
    }

    function getData(){
      if(_cache.contact === null){
        _cache.contact = RestangularExt.all('proxy').customGET(null, {url: _url_contact});
      }
      return _cache.contact;
    }

    function getBusinessData(){
      if(_cache.business === null){
        _cache.business = RestangularExt.all('proxy').customGET(null, {url: _url_business});
      }
      return _cache.business;
    }
  }

  angular.module('wml.portal.contact')
    .factory('contactFormService', contactFormService);

})();
