(function () {
  'use strict';

  /** @ngInject */
  function meterReadingSummaryController($state, $rootScope, $scope, $stateParams, $filter, $translate, meterReadingService, storage) {
    var vm = this;
    // private variables
    var _state = 'meterReadingRecording';
    var _stateParams = {};

    // Public variables
    vm.data = meterReadingService.data;

    vm.isCorporation = storage('corporation') || false;
    vm.isCompany = storage('company') || false;

    // Public function definitions
    vm.save = save;
    vm.cancel = cancel;
    vm.gotoMeterReading = gotoMeterReading;
    vm.sendingData = false;

    // Public functions
    function save() {
      var meterReading = vm.data.num1 + vm.data.num2 + vm.data.num3 + vm.data.num4 + vm.data.num5 + vm.data.num6 + vm.data.num7;
      vm.sendingData = true;

      if (vm.data.deviations && vm.data.deviation_code !== null) {
        var found = $filter('filter')(vm.data.deviations, {code: vm.data.deviation_code}, true);
        if (found.length === 1) {
          vm.data.deviation_text = $translate.instant('meter_readings.deviations.' + found[0].translate_key);
        }
      }
      // check if vm.data.file_upload.name is set
      // if no more recollection of fileUpload, then file is gone due to refresh.
      if (vm.data && vm.data.file_upload && vm.data.file_upload.name && !vm.data.file_upload.file) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 11013});
        vm.data.file_upload = null;
        meterReadingService.storeData();
        return;
      }

      meterReadingService.setMeterReading(vm.data.credentials.assignment_number, vm.data.credentials.postal_code, meterReading, vm.data.forced_meter_reading, vm.data.date, vm.data.deviation_code, vm.data.deviation_text, vm.data.deviation_reason, vm.data.email, vm.data.file_upload, vm.data.keep_installment_amount).then(function (response) {
        if (angular.isDefined(response)) {
          // should not happen anymore, since we checked beforehand.
          if (angular.isDefined(response.level) && response.level === 'warning') {
            if (response.fields && response.fields.deviations) {
              vm.data.forced_meter_reading = true;
              vm.data.forced_meter_reading_err_code = response.code;
              vm.data.deviations = response.fields.deviations;
              vm.data.keep_installment_amount = null; // if forced, you can't choose to keep installment amount
              // meterReadingService.storeData();
              $state.go(_state, _stateParams);
            }
          } else {
            $state.go('meterReadingThankYou', _stateParams);
          }
        }
      }).finally(function () {
        vm.sendingData = false;
      });
    }

    function gotoMeterReading() {
      $state.go(_state, _stateParams);
    }

    function cancel() {
      $state.go('meterReadingEmailConfirmation', _stateParams);
    }

    // Private functions
    activate();
    function activate() {
      _stateParams.assignment_number = vm.data.credentials.assignment_number;
      _stateParams.postal_code = vm.data.credentials.postal_code;
      _stateParams.meter_reading = vm.data.meter_reading;
      if (vm.data.credentials.meter_reading) {
        _state = 'meterReadingRecordingExtReading';
        _stateParams.meter_reading = vm.data.credentials.meter_reading;
      }
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }
  }
  angular
    .module('wml.portal.meterReading')
    .controller('meterReadingSummaryController', meterReadingSummaryController);
})();
