(function() {
  'use strict';

  /** @ngInject */
  function PremisesActionOverviewController(premisesService) {
    var vm  = this;

  }

  angular.module('wml.portal.premises')
    .controller('PremisesActionOverviewController', PremisesActionOverviewController);

})();
