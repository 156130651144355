(function(){
  'use strict';

  function BusinessContactController(enumService, contactFormService, $state, $rootScope, $translate){
    var vm = this;

    //public variables
    vm.subjects = null;
    vm.content = null;
    vm.data = {
      company : null,
      contact_person : null,
      phone_number : null,
      email : null,
      subject : null,
      message : null
    };

    //public functions
    vm.submitForm = submitForm;


    function submitForm(){
      contactFormService.sendBusinessForm(vm.data).then(function(response){
        $state.go('contactThankYou');
      });
    }

    function activate(){
      enumService.getBusinessContactFormSubjects().then(function(data){
        vm.subjects = angular.copy(data).map(function(item) {
          item.label = $translate.instant('contact.' + item.label);
          return item;
        });

        vm.subjects.sort(function(a, b){
          if(a.label < b.label) return -1;
          if(a.label > b.label) return 1;
          return 0;
        })
      });

      contactFormService.getBusinessData().then(function (response) {
        vm.contactInformation = response;
        $rootScope.seo_title = response.Content.seo_title.data;
        $rootScope.seo_description = response.Content.seo_description.data;
        $rootScope.seo_keywords = response.Content.seo_keywords.data;
      });

      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');

    }
    activate();
  }
  angular
    .module('wml.portal.contact')
    .controller('BusinessContactController', BusinessContactController);
})();
