(function(){
  'use strict';

  function carafeService(RestangularExt){
    var _carafeRoute  = RestangularExt.all('/form/carafe');

    return {
      send: send
    };

    function send(data) {
      var _data = angular.copy(data);
      if(_data.company.house_number_addition.length){
        _data.company.house_number = _data.company.house_number + ' ' +  _data.company.house_number_addition;
      }
      _data.order.amount = parseInt(_data.order.amount);

      return _carafeRoute.post(_data);
    }
  }


  angular
    .module('wml.portal.carafe')
    .factory('carafeService', carafeService);

})();
