(function () {
  'use strict';

  /** @ngInject */
  function appointmentFormController($state, appointmentService, $rootScope) {
    var vm = this;

    // private variables


    // Public variables
    vm.form                   = null;
    vm.data                   = {};
    vm.sendingData            = false;

    // Public function definitions

    vm.next = next;


    // Public functions
    function next() {
      vm.form.$commitViewValue();
      if (vm.form.$valid) {
        vm.sendingData = true;
        vm.data.filter_type_z6 = true;
        appointmentService.verifyAppointment(vm.data).then(function () {
          $state.go('appointmentEmailCreate');
        }).finally(function(){
          vm.sendingData = false;
        });
      }
    }

    // Private functions

    activate();

    function activate() {
      appointmentService.clearAppointment();
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }

  }

  angular
    .module('wml.portal.appointment')
    .controller('appointmentFormController', appointmentFormController);
})();
