(function () {
  'use strict';

  /** @ngInject */
  function meterReadingController($document, $timeout, $state, $rootScope, $scope, $stateParams, $filter, $translate, calendarsService, meterReadingService, ivAuthenticate, Upload) {
    var vm = this;
    // private variables

    // Public variables
    vm.form = null;

    vm.showDatepicker = false;
    vm.datepickerFormat = "dd-MM-yyyy";
    vm.datepickerCurrentMonth = new Date();
    vm.datepickerOptions = {
      customClass: datePickerClass,
      maxDate: null,
      minDate: null
    };
    vm.currentMeterReading = false;
    vm.data = meterReadingService.data;
    vm.consumption = null;

    vm.fileToUpload = null;

    // Public function definitions
    vm.save = save;
    vm.cancel = cancel;
    vm.openDatepicker = openDatepicker;
    vm.fileChange = fileChange;

    $scope.$on('datepicker.monthChanged', function (event, newVal) {
      vm.datepickerCurrentMonth = newVal;
    });

    $scope.$on('datepicker.format', function (event, mode) {
      datepickerFormat(mode);
    });
    function datepickerFormat(mode) {
      if (mode === 'day') {
        $timeout(function () {
          var uibWeeks = $document[0].getElementsByClassName('uib-weeks');
          for (var i = 0; i < uibWeeks.length; i += 1) {
            var uibBans = uibWeeks[i].getElementsByClassName('uib-ban');
            if (uibBans.length === 7) {
              uibWeeks[i].style.display = 'none';
            }
            if (uibBans.length !== 7) {
              uibWeeks[i].style.display = 'table-row';
            }
          }
        }, 100);
      }
    }

    function datePickerClass(data) {
      var date = data.date;
      var mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        var currentMonthToCheck = angular.isString(vm.datepickerCurrentMonth) ? new Date(vm.datepickerCurrentMonth) : vm.datepickerCurrentMonth;
        if (new Date(dayToCheck).getMonth() !== currentMonthToCheck.getMonth()) {
          if (new Date(dayToCheck) > vm.datepickerCurrentMonth) {
            return 'uib-ban';
          }
          return 'uib-nope';
        }
        return 'uib-yeah';
      }
      return '';
    }

    function openDatepicker() {
      vm.datepickerCurrentMonth = (vm.data.date) ? vm.data.date : new Date();
      vm.showDatepicker = true;
      datepickerFormat('day');
    }

    function getCalendarSettingsFromImanager() {
      calendarsService.getCalendars('meterReading').then(function (response) {
        var max = response.maxDate;
        var min = response.minDate;
        vm.datepickerOptions.maxDate = (isNaN(max)) ? null : new Date(new Date().setDate(new Date().getDate() + max));
        vm.datepickerOptions.minDate = (isNaN(min)) ? null : new Date(new Date().setDate(new Date().getDate() - min));
      });
    }

    function fileChange(file, errFiles) {
      $rootScope.$broadcast('wml.portal.message.cancel');
      if (errFiles.length > 0) {
        if (errFiles[0].$error === "maxSize") {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        } else {
          $rootScope.$broadcast('wml.portal.message', {level: 'error', code: 12102});
        }
      }
      if (file) {
        vm.data.file_upload = null;
        vm.fileToUpload = file;
      }
    }

    function save() {
      if (vm.form.$valid) {
        Upload.base64DataUrl(vm.fileToUpload)
          .then(function (dataUrl) {
            if (dataUrl) {
              vm.data.file_upload = {
                file: dataUrl,
                name: vm.fileToUpload.name,
                type: vm.fileToUpload.type
              };
            }
          })
          .finally(function () {
            if (vm.data.credentials.assignment_number === null || vm.data.credentials.postal_code === null) {
              vm.data.credentials.assignment_number = $stateParams.assignment_number;
              vm.data.credentials.postal_code = $stateParams.postal_code;
            }
            // when deviation reason given, do not do a check again.
            if (vm.data.deviations && vm.data.deviation_code !== null) {
              var found = $filter('filter')(vm.data.deviations, {code: vm.data.deviation_code}, true);
              if (found.length === 1) {
                vm.data.deviation_text = $translate.instant('meter_readings.deviations.' + found[0].translate_key);
              }

              meterReadingService.storeData();
              $state.go('meterReadingEmailConfirmation', $stateParams);
            } else {
              // after first time setting meterreading, check if plausible. If not we'll retrieve devation reasons.
              meterReadingService.checkMeterReading(vm.data.credentials.assignment_number, vm.data.credentials.postal_code, vm.data.meter_reading, vm.data.date).then(function (response) {
                if (angular.isDefined(response)) {
                  if (angular.isDefined(response.level) && response.level === 'warning') {
                    if (response.fields && response.fields.deviations) {
                      vm.data.forced_meter_reading = true;
                      vm.data.forced_meter_reading_err_code = response.code;
                      vm.data.deviations = response.fields.deviations;
                      meterReadingService.storeData();
                      $state.go('meterReadingRecording',
                        {
                          assignment_number: vm.data.credentials.assignment_number,
                          postal_code: vm.data.credentials.postal_code,
                          meter_reading: vm.data.meter_reading
                        });
                    }
                  } else {
                    meterReadingService.storeData();
                    $state.go('meterReadingEmailConfirmation', $stateParams);
                  }
                }
              });
            }
          });
      }
    }

    function cancel() {
      meterReadingService.clearData();
      if (ivAuthenticate.isAuthenticated() !== false) {
        $state.go('dashboard');
      } else {
        $state.go('home');
      }
    }

    // Private functions

    activate();

    function activate() {
      getCalendarSettingsFromImanager();
      if (vm.data && vm.data.date && angular.isString(vm.data.date)) {
        vm.data.date = new Date(vm.data.date);
      }
      meterReadingService.meterDetails($stateParams).then(function (response) {
        if (response.address) {
          vm.data.address = {
            city: response.address.city,
            house_no: response.address.house_no,
            house_no_addition: response.address.house_no_addition,
            postal_code: response.address.postal_code,
            street: response.address.street,
          };
        }

        if (response.meter_number) {
          vm.data.meter_number = response.meter_number;
        }

        if (response.previous_reading) {
          vm.consumption = response.previous_reading;
          if (angular.isDefined(vm.consumption.date)) {
            vm.consumption.date = $filter('date')(vm.consumption.date, 'longDate');
          }
        }

        vm.numberOfMeterNumbers = parseInt(response.nr_wheels_before) || 5;
        vm.aNumberOfMeterNumbers = [];
        for (var i = 0; i < vm.numberOfMeterNumbers; i++) {
          vm.aNumberOfMeterNumbers[i] = i + 1;
        }

        vm.currentMeterReading = vm.data.meter_reading;

        $scope.$watch(function () { return vm.data.meter_reading; }, function (newValue) {
          if (angular.isString(newValue)) {

            if (!vm.data.meter_reading) {
              if ($stateParams.meter_reading && $stateParams.meter_reading !== newValue) {
                vm.data.forced_meter_reading = false;
                vm.data.deviation_code = null;
                vm.data.deviation_reason = null;
                vm.data.deviation_text = null;
              }
            } else {
              if (vm.currentMeterReading && vm.currentMeterReading !== newValue) {
                vm.data.forced_meter_reading = false;
                vm.data.deviation_code = null;
                vm.data.deviation_reason = null;
                vm.data.deviation_text = null;
              }
            }

            var data = newValue.split('');
            var length = data.length;
            if (length < vm.numberOfMeterNumbers) {
              for (var j = 0; j < (vm.numberOfMeterNumbers-length); j++) {
                data.unshift('0');
              }
            }
            for(var k=0; k < data.length; k++) {
              var key = 'num' + (k+1);
              vm.data[key]  = data[k];
            }
          } else {
            for(var l=1; l <= vm.numberOfMeterNumbers; l++) {
              vm.data['num' + l]  = 0;
            }
          }
        });

        $scope.$watchCollection(function() {return [vm.data.num1, vm.data.num2, vm.data.num3, vm.data.num4, vm.data.num5, vm.data.num6, vm.data.num7]}, function() {
          // s3p14 - now possible to come back to page, only set null if not equal to previous meter_reading.
          if (Number(vm.data.num1 + vm.data.num2 + vm.data.num3 + vm.data.num4 + vm.data.num5 + vm.data.num6 + vm.data.num7) !== Number(vm.data.meter_reading)) {
            vm.data.deviation_code    = null;
            vm.data.deviation_reason  = null;
          }
        });

        meterReadingService.storeData();
      });

      vm.data.meter_reading = vm.data.meter_reading || $stateParams.meter_reading || '';

      if (vm.data.forced_meter_reading) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: vm.data.forced_meter_reading_err_code || 11003});
      }

      // check if vm.data.file_upload.name is set
      // if no more recollection of fileUpload, then file is gone due to refresh.
      if (vm.data && vm.data.file_upload && vm.data.file_upload.name && !vm.data.file_upload.file) {
        $rootScope.$broadcast('wml.portal.message', {level: 'warning', code: 11013});
        vm.data.file_upload = null;
        meterReadingService.storeData();
        return;
      }

      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }
  }

  angular
    .module('wml.portal.meterReading')
    .controller('meterReadingController', meterReadingController);
})();
