(function () {
  'use strict';

  angular
    .module('wml.portal')
    .run(runBlock);

  /** @ngInject */
  function runBlock(Restangular, $rootScope, $cookies, $http, storage, ivAuthenticate, $transitions, $window, $location, $state, corporationStateCheckService, $translate, registry) {

    $rootScope.previewBarStyling = {
      display: 'none'
    };

    $rootScope.$watch('preview', function(newValue, oldValue) {
      if(newValue === false && newValue !== oldValue) {
        $cookies.remove('IpCmsH');
        $cookies.remove('sip');
        $window.location.reload();
      }

      if(newValue === true) {
        $rootScope.previewBarStyling.display = 'block';
      } else {
        $rootScope.previewBarStyling.display = 'none';
      }

    });

    $rootScope.preview = false;
    if(parseInt($cookies.get('IpCmsH'), 10) === 1 && $cookies.get('sip')) {
      $rootScope.preview = true;
      Restangular.setDefaultHeaders(angular.extend(Restangular.configuration.defaultHeaders, {'X-IManager-Preview': 'true', 'X-IManager-Session': $cookies.get('sip')}));
    }

    function localstorageTest(){
      var test = 'test';
      try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
      } catch(e) {
        return false;
      }
    }

    if(localstorageTest() !== true){
      $translate('message.custom.1006').then(function (translation) {
        alert(translation);
      });
    }

    $rootScope.ngModelOptions = {
      updateOn: 'default blur',
      debounce: { 'default': 500, 'blur': 0 }
    };

    // Handle response types warning and info
    Restangular.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
      if (data !== null) {
        if (angular.isDefined(data.type) && data.type === 'message') {
          if (angular.isDefined(data.level) && (data.level === 'warning' || data.level === 'info' || data.level === 'success')) {
            $rootScope.$broadcast('wml.portal.message', data);
          }
        }
      }

      return data;
    });

    $rootScope.$on('iv.authenticate.aborted', function() {
      $rootScope.$broadcast('wml.loader.abort');
    });

    // Handle http error responses and check if error message attached or not.
    Restangular.setErrorInterceptor(function (response, deferred) {
      // intercept all errors and broadcast to the loader
      $rootScope.$broadcast('wml.loader.abort');

      if(response.status === 429) {
        if(response.config.url.indexOf(registry.restApiUrl) !== -1) {
          $rootScope.$broadcast('wml.unavailable.start');
          deferred.reject();
        }
      } else {
        if(angular.isDefined(response.data) && response.data !== null && angular.isDefined(response.data.type) && response.data.type === 'message') {
          if(angular.isDefined(response.data.level) && response.data.level === 'error') {
            if(angular.isDefined(response.data.fields) && angular.isArray(response.data.fields) && response.data.fields.length > 0) {
              $rootScope.$broadcast('wml.portal.invalid.fields', response.data.fields);
            }
            $rootScope.$broadcast('wml.portal.message', response.data);
          }
        } else {
          if(response.status !== 401) {
            $rootScope.$broadcast('wml.portal.http_message', {
              level: 'error_http',
              code: response.status
            });
          } else {
            deferred.reject();
          }
        }
      }
    });

    // Handle getList operations that return an object
    Restangular.addResponseInterceptor(function (data, operation, what, url, response, deferred) {
      var extractedData;
      if (operation === "getList" && angular.isObject(data) && angular.isObject(data.meta)) {
        extractedData = data.items;
        extractedData.meta = data.meta;
        return extractedData;
      } else {
        return data;
      }
    });

    $transitions.onBefore({}, function(transition) {
      if(storage('corporation')){
        if(!corporationStateCheckService.isAllowedSecureState(transition.to().name) && !ivAuthenticate.isInsecureState(transition.to().name)){
          if(angular.isObject(storage('corporation_address'))){
            return transition.router.stateService.target('dashboard');
          } else {
            return transition.router.stateService.target('chooseAddress');
          }
        }
      }
    });

    $transitions.onStart({
      to: function (state) {

        $rootScope.insecureRoute = (ivAuthenticate.isInsecureState(state.name));
        $rootScope.seo_title = '';
        $rootScope.seo_description = '';
        $rootScope.seo_keywords = '';
      },
      from: function(state){
        if(state.name.length){
          storage('previous_state', state.name);
        }
      }
    }, function () {
      // intercept all page changes and broadcast to the loader
      $rootScope.$broadcast('wml.loader.abort');
    });

    // Scroll to top when changing page
    $transitions.onError({}, function () {
      $window.scrollTo(0, 0);
      $rootScope.$broadcast('wml.loader.abort');
    });

    // Scroll to top when changing page
    $transitions.onFinish({}, function () {
      $window.scrollTo(0, 0);
    });

    //Set customernumber in Header when communication with backend
    if (ivAuthenticate.isAuthenticated()) {
      var currentCustomerNumber = storage('customer_number');
      if (angular.isDefined(currentCustomerNumber) === false || currentCustomerNumber === null || /[0-9]{8}/.test(currentCustomerNumber) === false) {
        var claims  = ivAuthenticate.getJWTClaims();
        if(angular.isDefined(claims) && angular.isDefined(claims.aud)) {
          storage('customer_number', claims.aud);
        }

        if (angular.isDefined(claims)) {
          if (angular.isDefined(claims.corporation)) {
            storage('corporation', claims.corporation);
          }
          if (angular.isDefined(claims.company)) {
            storage('company', claims.company);
          }
        }
      }

      Restangular.setDefaultHeaders(angular.extend(Restangular.configuration.defaultHeaders, {'X-Customer-Number': currentCustomerNumber}));
      $http.defaults.headers.common['X-Customer-Number'] = currentCustomerNumber;
    } else {
      if (!sessionStorage.getItem(ivAuthenticate.getLocalShortTokenStorageKey()) || ivAuthenticate.isShortTermTokenExpired()) {
        sessionStorage.removeItem(ivAuthenticate.getLocalShortTokenStorageKey());
        storage('customer_number', null);
      }
      storage('corporation', null);
      storage('company', null);
      storage('corporation_address', null);
    }
  }
})();
