(function() {
  'use strict';

  /** @ngInject */
  function SignUpController(signUpService, $state, $rootScope) {
    var vm  = this;

    // private variables

    // Public variables
    vm.form = null;
    vm.data = {};
    vm.sendingData  = false;

    // Public function definitions
    vm.signUp = signUp;

    // Public functions

    function signUp() {
      vm.form.$commitViewValue();
      if(vm.form.$valid) {
        vm.sendingData = true;
        signUpService.signUp(vm.data).then(function(){
          $state.go('sign-upThankYou');
        }).finally(function(){
          vm.sendingData = false;
        });
      }
    }

    // Private functions

    activate();

    function activate() {
      $rootScope.$broadcast('readspeaker.readclass', 'rs-text');
    }
  }

  angular
    .module('wml.portal.signup')
    .controller('SignUpController', SignUpController);
})();
