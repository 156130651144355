(function () {
  'use strict';

  /** @ngInject */
  function MessageController($rootScope, $translate, $document, $scope, $timeout, restBackend) {

    var vm = this;
    var _message;
    var _stickyMessage;
    var _httpMessage;
    var _customMessage;
    var _cancelMessage;

    // private variables
    // Public variables
    vm.code           = null;
    vm.level          = null;
    vm.messageType    = null;
    vm.bHideError     = false;
    vm.stickyMessage  = {};
    // Public function definitions
    // Public functions
    // Private functions

    activate();

    function activate() {

      _message = $rootScope.$on('wml.portal.message', function (event, response) {
        if (angular.isDefined(response)) {
          response.params = response.params || response.fields || {};
          vm.code         = response.code;
          vm.level        = response.level;
          vm.message      = $translate.instant('message.backoffice.' + vm.code, response.params);
          vm.messageType  = 'backoffice_message';
          vm.bHideError   = false;

          scrollToMessage();
        }
      });

      _stickyMessage = $rootScope.$on('wml.portal.sticky_message', function (event, response) {
        if (angular.isDefined(response)) {
          response.params = response.params || response.fields || {};
          vm.stickyMessage.code         = response.code;
          vm.stickyMessage.level        = response.level;
          vm.stickyMessage.message      = response.message || $translate.instant('message.backoffice.' + vm.stickyMessage.code, response.params);
          vm.stickyMessage.messageType  = 'sticky_message';
        }
      });

      _httpMessage = $rootScope.$on('wml.portal.http_message', function (event, response) {
        if (angular.isDefined(response)) {
          vm.level = response.level;
          vm.messageType = 'http_message';
          $translate('message.http.' + response.code).then(function (translation) {
            vm.message = translation;
          }, function () {
            $translate('message.http.xxx').then(function (translation) {
              vm.message  = translation;
            });
          });
          vm.bHideError = false;
          scrollToMessage();
        }
      });

      _customMessage = $rootScope.$on('wml.portal.custom_message', function (event, response) {
        if (angular.isDefined(response)) {
          response.params = response.params || {};
          vm.code = response.code;
          vm.level = response.level;
          vm.message  = $translate.instant('message.custom.' + vm.code, response.params);
          vm.messageType = 'custom_message';
          vm.bHideError = false;
          scrollToMessage();
        }
      });

      _cancelMessage = $rootScope.$on('wml.portal.message.cancel', function () {
        vm.bHideError = true;
      });

      $scope.$on('$destroy', function() {
        _message();
        _cancelMessage();
        _httpMessage();
        _customMessage();
      });
    }

    function scrollToMessage() {
      $timeout(function () {
        var messageElement = angular.element(document.getElementById('messageScroll'));
        $document.scrollToElement(messageElement, 150, 1000);
      },0);
    }

    restBackend.getSapStatus().then(function(res) {
      if (!res.status) {
        $rootScope.$broadcast('wml.portal.sticky_message', {
          code: 19000,
          level: 'error',
          messageType: 'sticky_message'
        });
      }
    });

    restBackend.getCrisisMsg().then(function (res) {
      if (res.show && res.show) {
        $rootScope.$broadcast('wml.portal.sticky_message', {
          level: res.level || 'error',
          messageType: 'sticky_message',
          message: res.data
        });
      }
    });
  }

  angular
    .module('wml.portal.message')
    .controller('MessageController', MessageController);
})();
