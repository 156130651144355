(function () {
  'use strict';

  /** @ngInject */
  function UsageDashboardController($uibModal, $scope, $state, $translate, customerService, meterReadingService, usageChartService) {
    var vm = this;

    // private variables
    var _meterReadings;

    // Public variables
    vm.usageScanModal           = null;
    vm.bShowMeterReadingButton  = false;
    vm.bShowUsageScan           = false;
    vm.connectionAddresses      = null;

    vm.is_company               = false;

    vm.bShowChart               = false;
    vm.chartFilterAddresses     = null;
    vm.chartFormData            = {
      persons : 150
    };

    vm.chartExtraConfig             = usageChartService.chartExtraConfig;
    vm.chartConfig                  = usageChartService.getConfig;
    vm.chartConfig.yAxis.plotLines  = [];

    // Public function definitions
    vm.startUsageScan           = startUsageScan;
    vm.openMeterReadingsModal   = openMeterReadingsModal;
    vm.chartUpdatePlotline      = chartUpdatePlotline;
    vm.changeChart              = changeChart;
    vm.getDisplayNameMeterReadingCategory = getDisplayNameMeterReadingCategory;

    // Public functions
    function changeChart() {
      usageChartService.getConsumption(vm.chartExtraConfig.selectedChartAddress);
    }

    function startUsageScan(){
      goToUsageScan();
    }

    function getDisplayNameMeterReadingCategory(catId) {
      var customer = ['KL', 'T', 'I', '06', '02'];
      var wml = ['OW', 'OM', '01', 'IN'];
      var estimated = ['03', '04', 'SW'];
      if (customer.indexOf(catId) !== -1) { return $translate.instant('usage.chart.graph_labels.customer'); }
      if (wml.indexOf(catId) !== -1) { return $translate.instant('usage.chart.graph_labels.wml'); }
      if (estimated.indexOf(catId) !== -1) { return $translate.instant('usage.chart.graph_labels.estimated'); }
      return '-';
    }

    function openMeterReadingsModal() {
      vm.meterReadings = _meterReadings;
      if(angular.isObject(vm.meterReadings) && vm.meterReadings.length > 1){
        $scope.modal  = vm;
        vm.meterReadingsModal = $uibModal.open({
          templateUrl   : 'app/components/usage/partials/meter-readings.modal.partial.html',
          scope         : $scope,
          size          : 'lg'
        });

        vm.meterReadingsModal.result.then(function(params){
          $state.go('meterReadingRecording', params)
        });
      } else {
        var params = {assignment_number: vm.meterReadings[0].assignment_number, postal_code : vm.meterReadings[0].device.address.postal_code};
        $state.go('meterReadingRecording', params)
      }
    }

    function chartUpdatePlotline(){
      vm.chartConfig.getChartObj().yAxis[0].removePlotLine('usagePlotline');
      vm.chartConfig.getChartObj().yAxis[0].addPlotLine({
        value: vm.chartFormData.persons,
        id: 'usagePlotline',
        color: '#000',
        width: 1,
        zIndex: 5
      });
    }

    // Private functions
    function goToUsageScan() {
      if(angular.isObject(vm.connectionAddresses) && vm.connectionAddresses.length > 1){
        vm.usageScanModal = $uibModal.open({
          templateUrl: 'app/components/usage/partials/connection-addresses.modal.partial.html',
          scope: $scope,
          size: 'lg'
        });

        vm.usageScanModal.result.then(function(params){
          $state.go('usageMeterReadings', params);
        });
      } else {
        var params = {contract_account_id: vm.connectionAddresses[0].contract_account_id, contract_id: vm.connectionAddresses[0].contract_id, premise_id: vm.connectionAddresses[0].premise_id};
        $state.go('usageMeterReadings', params);
      }
    }

    function getPremises() {
      customerService.getPremises().then(function(response){

        vm.connectionAddresses = response;
        vm.bShowUsageScan = vm.connectionAddresses.length;

        usageChartService.initChart(response);
      });
    }

    function checkMeterReadings() {
      meterReadingService.getMeterReadings().then(getMeterReadingsSuccess);
    }

    function getMeterReadingsSuccess(response) {
      _meterReadings = response;
      if (angular.isArray(_meterReadings) && _meterReadings.length > 0) {
        vm.bShowMeterReadingButton = true;
      }
    }

    function getCustomer() {
      customerService.getCustomer().then(getCustomerSuccess);
    }

    function getCustomerSuccess(response) {
      vm.is_company = response.is_company;
    }

    activate();

    function activate() {
      checkMeterReadings();
      getPremises();
      getCustomer();
    }

  }

  angular
    .module('wml.portal.usage')
    .controller('UsageDashboardController', UsageDashboardController);
})();
