(function () {
  'use strict';

  /** @ngInject */
  function RegisterMoveInDateController($document, $timeout, $state, $scope, $rootScope, calendarsService, storage, registerService) {
    var vm = this;

    // Public variables
    vm.form = null;
    vm.data = registerService.data;

    vm.showDatepicker = false;
    vm.datepickerFormat = "dd-MM-yyyy";
    vm.datepickerCurrentMonth = new Date();
    vm.datepickerOptions = {
      customClass: datePickerClass,
      maxDate: null,
      minDate: null
    };

    // Public function definitions
    vm.next = next;
    vm.cancel = cancel;
    vm.openDatepicker = openDatepicker;

    activate();

    $scope.$on('datepicker.monthChanged', function (event, newVal) {
      vm.datepickerCurrentMonth = newVal;
    });

    $scope.$on('datepicker.format', function (event, mode) {
      datepickerFormat(mode);
    });

    function datepickerFormat(mode) {
      if (mode === 'day') {
        $timeout(function () {
          var uibWeeks = $document[0].getElementsByClassName('uib-weeks');
          for (var i = 0; i < uibWeeks.length; i += 1) {
            var uibBans = uibWeeks[i].getElementsByClassName('uib-ban');
            if (uibBans.length === 7) {
              uibWeeks[i].style.display = 'none';
            }
            if (uibBans.length !== 7) {
              uibWeeks[i].style.display = 'table-row';
            }
          }
        }, 100);
      }
    }

    function datePickerClass(data) {
      var date = data.date;
      var mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);
        if (new Date(dayToCheck).getMonth() !== vm.datepickerCurrentMonth.getMonth()) {
          if (new Date(dayToCheck) > vm.datepickerCurrentMonth) {
            return 'uib-ban';
          }
          return 'uib-nope';
        }
        return 'uib-yeah';
      }
      return '';
    }

    function openDatepicker() {
      vm.datepickerCurrentMonth = (vm.data.meterReading.date) ? vm.data.meterReading.date : new Date();
      vm.showDatepicker = true;
      datepickerFormat('day');
    }

    function getCalendarSettingsFromImanager() {
      calendarsService.getCalendars('premisesRegister').then(function (response) {
        var max = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.moveInMaxDateCorporation : response.moveInMaxDate);
        var min = (storage('corporation') && angular.isObject(storage('corporation_address')) ? response.moveInMinDateCorporation : response.moveInMinDate);
        vm.datepickerOptions.maxDate = (isNaN(max)) ? null : new Date(new Date().setDate(new Date().getDate() + max));
        vm.datepickerOptions.minDate = (isNaN(min)) ? null : new Date(new Date().setDate(new Date().getDate() - min));
      });
    }

    // Public functions
    function next() {
      if (vm.form.$valid) {

        var today = new Date().setHours(0, 0, 0, 0);
        var moveInDate = new Date(vm.data.meterReading.date).setHours(0, 0, 0, 0);
        vm.data.bIsInFuture = (moveInDate > today);

        registerService.storeData();
        $state.go('register');
      }
    }

    function cancel() {
      registerService.clearData();
      $state.go('sign-up');
    }

    function activate() {
      getCalendarSettingsFromImanager();
      if (vm.data.meterReading.date !== null) {
        vm.data.meterReading.date = new Date(vm.data.meterReading.date);
      }
    }
  }

  angular
    .module('wml.portal.register')
    .controller('RegisterMoveInDateController', RegisterMoveInDateController);
})();
